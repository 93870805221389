import React, { useState } from "react";
import Select from "react-select";
const IncomeTab = ({
  membersMeetingData,
  activeBank,
  allPermission,
  formData,
  setFormData,
  NextBackBtn,
}) => {
  let { sdUnionToSHG, deactiveMemberSaving, loanRepaidByOtherSHG,batchOtherIncome,seedMoney,batchOtherContribution
  } = formData;

 const funcKeyDown = (e) => {
    ["e", "E", "+", "-" ,"."].includes(e.key) && e.preventDefault();
  };

  let dataOfIncomeTab = {};
  if(formData.institutionId === "63c0e52501d22dcc13f12c32"){
    dataOfIncomeTab = {
      batchSavingAmtCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.savingAmt.cash || 0)),
        0
      ),
      batchSavingAmtBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.savingAmt.bankAmt || 0)),
        0
      ),
  
      //sub amt
  
      batchSubAmtCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.subscriptionAmt.cash || 0)),
        0
      ),
      batchSubAmtBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.subscriptionAmt.bankAmt || 0)),
        0
      ),
  
      //batchOtherContribution
  
      batchOtherContributionCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.otherContribution.cash || 0)),
        0
      ),
      batchOtherContributionBank:0,
      //  membersMeetingData.reduce(
      //   (acu, cur) => (acu += Number(cur.otherContribution.bankAmt || 0)),
      //   0
      // ),
  
      //batchMembershipFees
  
      batchMembershipFeesCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.membershipFees.cash || 0)),
        0
      ),
      batchMembershipFeesBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.membershipFees.bankAmt || 0)),
        0
      ),
  
      //batchLoanPaid
      batchLoanPaidCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.loanPaid.cash || 0)),
        0
      ),
  
      batchLoanPaidBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.loanPaid.bankAmt || 0)),
        0
      ),
      batchInterestPaidCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.interestPaid.cash || 0)),
        0
      ),
      batchInterestPaidBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.interestPaid.bankAmt || 0)),
        0
      ),
  
      batchOtherIncomeCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.otherIncome.cash || 0)),
        0
      ),
  
      batchOtherIncomeBank: 0 ,
      //  membersMeetingData.reduce(
      //   (acu, cur) => (acu += Number(cur.otherIncome.bankAmt || 0)),
      //   0
      // ),
  
      seedMoneyCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.memSeedMoney.cash || 0)),
        0
      ),
  
      seedMoneyBank:0,
      //  membersMeetingData.reduce(
      //   (acu, cur) => (acu += Number(cur.memSeedMoney.bankAmt || 0)),
      //   0
      // ),
  
      batchSecurityDepositMemToShgCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.cash || 0)),
        0
      ),
      batchSecurityDepositMemToShgBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.bankAmt || 0)),
        0
      ),
    };
  }else{
    dataOfIncomeTab = {
      batchSavingAmtCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.savingAmt.cash || 0)),
        0
      ),
      batchSavingAmtBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.savingAmt.bankAmt || 0)),
        0
      ),
  
      //sub amt
  
      batchSubAmtCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.subscriptionAmt.cash || 0)),
        0
      ),
      batchSubAmtBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.subscriptionAmt.bankAmt || 0)),
        0
      ),
  
      //batchOtherContribution
  
      batchOtherContributionCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.otherContribution.cash || 0)),
        0
      ),
      batchOtherContributionBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.otherContribution.bankAmt || 0)),
        0
      ),
  
      //batchMembershipFees
  
      batchMembershipFeesCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.membershipFees.cash || 0)),
        0
      ),
      batchMembershipFeesBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.membershipFees.bankAmt || 0)),
        0
      ),
  
      //batchLoanPaid
      batchLoanPaidCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.loanPaid.cash || 0)),
        0
      ),
  
      batchLoanPaidBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.loanPaid.bankAmt || 0)),
        0
      ),
      batchInterestPaidCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.interestPaid.cash || 0)),
        0
      ),
      batchInterestPaidBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.interestPaid.bankAmt || 0)),
        0
      ),
  
      batchOtherIncomeCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.otherIncome.cash || 0)),
        0
      ),
  
      batchOtherIncomeBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.otherIncome.bankAmt || 0)),
        0
      ),
  
      seedMoneyCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.memSeedMoney.cash || 0)),
        0
      ),
  
      seedMoneyBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.memSeedMoney.bankAmt || 0)),
        0
      ),
  
      batchSecurityDepositMemToShgCash: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.cash || 0)),
        0
      ),
      batchSecurityDepositMemToShgBank: membersMeetingData.reduce(
        (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.bankAmt || 0)),
        0
      ),
    };
  }
  

  const {
    batchSavingAmtCash,
    batchSavingAmtBank,
    batchSubAmtCash,
    batchSubAmtBank,
    batchOtherContributionCash,
    batchOtherContributionBank,
    batchMembershipFeesCash,
    batchMembershipFeesBank,
    batchLoanPaidCash,
    batchLoanPaidBank,
    //sd
    batchInterestPaidCash,
    batchInterestPaidBank,
    batchOtherIncomeCash,
    batchOtherIncomeBank,
    seedMoneyCash,
    seedMoneyBank,
    batchSecurityDepositMemToShgCash,
    batchSecurityDepositMemToShgBank,
  } = dataOfIncomeTab;

////////////////////////////////////////////calculating the otherLoan start///////////////////////////////////////////////////
// Initialize totals
const totalsByBankId = {};
// Loop through each member's meeting data
membersMeetingData.forEach(member => {
  // Get the bankId for this member
  const bankId = member.memBankName && member.memBankName.bankId;

  // Initialize the entry for this bankId if it doesn't exist
  if (!totalsByBankId[bankId]) {
    totalsByBankId[bankId] = {
      batchBankNameId: bankId,
      batchBankName: member.memBankName && member.memBankName.label, // Include bank name for reference
      indBankInterest: 0,
      indBankCommission: 0, 
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: 0,
      transactionType: "M", 
      otherLoanTypeId: "" 
    };
  }

  // Calculate income totals (deposits)
  member.otherLoan.forEach(loan => {
    // Sum up otherInterestPaid
    totalsByBankId[bankId].otherLoanTypeId = loan.otherLoanTypeId;
    if (loan.otherInterestPaid) {
      totalsByBankId[bankId].indBatchBankDeposit += Number(loan.otherInterestPaid.bankAmt) || 0;
    }
    
    // Sum up otherLoanPaid
    if (loan.otherLoanPaid) {
      totalsByBankId[bankId].indBatchBankDeposit += Number(loan.otherLoanPaid.bankAmt) || 0;
    }
     // Calculate expenses (withdrawals)
  if (loan.indSubsidyDistributed) {
    totalsByBankId[bankId].indBatchBankWithdrawals += Number(loan.indSubsidyDistributed.bankAmt) || 0;
  }
  });
});

// Convert the totals object into an array
const otherLoanArray = Object.values(totalsByBankId);

//////////////////////////////////////////calculating the otherLoan end///////////////////////////////////////////////////////////
  
///////////////////////////////////////////otherLoan seperate fields start//////////////////////////////////////////////
// Initialize an object to store totals grouped by bankId and otherLoanTypeId
const totalsByBankAndLoanType = {};

// Loop through each member's meeting data
membersMeetingData.forEach(member => {
  const bankId = member.memBankName && member.memBankName.bankId;
  const bankName = member.memBankName && member.memBankName.label; // Get the bank name

  // Loop through the loans for this member
  member.otherLoan.forEach(loan => {
    const otherLoanTypeId = loan.otherLoanTypeId;

    // Ensure the loan has the necessary fields
    if (otherLoanTypeId) {
      // Initialize the structure for this bankId and otherLoanTypeId combination if it doesn't exist
      if (!totalsByBankAndLoanType[bankId]) {
        totalsByBankAndLoanType[bankId] = {};
      }
      if (!totalsByBankAndLoanType[bankId][otherLoanTypeId]) {
        totalsByBankAndLoanType[bankId][otherLoanTypeId] = {
          bankId: bankId,
          bankName: bankName,
          otherLoanTypeId: otherLoanTypeId,
          totalInterestPaid: 0,
          totalLoanPaid: 0,
          totalSubsidyDistributed: 0
        };
      }

      // For interest paid (income)
      if (loan.otherInterestPaid && loan.otherInterestPaid.bankAmt > 0) {
        totalsByBankAndLoanType[bankId][otherLoanTypeId].totalInterestPaid += Number(loan.otherInterestPaid.bankAmt);
      }

      // For loan paid (income)
      if (loan.otherLoanPaid && loan.otherLoanPaid.bankAmt > 0) {
        totalsByBankAndLoanType[bankId][otherLoanTypeId].totalLoanPaid += Number(loan.otherLoanPaid.bankAmt);
      }

      // For subsidy distributed (expenses)
      if (loan.indSubsidyDistributed && loan.indSubsidyDistributed.bankAmt > 0) {
        totalsByBankAndLoanType[bankId][otherLoanTypeId].totalSubsidyDistributed += Number(loan.indSubsidyDistributed.bankAmt);
      }
    }
  });
});

// Convert the object into an array
const finalResultArray = Object.values(totalsByBankAndLoanType).flatMap(bankEntry => {
  return Object.values(bankEntry).map(entry => ({
    bankId: entry.bankId,
    bankName: entry.bankName,
    otherLoanTypeId: entry.otherLoanTypeId,
    totalInterestPaid: entry.totalInterestPaid,
    totalLoanPaid: entry.totalLoanPaid,
    totalSubsidyDistributed: entry.totalSubsidyDistributed
  }));
});

// Log the final result array for debugging
////////////////////////////////////////////otherLoan seperate fields end////////////////////////////////////////////////
const[newBatchOtherContributionBank,setNewBatchOtherContributionBank] = useState(batchOtherContribution && batchOtherContribution.bankAmt);
  const[newBatchOtherContributionCash,setBatchOtherContributionCash] = useState(batchOtherContribution && batchOtherContribution.cash);
  
  const[newBatchOtherIncomeBank,setBatchOtherIncomeBank] = useState(batchOtherIncome && batchOtherIncome.bankAmt);
  const[newBatchOtherIncomeCash,setBatchOtherIncomeCash] = useState(batchOtherIncome && batchOtherIncome.cash);
  
  const[newSeedMoneyBank,setSeedMoneyBank] =useState(seedMoney && seedMoney.bankAmt);
  const[newSeedMoneyCash,setSeedMoneyCash] =useState(seedMoney && seedMoney.cash);

  const[otherContributionBankName,setsOtherContributionBankName] = useState(batchOtherContribution && batchOtherContribution.bankName != '' ?
    {
    bankId:batchOtherContribution.bankId,
    label: batchOtherContribution.bankName,
    value: batchOtherContribution.bankName,
  } : "" );
  const[otherIncomeBankName,setsOtherIncomeBankName] = useState(batchOtherIncome && batchOtherIncome.bankName ? {
    label: batchOtherIncome.bankName,
    value: batchOtherIncome.bankName,
  } : "" )
  const[seedMoneyBankName,setsSeedMoneyBankName] = useState(seedMoney && seedMoney.bankName != "" ?{
    label: seedMoney.bankName,
    value: seedMoney.bankName,
  } : "" );

  const [sdUnionToSHGBank, setsdUnionToSHGBank] = useState(
    sdUnionToSHG ? sdUnionToSHG.bankAmt : 0
  );

  const [sdUnionToSHGBankName, setsdUnionToSHGBankName] = useState(
    sdUnionToSHG.bankName != ''
      ? {
          label: sdUnionToSHG.bankName,
          value: sdUnionToSHG.bankName,
        }
      : ""
  );

  const [sdUnionToSHGCash, setsdUnionToSHGCash] = useState(
    sdUnionToSHG ? sdUnionToSHG.cash : 0
  );

  //deactive memberSaving
  const [
    deactiveSavingMemberAmtCash,
    setdeactiveSavingMemberAmtCash,
  ] = useState(deactiveMemberSaving ? deactiveMemberSaving.cash : 0);

  const [
    deactiveSavingMemberAmtBank,
    setdeactiveSavingMemberAmtBank,
  ] = useState(deactiveMemberSaving ? deactiveMemberSaving.bankAmt : 0);

  const [
    deactiveSavingMemberAmtBankName,
    setdeactiveSavingMemberAmtBankName,
  ] = useState(
    deactiveMemberSaving.bankName
      ? {
          label: deactiveMemberSaving.bankName,
          value: deactiveMemberSaving.bankName,
        }
      : ""
  );

  //loan
  const [
    loanRepaidByOtherSHGBankName,
    setloanRepaidByOtherSHGBankName,
  ] = useState(
    loanRepaidByOtherSHG
      ? {
          label: loanRepaidByOtherSHG.bankName,
          value: loanRepaidByOtherSHG.bankName,
        }
      : ""
  );

  const [loanRepaidByOtherSHGBank, setloanRepaidByOtherSHGBank] = useState(
    loanRepaidByOtherSHG ? loanRepaidByOtherSHG.bankAmt : 0
  );

  const [loanRepaidByOtherSHGCash, setloanRepaidByOtherSHGCash] = useState(
    loanRepaidByOtherSHG ? loanRepaidByOtherSHG.cash : 0
  );

///////////////////////////////////////////new bank Details of Income & expense calculation start///////////////////////////////////////////
// Create a new array with bankId and bankName
const bankDetails = membersMeetingData
  .map(member => ({
    bankId: member.memBankName ? member.memBankName.bankId : null,
    bankName: member.memBankName ? member.memBankName.label : null
  }))
  .filter(bank => bank.bankId && bank.bankName) // Filter out invalid records
  .filter((bank, index, self) => 
    index === self.findIndex(b => b.bankId === bank.bankId) // Remove duplicates based on bankId
  );

// Initialize an object to hold totals
const bankTotals = {};

// Define the fields for income and expenses
const incomeFields = [
  'memSeedMoney',
  'memSecurityDepositToShg',
  'membershipFees',
  'interestPaid',
  'loanPaid',
  'otherContribution',
  'otherIncome',
  'savingAmt',
  'subscriptionAmt',
];

const expenseFields = [
  'dividendDistributed',
  'savingWithdrawals',
  'securityDeposit',
];

// Calculate totals based on bankId
membersMeetingData.forEach(member => {
  const bankId = member.memBankName ? member.memBankName.bankId : null;
  const bankName = member.memBankName ? member.memBankName.label : null;

  // Skip if either bankId or bankName is invalid (undefined or empty)
  if (!bankId || !bankName) {
    return; // Skip this iteration if bankId or bankName is invalid
  }

  // Initialize bank entry if it doesn't exist
  if (!bankTotals[bankId]) {
    bankTotals[bankId] = {
      bankId,
      batchBankNameId: bankId,
      batchBankName: bankName,
      indBankInterest: 0,
      indBankCommission: 0,
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: 0,
      transactionType: "M",
      otherLoanTypeId: ""
    };
  }

  // Process income fields (deposits)
  incomeFields.forEach(field => {
    if (member[field] && member[field].bankName) {
      const amount = member[field].bankAmt || 0;
      bankTotals[bankId].indBatchBankDeposit += Number(amount);
    }
  });

  // Process expense fields (withdrawals)
  expenseFields.forEach(field => {
    if (member[field] && member[field].bankName) {
      const amount = member[field].bankAmt || 0;
      bankTotals[bankId].indBatchBankWithdrawals += Number(amount);
    }
  });

  });

// Convert the totals object into an array
const totalsArray = Object.values(bankTotals).filter(
  (bank) => {
    return (
      bank.indBankInterest !== 0 ||
      bank.indBankCommission !== 0 ||
      bank.indBatchBankDeposit !== 0 ||
      bank.indBatchBankWithdrawals !== 0
    );
  }
);

////////////////////////////////////////////new bank Details of Income & expense calculation end///////////////////////////////////////////
  const incomeNextTab = () => {
    let finalIncomeData = {};
    if(formData.institutionId === "63c0e52501d22dcc13f12c32"){
       finalIncomeData = {
        batchSavingAmt: {
          cash: batchSavingAmtCash,
          bankAmt: batchSavingAmtBank,
          bankName: "",
        },
  
        batchSubAmt: {
          cash: batchSubAmtCash,
          bankAmt: batchSubAmtBank,
          bankName: "",
        },
  
        batchLoanPaid: {
          cash: batchLoanPaidCash,
          bankAmt: batchLoanPaidBank,
          bankName: "",
        },
        batchOtherIncome: {
          cash: newBatchOtherIncomeCash  ,
          bankAmt: newBatchOtherIncomeBank  ,
          bankName: otherIncomeBankName.value || "",
          bankId:otherIncomeBankName.bankId || ""
        },
        batchOtherContribution: {
          cash: newBatchOtherContributionCash ,
          bankAmt: newBatchOtherContributionBank  ,
          bankName: otherContributionBankName.value || "",
          bankId:otherContributionBankName.bankId || ""
        },
  
        batchMembershipFees: {
          cash: batchMembershipFeesCash,
          bankAmt: batchMembershipFeesBank,
          bankName: "",
        },
  
        seedMoney: {
          cash: newSeedMoneyCash  ,
          bankAmt: newSeedMoneyBank   ,
          bankName: seedMoneyBankName.value || "",
          bankId:seedMoneyBankName.bankId || ""
        },
  
        deactiveMemberSaving: {
          bankName: deactiveSavingMemberAmtBankName.label || "",
          bankAmt: Number(deactiveSavingMemberAmtBank),
          cash: Number(deactiveSavingMemberAmtCash),
          bankId:deactiveSavingMemberAmtBankName.bankId || ""
        },
  
        batchInterestPaid: {
          cash: batchInterestPaidCash,
          bankAmt: batchInterestPaidBank,
          bankName: "",
        },
        batchSecurityDepositMemToShg: {
          cash: batchSecurityDepositMemToShgCash,
          bankAmt: batchSecurityDepositMemToShgBank,
          bankName: "",
        },
  
        // sdUnionToSHGBankName
        sdUnionToSHG: {
          cash: sdUnionToSHGCash,
          bankAmt: sdUnionToSHGBank ,
          bankName: sdUnionToSHGBankName.label || "",
          bankId:sdUnionToSHGBankName.bankId || ""
        },
  
        loanRepaidByOtherSHG: {
          cash: loanRepaidByOtherSHGCash,
          bankAmt: loanRepaidByOtherSHGBank,
          bankName: loanRepaidByOtherSHGBankName.label || "", // req flag
        },
        
      };
    }else{
       finalIncomeData = {
        batchSavingAmt: {
          cash: batchSavingAmtCash,
          bankAmt: batchSavingAmtBank,
          bankName: "",
        },
  
        batchSubAmt: {
          cash: batchSubAmtCash,
          bankAmt: batchSubAmtBank,
          bankName: "",
        },
  
        batchLoanPaid: {
          cash: batchLoanPaidCash,
          bankAmt: batchLoanPaidBank,
          bankName: "",
        },
        batchOtherIncome: {
          cash: newBatchOtherIncomeCash ? newBatchOtherIncomeCash :batchOtherIncomeCash ,
          bankAmt: newBatchOtherIncomeBank ? newBatchOtherIncomeBank : batchOtherIncomeBank ,
          bankName: otherIncomeBankName.value,
        },
        batchOtherContribution: {
          cash: newBatchOtherContributionCash ? newBatchOtherContributionCash : batchOtherContributionCash,
          bankAmt: newBatchOtherContributionBank ? newBatchOtherContributionBank :batchOtherContributionBank ,
          bankName: otherContributionBankName.value,
        },
  
        batchMembershipFees: {
          cash: batchMembershipFeesCash,
          bankAmt: batchMembershipFeesBank,
          bankName: "",
        },
  
        seedMoney: {
          cash: newSeedMoneyCash ? newSeedMoneyCash : seedMoneyCash ,
          bankAmt: newSeedMoneyBank ? newSeedMoneyBank :seedMoneyBank  ,
          bankName: seedMoneyBankName.value,
        },
  
        deactiveMemberSaving: {
          bankName: deactiveSavingMemberAmtBankName.label || "",
          bankAmt: Number(deactiveSavingMemberAmtBank),
          cash: Number(deactiveSavingMemberAmtCash),
          bankId: deactiveSavingMemberAmtBankName.bankId || "",
        },
  
        batchInterestPaid: {
          cash: batchInterestPaidCash,
          bankAmt: batchInterestPaidBank,
          bankName: "",
        },
        batchSecurityDepositMemToShg: {
          cash: batchSecurityDepositMemToShgCash,
          bankAmt: batchSecurityDepositMemToShgBank,
          bankName: "",
        },
  
        // sdUnionToSHGBankName
        sdUnionToSHG: {
          cash: Number(sdUnionToSHGCash || 0),
          bankAmt: Number(sdUnionToSHGBank || 0) ,
          bankName: sdUnionToSHGBankName.label || "",
          bankId:sdUnionToSHGBankName.bankId || ""
        },
  
        loanRepaidByOtherSHG: {
          cash: loanRepaidByOtherSHGCash,
          bankAmt: loanRepaidByOtherSHGBank,
          bankName: loanRepaidByOtherSHGBankName.label || "", // req flag
          bankId:loanRepaidByOtherSHGBankName.bankId || ""
        },
        
      };
    }

/////////////////////////////calculating the income of respective fields start////////////////////////////////
// Create the income array
const batchIncomeArray = [];
// Fields to consider for income calculation
const incomeFields = [
  "batchOtherContribution",
  "sdUnionToSHG",
  "batchOtherIncome",
  "seedMoney",
  "deactiveMemberSaving",
];

incomeFields.forEach(field => {
  const data = finalIncomeData[field];

  // Find the bank using bankName
  const bank = bankDetails.find(bank => bank.bankName === data.bankName);

  let bankId = ''; // Initialize bankId variable
  
  if (bank && bank.bankId !== '' && bank.bankName !== '' && bank !== undefined) {
    bankId = bank.bankId;
  } else {
    bankId = data.bankId;
  }

  if (bankId === undefined || bankId === '') {
    return;
  }
  const existingEntry = batchIncomeArray.find(entry => entry.bankId === bankId);
  
  if (existingEntry) {
    // Combine totals if the bankId already exists
    existingEntry.indBatchBankDeposit += Number(data.bankAmt) || 0; 
    existingEntry.cash += Number(data.cash) || 0;
  } else {
    // Create a new record if it doesn't exist
    batchIncomeArray.push({
      bankId: bankId,
      batchBankNameId: bankId,  
      batchBankName: bank ? bank.bankName : data.bankName, 
      indBankInterest: 0, 
      indBankCommission: 0, 
      indBatchBankDeposit: Number(data.bankAmt) || 0,
      indBatchBankWithdrawals: 0,
      cash: Number(data.cash) || 0,
      transactionType: 'B (I/E)',
      otherLoanTypeId: ""
    });
  }
});
////////////////////////////calculating the income of respective fields end////////////////////////////////////
  setFormData({ ...formData,...finalIncomeData,bankDetails:bankDetails,memberBankDetails:totalsArray,batchIncomeArray:batchIncomeArray,memberOtherLoanArray:otherLoanArray,OlRepaymentFields:finalResultArray});
  NextBackBtn(5);
  };

  const handleKeyPress = (e) => {
    // Allow only numbers, decimal point, and prevent negative sign
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="row card-new py-3 col-11">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <h5>Income</h5>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">SHG Saving Amount*&nbsp;:&nbsp;</label>

        <div className="row col-12">
          <div className="col-lg-6">
            Bank
            <input
              type="number"
              name="batchSavingAmtBank"
              value={batchSavingAmtBank}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              disabled
              required
            />
          </div>
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSavingAmtCash"
              value={batchSavingAmtCash}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              disabled
              required
            />
          </div>
        </div>
        <label className="label-control">Subscription*&nbsp;:&nbsp;</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank
            <input
              type="number"
              name="batchSubAmtBank"
              value={batchSubAmtBank}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              disabled
              required
            />
          </div>
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSubAmtCash"
              value={batchSubAmtCash}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              disabled
              required
            />
          </div>
        </div>
        <label className="label-control">Other Contribution&nbsp;:&nbsp;</label>
          {/* 1 */}
        <div className="row col-12">
         <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={otherContributionBankName}
              onChange={(e) => setsOtherContributionBankName(e)}
              isSearchable={true}
              isDisabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          {batchOtherContributionCash != 0 || batchOtherContributionBank !=0 ? (
             <div className="col-lg-6">
             Bank{" "}
             
             <input
               type="number"
               name="batchOtherContributionBank"
               value={batchOtherContributionBank}
               className="form-control"
               onChange={(e)=>setNewBatchOtherContributionBank(e.target.value)}
               onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
               onKeyDown={(e) => funcKeyDown(e)}
               onKeyPress={handleKeyPress}
               disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ?  true:  otherContributionBankName === "" ? true : false}             />
           </div>
          ) : (
            <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchOtherContributionBank"
              value={newBatchOtherContributionBank}
              className="form-control"
              onChange={(e)=>setNewBatchOtherContributionBank(e.target.value)}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              onKeyPress={handleKeyPress}
              onKeyDown={(e) => funcKeyDown(e)}
              // onScroll={false}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: otherContributionBankName === "" ? true : false}
            />
          </div>
          )}
         
        </div>
        <div className="col-lg-6">
          {batchOtherContributionCash != 0 || batchOtherContributionBank !=0  ? ( 
          <div className="col-lg-12">
           Cash{" "}
            <input
              type="number"
              name="batchOtherContributionCash"
              value={batchOtherContributionCash}
              className="form-control"
              onChange={(e)=>setBatchOtherContributionCash(e.target.value)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              onKeyDown={(e) => funcKeyDown(e)}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}            />
           </div>) : (
             <div className="col-lg-12">
             Cash{" "}
              <input
                type="number"
                name="batchOtherContributionCash"
                value={newBatchOtherContributionCash || 0}
                className="form-control"
                disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}
                onChange={(e)=>setBatchOtherContributionCash(e.target.value)}
                onKeyPress={handleKeyPress}
                onKeyDown={(e) => funcKeyDown(e)}
                onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
              />
             </div>
           ) }
          
          </div>
         
        <label className="label-control">Membership Fees&nbsp;:&nbsp;</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchMembershipFeesBank"
              value={batchMembershipFeesBank}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyPress={handleKeyPress}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchMembershipFeesCash"
              value={batchMembershipFeesCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyPress={handleKeyPress}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <label className="label-control">
          IL Repayment to SHG&nbsp;
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Internal Loan Repayment to SHG from the Members"
          />&nbsp;:
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchLoanPaidBank"
              value={batchLoanPaidBank}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchLoanPaidCash"
              value={batchLoanPaidCash}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
            />
          </div>
        </div>
        <label className="label-control">
          SD Union To SHG&nbsp;
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Security Deposit Amount Received from the Union to the SHG
            "
          />&nbsp;:
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={sdUnionToSHGBankName}
              onChange={(e) => setsdUnionToSHGBankName(e)}
              isSearchable={true}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="sdUnionToSHGBank"
              value={sdUnionToSHGBank}
              disabled={sdUnionToSHGBankName ? false : true}
              onChange={(e) => {
                setsdUnionToSHGBank(e.target.value);
              }}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyPress={handleKeyPress}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="sdUnionToSHGCash"
              value={sdUnionToSHGCash}
              onChange={(e) => setsdUnionToSHGCash(e.target.value)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyPress={handleKeyPress}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">
          IL Interest Paid to SHG&nbsp;
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Internal Loan Interest Repayment to SHG from the Members"
          />&nbsp;:
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchInterestPaidBank"
              value={batchInterestPaidBank}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchInterestPaidCash"
              value={batchInterestPaidCash}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
            />
          </div>
        </div>
{/* 2 */}
        <label className="label-control">Other Income&nbsp;:&nbsp;</label>
        <div className="row col-12">
        <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={otherIncomeBankName}
              onChange={(e) => setsOtherIncomeBankName(e)}
              isSearchable={true}
              isDisabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank{" "}
            {(batchOtherIncomeBank != 0 || batchOtherIncomeCash != 0) ? ( 
              <input
              type="number"
              name="batchOtherIncomeBank"
              value={batchOtherIncomeBank}
              className="form-control"
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: otherIncomeBankName === "" ? true :false}              // onChange={(e)=>setBatchOtherIncomeBank(e.target.value)}
              onKeyPress={handleKeyPress}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />) : (
              <input
              type="number"
              name="batchOtherIncomeBank"
              value={newBatchOtherIncomeBank}
              className="form-control"
              onChange={(e)=>setBatchOtherIncomeBank(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: otherIncomeBankName === "" ? true : false}
            />
            ) }
           
          </div>
        </div>
        <div className="row col-6">
        <div className="col-lg-12">
            Cash{" "}
            {batchOtherIncomeBank != 0 || batchOtherIncomeCash != 0 ? (  
              <input
              type="number"
              name="batchOtherIncomeCash"
              value={batchOtherIncomeCash}
              className="form-control"
              onKeyPress={handleKeyPress}
              // onChange={(e)=>setBatchOtherIncomeCash(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}
              onKeyDown={(e) => funcKeyDown(e)}
            />) :(  <input
              type="number"
              name="batchOtherIncomeCash"
              value={newBatchOtherIncomeCash || 0}
              className="form-control"
              onKeyPress={handleKeyPress}
              onChange={(e)=>setBatchOtherIncomeCash(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}
            />) }
          
          </div>
        </div>
        {/* 3 */}
        <label className="label-control">
          Seed Money&nbsp;
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Government Grant For Shg Development (Non-Returnable)
            "
          />&nbsp;:
        </label>
        <div className="row col-12">
        <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={seedMoneyBankName}
              onChange={(e) => setsSeedMoneyBankName(e)}
              isSearchable={true}
              isDisabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>

          <div className="col-lg-6">
            Bank{" "}
            {seedMoneyBank !=0 || seedMoneyCash != 0 ? ( 
              <input
              type="number"
              name="seedMoneyBank"
              value={seedMoneyBank}
              className="form-control"
              // onChange={(e)=>setSeedMoneyBank(e.target.value)}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true:seedMoneyBankName === "" ?true : false}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
            />) :( <input
              type="number"
              name="seedMoneyBank"
              value={newSeedMoneyBank}
              className="form-control"
              onChange={(e)=>setSeedMoneyBank(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
               disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true:seedMoneyBankName === "" ? true : false}
            />) }
           
          </div>
        </div>
        <div className="row col-6">
        <div className="col-lg-12">
            Cash{" "}
            {seedMoneyBank !=0 || seedMoneyCash != 0 ? ( 
              <input
              type="number"
              name="seedMoneyCash"
              value={seedMoneyCash}
              className="form-control"
              onKeyPress={handleKeyPress}
              // onChange={(e)=>setSeedMoneyCash(e.target.value)}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />):( 
            <input
              type="number"
              name="seedMoneyCash"
              value={newSeedMoneyCash || 0}
              className="form-control"
              onChange={(e)=>setSeedMoneyCash(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
              disabled={(formData.institutionId !== "63c0e52501d22dcc13f12c32") ? true: false}

            />)}
           
          </div>
        </div>
        <label className="label-control">
          SD Member To SHG&nbsp;
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Security Deposit Amount Collected from the Members to SHG"
          />&nbsp;:
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchSecurityDepositMemToShgBank"
              value={batchSecurityDepositMemToShgBank}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchSecurityDepositMemToShgCash"
              value={batchSecurityDepositMemToShgCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyPress={handleKeyPress}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <label className="label-control">
          Deactive Member charity Amount&nbsp;
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Any Amount Received from the Deactivated Member"
          />
          &nbsp;:
        </label>
        <div className="container">
          <div className="row">
            <div className="col-lg-6  d-flex flex-column">
              <label htmlFor="bankList">Bank List</label>
              <Select
                name="bankList"
                options={activeBank}
                value={deactiveSavingMemberAmtBankName}
                onChange={(e) => setdeactiveSavingMemberAmtBankName(e)}
                isSearchable={true}
                placeholder="Select Bank"
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            <div className="col-lg-6  d-flex flex-column">
              <label htmlFor="deactiveMemberSavingBank">Bank Amount</label>
              <input
                type="number"
                name="deactiveMemberSavingBank"
                value={deactiveSavingMemberAmtBank}
                onChange={(e) => {
                  setdeactiveSavingMemberAmtBank(e.target.value);
                }}
                className="form-control"
                disabled={deactiveSavingMemberAmtBankName ? false : true}
                onWheel={() => document.activeElement.blur()}
                // disabled
                onKeyPress={handleKeyPress}
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6  d-flex flex-column">
              <label htmlFor="deactiveMemberSavingCash">Cash</label>
              <input
                type="number"
                name="deactiveSavingMemberAmtCash"
                value={deactiveSavingMemberAmtCash}
                onChange={(e) => {
                  setdeactiveSavingMemberAmtCash(e.target.value);
                }}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                // disabled
                onKeyPress={handleKeyPress}
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
          </div>
        </div>

        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <>
            <label className="label-control">Loan Repaid By Other SHG :</label>
            <div className="row col-12">
              <div className=" col-lg-6">
              <label htmlFor="bankList">Bank List</label>
              <Select
                name="bankList"
                options={activeBank}
                value={loanRepaidByOtherSHGBankName}
                onChange={(e) => setloanRepaidByOtherSHGBankName(e)}
                isSearchable={true}
                placeholder="Select Bank"
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
              </div>
              <div className="col-lg-6">
                Bank{" "}
                <input
                  type="number"
                  name="loanRepaidByOtherSHGBank"
                  value={loanRepaidByOtherSHGBank}
                  onChange={(e) => {
                    setloanRepaidByOtherSHGBank(e.target.value);
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  
                  onKeyPress={handleKeyPress}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-6">
                Cash
                <input
                  type="number"
                  name="loanRepaidByOtherSHGCash"
                  value={loanRepaidByOtherSHGCash}
                  onChange={(e) => setloanRepaidByOtherSHGCash(e.target.value)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  
                  onKeyPress={handleKeyPress}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <input
          // onClick={() => NextBackBtn(4)}
          onClick={() => incomeNextTab()}
          type="button"
          name="submit"
          value="Next"
          className="btn sub_form btn_continue Save float-right"
        />
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(3)}
        >
          Previous
        </button>
      </div>
    </div>
  );
};

export default IncomeTab;
