import React, { Fragment, useState, useEffect,useRef } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  Modal,
  // NavDropdown,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { logout, getInstitutionData,setLanguage } from "../../actions/auth";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook

import Spinner from "../layout/Spinner";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Header = ({
  auth: { isAuthenticated, loading, user, institutionData,language },
  logout,
  getInstitutionData,
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      getInstitutionData();
    }
  }, [getInstitutionData]);
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [lang,setLang] = useState("English");

  const onChangeLanguage = (lang)=>{
    setSelectedLanguage(lang)
    switch (lang) {
      case "en":
        setLang("English");
        handleLanguageChange("en")
        break;
        case "hn":
          setLang("Hindi");
          handleLanguageChange("hn")
          break;
          case "kn":
            setLang("Kannada");
            handleLanguageChange("kn")
            break;
            case "or":
              setLang("Oriya");
              handleLanguageChange("or")
              break;
              case "ta":
                setLang("Tamil");
                handleLanguageChange("ta")
        break;
    
      default:
        break;
    }
  }

  const handleLanguageChange = (event) => {
    const language = event;
    setSelectedLanguage(language);
    i18n.changeLanguage(language); // Change language using i18next instance
    setLanguage(language);
  };

  const LoginMethods = [
    { value: "Co-ordinator", label: "Co-ordinator" },
    { value: "Incharge", label: "Incharge" },
  ];

  let loginTypeVal = localStorage.getItem("loginType");
  const [formData, setFormData] = useState({
    LoginType: loginTypeVal
      ? { value: loginTypeVal, label: loginTypeVal }
      : LoginMethods[0],
    isSubmitted: false,
  });

  const { LoginType, isSubmitted } = formData;
  let location = useLocation();
  const onLoginTypeChange = (e) => {
    localStorage.setItem("loginType", e.value);
    if (e) {
      setFormData({
        ...formData,
        LoginType: e,
      });
    }
    window.location.reload();
  };


  const [showLogout, setShowLogout] = useState(false);

  const handleLogoutModalClose = () => setShowLogout(false);
  const handleLogoutModalShow = () => setShowLogout(true);

  const LogoutModalClose = () => {
    setFormData({
      ...formData,
      LoginType: LoginMethods[0],
    });
    handleLogoutModalClose();
    logout();
  };

  const closeMenuForMobile = (openClose) => {
    // if (openClose === "isOpen") {
    //   if (window.innerWidth <= 992) {
    //     var element = document.getElementById("basic-navbar-nav");
    //     element.classList.remove("show");
    //     var element = document.getElementById("nav-toggler");
    //     element.classList.add("collapsed");
    //   }
    // } else if (openClose === "isClosed") {
    //   if (window.innerWidth <= 992) {
    //     var element1 = document.getElementById("nav-toggler");
    //     element1.classList.remove("collapsed");
    //     var element = document.getElementById("basic-navbar-nav");
    //     element.classList.add("show");
    //   }
    // }
  };
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allAccess = [];
  institutionData &&
    institutionData.access &&
    institutionData.access.map((access) => allAccess.push(access.accessName));

  const allRights = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.groupRights &&
    loggedUserInstitutionData.groupRights.map((groupRights) =>
      allRights.push(groupRights)
    );


    const [languageModal, showLanguageModal] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
      const tooltipRef = useRef(null);
    
      // Close tooltip if clicked outside
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (tooltipRef.current) {
            showLanguageModal(false);
          
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

  return !isAuthenticated || !user ? (
    (location.pathname != "/" && location.pathname != "/terms-conditions" && location.pathname != "/privacy-policy") ? 
    <Spinner /> :<></>
    
  ) : (
    <Fragment>
      <header>
        <Container id="header_navbar">
          <Navbar
            className="navbar_height top_menu sidebar"
            expand="lg"
            collapseOnSelect
            style={{ padding: "1em 1em" }}
          >
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              id="nav-toggler"
              onClick={() => closeMenuForMobile("isClosed")}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto ">
                <NavItem
                  style={{
                    backgroundColor: "",
                    overflowY: "scroll",
                    maxHeight: "800px",
                  }}
                >
                  {!loading && isAuthenticated && user ? (
                    <Fragment>
                      <Navbar.Brand>
                        <Link to="/my-profile">
                          <img
                            className="log_size"
                            alt=""
                            src={`${institutionData.institutionProfile}`}
                          />
                        </Link>
                      </Navbar.Brand>
                      <NavLink
                        to="/my-profile"
                        activeStyle={{
                          color: "#dffd35",
                          fontSize: "17px",
                          textDecoration: "none",
                        }}
                      >
                        {user.userFullName}
                      </NavLink>
                      {user.userGroupName === "Co-ordinator" &&
                        user.isIncharge === true && (
                          <Select
                            styles={{
                              control: (base) => ({
                                ...base,
                                background: "#144c5a",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#fff",
                              }),
                              input: (base) => ({
                                ...base,
                                color: "#fff",
                              }),
                            }}
                            name="LoginType"
                            options={LoginMethods}
                            isSearchable={false}
                            value={LoginType}
                            placeholder="Select"
                            onChange={(e) => onLoginTypeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        )}

                      {((allAccess && allAccess.includes("shg")) ||
                        user.userGroupName === "Super Admin") && (
                        <NavLink
                          to="/landing-page"
                          onClick={() => closeMenuForMobile("isOpen")}
                          activeStyle={{
                            color: "#dffd35",
                            fontSize: "17px",
                            textDecoration: "none",
                          }}
                        >
                          {t("Dashboard")}
                        </NavLink>
                      )}
                      {/* ////////////////////shg access start///////////////////////// */}

                   {allAccess && allAccess.includes("shg") && (
                        <>
                          {(user.userGroupName === "Super Admin" ||
                            user.userGroupName === "Admin" ||
                            user.userGroupName === "Incharge" ||
                            user.userGroupName === "Co-ordinator") && (
                            <NavLink
                              onClick={() => {
                                localStorage.removeItem("selBatchDetails");
                                localStorage.removeItem("PageIndex");
                              }}
                              to="/all-batches"
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Batches
                            </NavLink>
                          )}
                          {user.userGroupName === "Member" && (
                            <NavLink
                              to={{
                                pathname:
                                  user && user.userGroupName === "Member"
                                    ? "/member-all-members"
                                    : "/all-members",
                              }}
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              All Members
                            </NavLink>
                          )}
                          {(user.userGroupName === "Admin" ||
                            user.userGroupName === "Incharge" ||
                            user.userGroupName === "Co-ordinator") && (
                            <NavLink
                              onClick={() => {
                                localStorage.removeItem("selVerfyBatchDetails");
                              }}
                              to="/all-meetings-verification"
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Verify
                            </NavLink>
                          )}
                          {(user.userGroupName === "Admin" ||
                            user.userGroupName === "Incharge" ||
                            user.userGroupName === "Auditor" ||
                            user.userGroupName === "Co-ordinator") && (
                            <NavLink
                              to="/all-reports"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Reports
                            </NavLink>
                          )}
 <NavLink
                            to="/All-SHG-Category"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            SHG Category
                          </NavLink>                          {(user.userGroupName === "Super Admin" ||
                            user.userGroupName === "Admin") && (
                            <NavLink
                              to="/all-users"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              All User
                            </NavLink>
                          )}
                          {(user.userGroupName === "Super Admin" ||
                            user.userGroupName === "Admin") && (
                            <>
                              <NavLink
                                to="/all-area"
                                onClick={() => closeMenuForMobile("isOpen")}
                                activeStyle={{
                                  color: "#dffd35",
                                  fontSize: "17px",
                                  textDecoration: "none",
                                }}
                              >
                                Area
                                </NavLink>
                            </>
                          )}
                        </>
                      )}

                      {/* ////////////////////shg access end//////////////////// */}

                      {/* /////////////////////////new codee start//////////////////////////////////// */}
                      {allPermission && allPermission.includes("Religion") && (
                        <NavLink
                          to="/all-religion-caste-types"
                          onClick={() => closeMenuForMobile("isOpen")}
                          activeStyle={{
                            color: "#dffd35",
                            fontSize: "17px",
                            textDecoration: "none",
                          }}
                        >
                          Caste Setting
                        </NavLink>
                      )}

                      <NavLink
                        to="/All-OtherLoan-Types"
                        onClick={() => closeMenuForMobile("isOpen")}
                        activeStyle={{
                          color: "#dffd35",
                          fontSize: "17px",
                          textDecoration: "none",
                        }}
                      >
                        Other Loan Types
                      </NavLink>

                      {allPermission && allPermission.includes("Occupation") && (
                        <NavLink
                          to="/all-occupation-setting"
                          onClick={() => closeMenuForMobile("isOpen")}
                          activeStyle={{
                            color: "#dffd35",
                            fontSize: "17px",
                            textDecoration: "none",
                          }}
                        >
                          Occupations
                        </NavLink>
                      )}
                      {/* ////////////////////////new code end/////////////////////////////////////////////// */}
                      {user.userGroupName === "Super Admin" && (
                        <>
                          <NavLink
                            to="/all-access"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            All Access
                          </NavLink>
                          <NavLink
                            to="/all-Permission"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            All Permissions
                          </NavLink>
                        </>
                      )}
                      {allPermission &&
                      allPermission.includes("SingleSkill") ? (
                        <>
                          <NavLink
                            onClick={() => {
                              localStorage.removeItem("selBatchDetails");
                              localStorage.removeItem("PageIndex");
                            }}
                            to="/all-batches"
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Batches
                          </NavLink>
                          <NavLink
                            to="/all-reports"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Reports
                          </NavLink>
                          <NavLink
                            to="/all-users"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            All User
                          </NavLink>
                          <NavLink
                            to="/all-area"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Area
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}
                      {allAccess &&
                        allAccess.includes("skill") &&
                        (user.userGroupName === "Super Admin" ||
                          user.userGroupName === "Admin" ||
                          user.userGroupName === "Incharge" ||
                          user.userGroupName === "Co-ordinator") && (
                          <>
                            <NavLink
                              to="/all-programs"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              All Programs
                            </NavLink>
                            <NavLink
                              to="/add-skill-training"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Add Skill Training
                            </NavLink>
                            {/* <NavLink
                              to="/skill-training-report"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Skill Report
                            </NavLink> */}
                          </>
                        )}

                      <NavLink
                        to="/change-password"
                        onClick={() => closeMenuForMobile("isOpen")}
                        activeStyle={{
                          color: "#dffd35",
                          fontSize: "17px",
                          textDecoration: "none",
                        }}
                      >
                        Change Password
                      </NavLink>

                      {allRights &&
                      allRights.includes(
                        user.userGroupName + "TrainingVideo"
                      ) ? (
                        <>
                          <NavLink
                            to="/shgLangList"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Training Videos
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}

                      <NavLink to="#" onClick={() => handleLogoutModalShow()}>
                        <span className="mb-4">Logout</span>
                      </NavLink>

                      <div
                        className="copyRightCss my-2 p-2"
                        style={{ borderTop: "1px solid #fff" }}
                      >
                        {allPermission &&
                        allPermission.includes("Multi-Language") ? (
                          <NavLink
                            to="#"
                            onClick={() => showLanguageModal(true)}
                            className="navLink"
                          >
                            <div className="d-flex">
                              <div className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5M10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 9.01509 2.69399 8.03982 3.0709 7.12987C3.44781 6.21993 4.00026 5.39314 4.6967 4.6967C5.39314 4.00026 6.21993 3.44781 7.12987 3.0709C8.03982 2.69399 9.01509 2.5 10 2.5M10 17.5C12.3008 17.5 13.2842 13.1975 13.2842 10C13.2842 6.8025 12.3008 2.5 10 2.5M10 17.5C7.69917 17.5 6.71583 13.1975 6.71583 10C6.71583 6.8025 7.69917 2.5 10 2.5M2.91667 7.5H17.0833M2.91667 12.5H17.0833"
                                    stroke="white"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              &nbsp;
                              <div className="">
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {lang}
                                </span>
                              </div>
                              <div className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M13.3201 8.51996L10.0001 11.84L6.68012 8.51996L6.12012 9.07996L10.0001 12.96L13.8801 9.07996L13.3201 8.51996Z"
                                    fill="#F9F9F9"
                                  />
                                </svg>
                              </div>
                            </div>
                          </NavLink>
                        ) : (
                          <></>
                        )}
                        &nbsp;
                        <NavLink className="navLink" to="#">
                          <span>
                            &copy; {new Date().getFullYear()} &nbsp;
                            <a
                              href="https://www.pinnacletechnologies.in"
                              target="_blank"
                              className="linkCopyRight"
                            >
                              Pinnacle Technologies
                            </a>
                            .<br /> All rights reserved.
                          </span>
                        </NavLink>
                        </div>
                    </Fragment>
                  ) : (
                    <NavItem></NavItem>
                  )}
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>

        {/* Logout Modal */}
        <Modal
          show={showLogout}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="logout-modal"
        >
          <Modal.Header className="confirmbox-heading">
            <h4 className="mt-0">Confirmation</h4>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to logout?</h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn_green_bg"
              onClick={() => LogoutModalClose()}
            >
              YES
            </button>
            <button
              className="btn btn_green_bg"
              onClick={() => handleLogoutModalClose()}
            >
              NO
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          ref={tooltipRef}
          show={languageModal}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="bottom-modal"
        >
          <Modal.Body className="languageModal">
            <label
              style={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "17px",
                textDecoration: "none",
              }}
            >
              Choose Your Language
            </label>
            <ul className="languageList">
              <li
                value="en"
                style={{
                  cursor: "pointer",
                  listStyleType: "none",
                  color: "black",
                  fontSize: "17px",
                }}
                onClick={() => onChangeLanguage("en")}
              >
                English
              </li>
              <li
                value="hn"
                style={{
                  cursor: "pointer",
                  listStyleType: "none",
                  color: "black",
                  fontSize: "17px",
                }}
                onClick={() => onChangeLanguage("hn")}
              >
                Hindi
              </li>
              <li
                value="kn"
                style={{
                  cursor: "pointer",
                  listStyleType: "none",
                  color: "black",
                  fontSize: "17px",
                }}
                onClick={() => onChangeLanguage("kn")}
              >
                Kannada
              </li>
              <li
                value="or"
                style={{
                  cursor: "pointer",
                  listStyleType: "none",
                  color: "black",
                  fontSize: "17px",
                }}
                onClick={() => onChangeLanguage("or")}
              >
                Oriya
              </li>
              <li
                value="ta"
                style={{
                  cursor: "pointer",
                  listStyleType: "none",
                  color: "black",
                  fontSize: "17px",
                }}
                onClick={() => onChangeLanguage("ta")}
              >
                Tamil
              </li>
            </ul>
          </Modal.Body>
        </Modal>
      </header>
    </Fragment>
  );
};
Header.propTypes = {
  logout: PropTypes.func.isRequired,
  getInstitutionData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logout,
  getInstitutionData,
  setLanguage,
})(Header);
