import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import {
  getMemberCount,
  getBatchSavingAmountCount,
  batchAndMeetingDetails,
  getInchargeCount,
  getCordinatorCount,
  getOtherLoanTypeCount,
  getYearcount,
  getAllBatchesCount,
} from "../../actions/shg";
import { useTranslation } from 'react-i18next';    

const LandingPage = ({
  auth: { expReport, isAuthenticated, user, userBatchData },
  shg: {
    memberCount,
    memberGenCount,
    batchCount,
    batchsavingamountcount,
    inchargeCount,
    cordinatorCount,
    otherLoanTypeCount,
    allActiveBatches,
    allDeactiveBatches,
  },

  getMemberCount,
  getBatchSavingAmountCount,
  batchAndMeetingDetails,
  getInchargeCount,
  getCordinatorCount,
  getOtherLoanTypeCount,
  getYearcount,
  getAllBatchesCount,
}) => {
  useEffect(() => {
    getMemberCount();
  }, []);
  useEffect(() => {
    getAllBatchesCount();
  }, [getAllBatchesCount]);

  useEffect(() => {
    getBatchSavingAmountCount();
  }, []);
  useEffect(() => {
    getInchargeCount();
  }, []);
  useEffect(() => {
    getCordinatorCount();
  }, []);
  useEffect(() => {
    getOtherLoanTypeCount();
  }, []);
  useEffect(() => {
    getYearcount({
      institutionId: user && user.institutionId,
    });
  }, []);


    const { t } = useTranslation(); // Get the translation function `t` from i18next

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  let loggedInstAggrementData = JSON.parse(
    localStorage.getItem("loggedInstAggrementData")
  );
  // localStorage.setItem("selBatchDetails", JSON.stringify(""));
  let loggedMemberBatch = JSON.parse(localStorage.getItem("loggedMemberBatch"));

  const [allActiveBatchCount, setallActiveBatcheCount] = useState(
    allActiveBatches.length
  );
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmittedStatus, setSubmittedStatus] = useState(false);

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  useEffect(() => {}, [allPermission]);

  const handleGoToAddMemberMeeting = () => {
    const batchIdData = {
      batchIdVal: user.userBatchId,
    };
    batchAndMeetingDetails(batchIdData);
    setSubmitted(true);
  };
  const handleGoToAddBatchMeeting = () => {
    const batchIdData = {
      batchIdVal: user.userBatchId,
      batchNameVal: loggedMemberBatch && loggedMemberBatch.batchName,
    };
    batchAndMeetingDetails(batchIdData);
    setSubmittedStatus(true);
  };
  //To open modal for expiry

  //For setting maxdate,todays + 30 days date
  const date2 = new Date();
  date2.setDate(date2.getDate() + 30);

  var expMatchDate = new Date(date2).getTime();
  const InstAggrementEndDt =
    loggedInstAggrementData && loggedInstAggrementData.aggrementEndDate;
  var InstAggrementEndDate = new Date(InstAggrementEndDt).getTime();

  //For setting mindate as todays date
  const [showInstExpiryModal, setShowInstExpiryModal] = useState(false);
  const [showInstExpiryCloseModal, setShowInstExpiryCloseModal] = useState(
    false
  );

  if (
    !showInstExpiryModal &&
    !showInstExpiryCloseModal &&
    InstAggrementEndDate &&
    user &&
    user.userGroupName === "Admin" &&
    expMatchDate >= InstAggrementEndDate
  ) {
    setShowInstExpiryModal(true);
    setShowInstExpiryCloseModal(true);
  }

  const handleExpiryModalModalClose = () => setShowInstExpiryModal(false);
  //To open modal for expiry ends
  let Male = 0,
    Female = 0,
    Other = 0,
    NotMentioned = 0;
  memberGenCount &&
    memberGenCount.map((GenCount) => {
      if (GenCount._id === "Male") Male = GenCount.count;
      else if (GenCount._id === "Female") Female = GenCount.count;
      else if (GenCount._id === "Other") Other = GenCount.count;
      else NotMentioned += GenCount.count;
    });
  if (isSubmitted) {
    return <Redirect to="/add-membermeeting-details" />;
  }

  if (isSubmittedStatus) {
    localStorage.removeItem("selMemBatchMeetingDetails");
    let url = "";
    user && user.userGroupName === "Member"
      ? loggedMemberBatch &&
        loggedMemberBatch.batchTransactionType !== "Batchwise"
        ? (url = "/add-memberwise-batchdetails-meeting")
        : (url = "/add-batchmeeting-details")
      : (url = "/select-add-meeting");
    return <Redirect to={url} />;
  }

  return !isAuthenticated || !user || allPermission.length == 0 ? (
    <Spinner />
  ) : (
    <>
      {allPermission.length > 0 ? (
        <>
          {allPermission.includes("SingleSkill") ? (
            <>
              <Redirect to="/all-reports" />
            </>
          ) : (
            <Fragment>
              <div className="container container_align">
                <div className="col-lg-11 col-md-11 col-sm-12 col-12">
                  <h2 className="heading_color">{t("Dashboard")} </h2>
                  <hr />
                </div>
                <section className="sub_reg">
                  {user.userGroupName !== "Super Admin" && (
                    <Fragment>
                      {user.userGroupName !== "Auditor" && (
                        <div className="row col-lg-11 col-md-11 col-sm-12 col-12 ">
                          <div className="col-lg-4 col-md-6 col-sm-12 col-12 py-2">
                            <div className="card card-content ">
                              <Link
                                to={"#"}
                                onClick={() => handleGoToAddBatchMeeting()}
                              >
                                <center>
                                  <img
                                    className="img_icon_size3 log"
                                    src={require("../../static/images/add-icon.png")}
                                    alt="SHG meeting"
                                    title="SHG meeting"
                                  />
                                  <h3>SHG Meeting </h3>
                                </center>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 col-12 py-2">
                            {(loggedMemberBatch &&
                              loggedMemberBatch.batchTransactionType !==
                                "Batchwise") ||
                            (user && user.userGroupName !== "Member") ? (
                              <div className="card card-content ">
                                {user && user.userGroupName === "Member" ? (
                                  <Link
                                    to={"#"}
                                    onClick={() => handleGoToAddMemberMeeting()}
                                  >
                                    <center>
                                      {/* /add-membermeeting-details */}
                                      <img
                                        className="img_icon_size3 log"
                                        src={require("../../static/images/add-icon.png")}
                                        alt="Member meeting"
                                        title="Member meeting"
                                      />
                                      <h3>Member Meeting </h3>
                                    </center>
                                  </Link>
                                ) : (
                                  <Link
                                    to={"/member-meeting"}
                                    onClick={localStorage.removeItem(
                                      "selbatchAndMeetingDetails"
                                    )}
                                  >
                                    <center>
                                      <img
                                        className="img_icon_size3 log"
                                        src={require("../../static/images/add-icon.png")}
                                        alt="Member meeting"
                                        title="Member meeting"
                                      />
                                      <h3>Member Meeting </h3>
                                    </center>
                                  </Link>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-12 col-12 py-2">
                            {(loggedMemberBatch &&
                              loggedMemberBatch.batchTransactionType !==
                                "Batchwise") ||
                            (user && user.userGroupName !== "Member") ? (
                              <div className="card card-content ">
                                <Link
                                  to="/all-partial-meetings"
                                  onClick={localStorage.removeItem(
                                    "selParMeetingDetails"
                                  )}
                                >
                                  <center>
                                    <img
                                      className="img_icon_size3 log"
                                      src={require("../../static/images/add-icon.png")}
                                      alt="Partial meeting"
                                      title="Partial meeting"
                                    />
                                    <h3>Partial Member Meeting </h3>
                                  </center>
                                </Link>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="row col-lg-11 col-md-11 col-sm-12 col-12 py-3">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 py-2 ">
                          {user && user.userGroupName !== "Member" ? (
                            <div className="card ">
                              <Link
                                to="/all-batches"
                                onClick={() => {
                                  localStorage.removeItem("selBatchDetails");
                                }}
                              >
                                <center>
                                  <h3>{batchCount ? batchCount : 0}</h3>
                                  <h4>
                                    {" "}
                                    Active SHG &nbsp;
                                    <img
                                      className="img_icon_size log mb-1"
                                      src={require("../../static/images/info.png")}
                                      alt="Govt Cards"
                                      // title="Active"
                                      title={
                                        "Active -" +
                                        allActiveBatches.length +
                                        ",Inactive -" +
                                        allDeactiveBatches.length
                                      }
                                    />
                                  </h4>
                                </center>
                              </Link>
                            </div>
                          ) : (
                            <div className="card ">
                              <center>
                                <h3>{batchCount ? batchCount : 0}</h3>
                                <h3>SHG</h3>
                              </center>
                            </div>
                          )}
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 py-2">
                          {user && user.userGroupName === "Member" ? (
                            <div className="card">
                              <Link to="/member-all-members">
                                <center>
                                  <h3>
                                    Members-
                                    {memberCount &&
                                    memberCount[0] &&
                                    memberCount[0].NOM
                                      ? memberCount[0].NOM
                                      : 0}
                                  </h3>
                                </center>
                                <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <center>
                                      <h6 className="my-0">M-{Male}</h6>
                                      <h6 className="my-0">O-{Other}</h6>
                                    </center>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <h6 className="my-0">F-{Female}</h6>
                                    <h6 className="my-0">NM-{NotMentioned}</h6>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            <div className="card ">
                              <center>
                                <h3>
                                  {memberCount &&
                                  memberCount[0] &&
                                  memberCount[0].NOM
                                    ? memberCount[0].NOM
                                    : 0}
                                </h3>
                                <h4>
                                  {" "}
                                  Members &nbsp;
                                  <img
                                    className="img_icon_size log mb-1"
                                    src={require("../../static/images/info.png")}
                                    alt="Govt Cards"
                                    // title="Active"
                                    title={
                                      "M -" +
                                      Male +
                                      ", F -" +
                                      Female +
                                      ", O -" +
                                      Other +
                                      ", NM -" +
                                      NotMentioned
                                    }
                                  />
                                </h4>
                              </center>
                              {/* <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <center>
                                  <h6 className="my-0">M- {Male}</h6>
                                  <h6 className="my-0">O- {Other}</h6>
                                </center>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <center>
                                  <h6 className="my-0">F- {Female}</h6>
                                  <h6 className="my-0">NM- {NotMentioned}</h6>
                                </center>
                              </div> */}
                              {/* </div> */}
                            </div>
                          )}
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
                          <div className="card ">
                            <center>
                              <h3>
                                {batchsavingamountcount &&
                                batchsavingamountcount[0] &&
                                batchsavingamountcount[0].batchSavingAmt
                                  ? batchsavingamountcount[0].batchSavingAmt.toFixed(
                                      2
                                    )
                                  : 0}
                              </h3>
                              <h3>Savings</h3>
                            </center>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-3 col-sm-12 col-12 py-2">
                          <div className="card ">
                            <center>
                              <h4>
                                {batchsavingamountcount &&
                                batchsavingamountcount[0] &&
                                batchsavingamountcount[0].batchLoanAmt
                                  ? batchsavingamountcount[0].batchLoanAmt.toFixed(
                                      2
                                    )
                                  : 0}
                              </h4>
                              <h4> Outstanding Internal Loan</h4>
                            </center>
                            {/* <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <h6 className="my-0">
                                M-
                                {batchsavingamountcount &&
                                batchsavingamountcount[0] &&
                                batchsavingamountcount[0].batchLoanAmt
                                  ? batchsavingamountcount[0].batchLoanAmt.toFixed(
                                      2
                                    ) - 2500
                                  : 0}
                              </h6>
                              <h6 className="my-0">O-20</h6>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <h6 className="my-0">F-2500</h6>
                              <h6 className="my-0">NM-423</h6>
                            </div>
                          </div> */}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-3 col-sm-12 col-12 py-2">
                          <div className="card ">
                            <center>
                              <h4>
                                {batchsavingamountcount &&
                                batchsavingamountcount[0] &&
                                batchsavingamountcount[0].batchOtherLoanAmt
                                  ? batchsavingamountcount[0].batchOtherLoanAmt.toFixed(
                                      2
                                    )
                                  : 0}
                              </h4>
                              <h4>Outstanding Other Loan</h4>
                            </center>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-3 col-sm-12 col-12 py-2">
                          <div className="card ">
                            <center>
                              <h4>
                                {batchsavingamountcount &&
                                batchsavingamountcount[0] &&
                                batchsavingamountcount[0].batchLoanAmount
                                  ? batchsavingamountcount[0].batchLoanAmount.toFixed(
                                      2
                                    )
                                  : 0}
                              </h4>
                              <h4> Internal Loan Given</h4>
                            </center>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-3 col-sm-12 col-12  py-2">
                          <div className="card ">
                            <center>
                              <h4>
                                {batchsavingamountcount &&
                                batchsavingamountcount[0] &&
                                batchsavingamountcount[0]
                                  .otherLoanSanctionedToMember
                                  ? batchsavingamountcount[0].otherLoanSanctionedToMember.toFixed(
                                      2
                                    )
                                  : 0}
                              </h4>
                              <h4> Other Loan Given</h4>
                            </center>
                          </div>
                        </div>
                        {user && user.userGroupName === "Admin" ? (
                          <>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
                              <div className="card ">
                                <center>
                                  <h3>
                                    {inchargeCount && inchargeCount
                                      ? inchargeCount
                                      : 0}
                                  </h3>
                                  <h3>Incharges</h3>
                                </center>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
                              <div className="card ">
                                <center>
                                  <h3>
                                    {cordinatorCount && cordinatorCount
                                      ? cordinatorCount
                                      : 0}
                                  </h3>
                                  <h3>Co-ordinators</h3>
                                </center>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
                              <div className="card ">
                                <center>
                                  <h3>
                                    {otherLoanTypeCount && otherLoanTypeCount
                                      ? otherLoanTypeCount
                                      : 0}
                                  </h3>
                                  <h3>Other Loan Types</h3>
                                </center>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Fragment>
                  )}
                  {user.userGroupName === "Super Admin" && (
                    <Fragment>
                      <div className="row col-lg-11 col-md-11 col-sm-12 col-12 py-5">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-2">
                          <div className="card card-content ">
                            <center>
                              <Link to="/all-institution">
                                <img
                                  className="img_icon_size3 log"
                                  src={require("../../static/images/add-icon.png")}
                                  alt="Add User"
                                  title="Add User"
                                />

                                <h3>All Institutions</h3>
                              </Link>
                            </center>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-2">
                          <div className="card card-content ">
                            <center>
                              <Link to="/all-user-groups">
                                {" "}
                                <img
                                  className="img_icon_size3 log"
                                  src={require("../../static/images/add-icon.png")}
                                  alt="Add User"
                                  title="Add User"
                                />
                                <h3>User Groups</h3>
                              </Link>
                            </center>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-2">
                          <div className="card card-content ">
                            <center>
                              <Link to="/all-loan-purpose">
                                {" "}
                                <img
                                  className="img_icon_size3 log"
                                  src={require("../../static/images/add-icon.png")}
                                  alt="Loan Purpose"
                                  title="Loan Purpose"
                                />
                                <h3>All Loan Purpose</h3>
                              </Link>
                            </center>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-2">
                          <div className="card card-content ">
                            <center>
                              <Link to="/all-institution-renewal">
                                {" "}
                                <img
                                  className="img_icon_size3 log"
                                  src={require("../../static/images/add-icon.png")}
                                  alt="Loan Purpose"
                                  title="Loan Purpose"
                                />
                                <h3>Renewals</h3>
                              </Link>
                            </center>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </section>
              </div>
              <Modal
                show={showInstExpiryModal}
                backdrop="static"
                keyboard={false}
                style={{ backgroundColor: "#144c5adb" }} //#144c5a
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">Expiry Alert</h3>
                  </div>
                  <div className="col-lg-2">
                    <button
                      onClick={handleExpiryModalModalClose}
                      className="close"
                    >
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <h3>Hi {user && user.institutionName},</h3>

                  <p>
                    Your membership at Swa Shakthi is about to expire on{" "}
                    {InstAggrementEndDt &&
                      InstAggrementEndDt.split("-")
                        .reverse()
                        .join("-")}
                    . We hope you’ve enjoyed benefits of your membership.
                    <br />
                    Good news! There’s still time to renew, and it’s as easy as
                    ever – just call your respective sales person, pick the
                    subscription that suits your needs and follow the prompts.
                    <br />
                    <br />
                    <span style={{ color: "green" }}>
                      {" "}
                      Please Ignore this if you have already renewed
                    </span>
                    <br />
                    <br />
                    If you have any questions regarding your membership,
                    benefits, or renewal, please don’t hesitate to reach out by
                    replying to joel@pinnacletechnologies.in or call your
                    respective sales person.
                    <br />
                    <br />
                    Thanks,
                    <br />
                    Swa Shakthi, Pinnacle Technologies.
                    <br />
                  </p>
                </Modal.Body>
              </Modal>
            </Fragment>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

LandingPage.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getMemberCount: PropTypes.func.isRequired,
  getBatchSavingAmountCount: PropTypes.func.isRequired,
  batchAndMeetingDetails: PropTypes.func.isRequired,
  getInchargeCount: PropTypes.func.isRequired,
  getCordinatorCount: PropTypes.func.isRequired,
  getOtherLoanTypeCount: PropTypes.func.isRequired,
  getAllBatchesCount: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getMemberCount,
  getBatchSavingAmountCount,
  batchAndMeetingDetails,
  getInchargeCount,
  getCordinatorCount,
  getOtherLoanTypeCount,
  getYearcount,
  getAllBatchesCount,
})(LandingPage);
