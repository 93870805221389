import {
  USER_LOADED,
  AUTH_ERROR,
  REMOVE_ERROR,
  ALL_USERS,
  CHANGE_PWD_FAIL,
  FORGOT_PWD_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  MONTH_EXP_CNT,
  YEAR_EXP_CNT,
  EXP_REPORT,
  GET_ALL_USER,
  SET_LOADING_TRUE,
  FINAL_DATA_REP,
  USER_BATCH,
  INSTITUTION_DATA,
  PARTIAL_MEETING_COUNT,
  UNVERIFIED_MEETING_COUNT,
  SET_LOADING_FALSE,
  OTP_LOGIN_SUCCESS,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: false,
  user: null,
  users: [],
  errorResponse: "",
  successResponse: "",
  language:"en",
  monthExpCnt: [],
  yearExpCnt: [],
  expReport: [],
  finalDataRep: [],
  userBatchData: [],
  institutionData: [],
  partialmeetingcount: [],
  unverifiedmeetingcount: [],
};

const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      localStorage.removeItem("loginType");
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        otpAuthenticated: false,
        loading: false,
        errorResponse: "",
      };

    case OTP_LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        otpAuthenticated: true,
        loading: false,
        errorResponse: "",
        isAuthenticated: true,
      };
      
      case "SET_LANGUAGE":
        return {
          ...state,
          language: payload,
        }
    case FORGOT_PWD_SUCCESS:
      return {
        ...state,
        errorResponse: "",
        successResponse: payload.msg,
        loading: false,
      };

    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.clear();
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorResponse: payload,
        successResponse: "",
      };

    case ALL_USERS:
      return {
        ...state,
        users: payload,
      };

    case REMOVE_ERROR:
      return {
        ...state,
        errorResponse: "",
        successResponse: "",
      };

    case CHANGE_PWD_FAIL:
      return {
        ...state,
        errorResponse: payload,
        successResponse: "",
      };
    case MONTH_EXP_CNT:
      return {
        ...state,
        monthExpCnt: payload,
      };
    case YEAR_EXP_CNT:
      return {
        ...state,
        yearExpCnt: payload,
      };
    case EXP_REPORT:
      return {
        ...state,
        expReport: payload,
      };
    case GET_ALL_USER:
      return {
        ...state,
        allUser: payload,
      };
    case FINAL_DATA_REP:
      return {
        ...state,
        finalDataRep: payload,
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case USER_BATCH:
      return {
        ...state,
        userBatchData: payload,
      };
    case INSTITUTION_DATA:
      return {
        ...state,
        institutionData: payload,
      };
    case PARTIAL_MEETING_COUNT:
      return {
        ...state,
        partialmeetingcount: payload,
      };
    case UNVERIFIED_MEETING_COUNT:
      return {
        ...state,
        unverifiedmeetingcount: payload,
      };
    default:
      return state;
  }
};

export default auth;
