import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  getActiveBankData,
  getIndividualMemberMeetingData,
  getCurMemMettingLoanSanc,
  getParticularLoanReason,
  getActiveOtherLoanType,
  getExistingMemberILData,
  getExistingMemberOLData,
  editMemberMeetingData,
  getMembersMeetingData,
  deleteBankLoanDetail,
  deleteInternalLoanDB,
  deleteOtherLoanDB,
  getMemberSavingTotal,
  getOtherLoanRepaymentDetails
} from "../../../actions/shg";
import LoanRepaymentDetails from "../../layout/LoanRepaymentDetails";
import EditCurLoan from "../../layout/EditCurLoan";
import ShgInternalLoan from "../../layout/ShgInternalLoan";
import LoanPayment from "../../layout/LoanPayment";
import ShgOtherLoan from "../../layout/ShgOtherLoan";
import MemInternalLoanEdit from "./MemInternalLoanEdit";

const EditMemberModel = ({
  memberData,
  allPermission,
  getIndividualMemberMeetingData,
  activeBank,
  handleMemberMeetingModalCloses,
  getCurMemMettingLoanSanc,
  getActiveOtherLoanType,
  getExistingMemberILData,
  getExistingMemberOLData,
  getParticularLoanReason,
  editMemberMeetingData,
  getMembersMeetingData,
  deleteBankLoanDetail,
  deleteInternalLoanDB,
  deleteOtherLoanDB,
  isThisFirstMeeting,
  getMemberSavingTotal,
  totalBankAndCashAmt,
  bankAmount,
  meetingBatchInfo,
  selectedDatesArray,
  getOtherLoanRepaymentDetails,
  shg: {
    memMeetingData,
    curMemMettingLoanSanc,
    loanreason,
    exstMemILData,
    activeOtherLoanTypes,
    exstMemOLData,
    memberSavingTotal,
    olRepaymentData
  },
  auth: { isAuthenticated, user, users },
}) => {

useEffect(()=>{
  getMemberSavingTotal(memberData)
  getOtherLoanRepaymentDetails(memberData)
},[])
////////////////////////////////////////////////otherLoan Repayment Details start////////////////////////
// Extracting data from multiple records
const result =olRepaymentData && olRepaymentData.flatMap((record) =>
  record.otherLoan.map((loan) => ({
    otherLoanTypeId: loan.otherLoanTypeId,
    otherLoanPaid: loan.otherLoanPaid,
    otherInterestPaid: loan.otherInterestPaid,
    otherLoanTakenAmt:loan.otherLoanTakenAmt,
    otherLoanType:loan.otherLoanType,
    meetingHeldOnDate:record.meetingHeldOnDate
  }))
);
const[otherRepaymentDetails,setOtherRepaymentDetails] = useState(result);
////////////////////////////////////////////////otherLoan Repayment Details end///////////////////////////


  useEffect(() => {
    getIndividualMemberMeetingData(memberData);
    getExistingMemberILData(memberData);
    getExistingMemberOLData(memberData);
    getCurMemMettingLoanSanc(memberData);
    getParticularLoanReason();
    getActiveOtherLoanType();
  }, [getIndividualMemberMeetingData]);

  const [editDisabled, setEditDisabled] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedBank, setSelectedbank] = useState(null);
  const[disableBankDropDown,setDisableBankDropDown] = useState(false)
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (memMeetingData) {
      setFormData({
        //INCOME
        savingAmtCash: Number(
          (memMeetingData &&
            memMeetingData.savingAmt &&
            memMeetingData.savingAmt.cash) ||
            0
        ),
        savingAmtBank: Number(
          (memMeetingData &&
            memMeetingData.savingAmt &&
            memMeetingData.savingAmt.bankAmt) ||
            0
        ),

        subscriptionAmtCash: Number(
          (memMeetingData &&
            memMeetingData.subscriptionAmt &&
            memMeetingData.subscriptionAmt.cash) ||
            0
        ),
        subscriptionAmtBank: Number(
          (memMeetingData &&
            memMeetingData.subscriptionAmt &&
            memMeetingData.subscriptionAmt.bankAmt) ||
            0
        ),

        otherIncomeCash: Number(
          (memMeetingData &&
            memMeetingData.otherIncome &&
            memMeetingData.otherIncome.cash) ||
            0
        ),
        otherIncomeBank: Number(
          (memMeetingData &&
            memMeetingData.otherIncome &&
            memMeetingData.otherIncome.bankAmt) ||
            0
        ),

        securityDepositMemToShgCash: Number(
          (memMeetingData &&
            memMeetingData.memSecurityDepositToShg &&
            memMeetingData.memSecurityDepositToShg.cash) ||
            0
        ),
        securityDepositMemToShgBank: Number(
          (memMeetingData &&
            memMeetingData.memSecurityDepositToShg &&
            memMeetingData.memSecurityDepositToShg.bankAmt) ||
            0
        ),

        TotSavingTillDate: 0,
        // Number(memMeetingData.savingAmt.cash || 0) +
        // Number(memMeetingData.savingAmt.bankAmt || 0),

        otherContributionCash: Number(
          (memMeetingData &&
            memMeetingData.otherContribution &&
            memMeetingData.otherContribution.cash) ||
            0
        ),
        otherContributionBank: Number(
          (memMeetingData &&
            memMeetingData.otherContribution &&
            memMeetingData.otherContribution.bankAmt) ||
            0
        ),

        seedMoneyCash: Number(
          (memMeetingData &&
            memMeetingData.memSeedMoney &&
            memMeetingData.memSeedMoney.cash) ||
            0
        ),
        seedMoneyBank: Number(
          (memMeetingData &&
            memMeetingData.memSeedMoney &&
            memMeetingData.memSeedMoney.bankAmt) ||
            0
        ),

        membershipFeesCash: Number(
          (memMeetingData &&
            memMeetingData.membershipFees &&
            memMeetingData.membershipFees.cash) ||
            0
        ),
        membershipFeesBank: Number(
          (memMeetingData &&
            memMeetingData.membershipFees &&
            memMeetingData.membershipFees.bankAmt) ||
            0
        ),

        //EXPENSE
        savingWithdrawalsCash: Number(
          (memMeetingData &&
            memMeetingData.savingWithdrawals &&
            memMeetingData.savingWithdrawals.cash) ||
            0
        ),
        savingWithdrawalsBank: Number(
          (memMeetingData &&
            memMeetingData.savingWithdrawals &&
            memMeetingData.savingWithdrawals.bankAmt) ||
            0
        ),
        savingWithdrawalsCash: Number(
          (memMeetingData &&
            memMeetingData.savingWithdrawals &&
            memMeetingData.savingWithdrawals.cash) ||
            0
        ),

        securityDepositCash: Number(
          (memMeetingData &&
            memMeetingData.securityDeposit &&
            memMeetingData.securityDeposit.cash) ||
            0
        ),

        securityDepositBank: Number(
          (memMeetingData &&
            memMeetingData.securityDeposit &&
            memMeetingData.securityDeposit.bankAmt) ||
            0
        ),

        dividendDistributedCash: Number(
          (memMeetingData &&
            memMeetingData.dividendDistributed &&
            memMeetingData.dividendDistributed.cash) ||
            0
        ),
        dividendDistributedBank: Number(
          (memMeetingData &&
            memMeetingData.dividendDistributed &&
            memMeetingData.dividendDistributed.bankAmt) ||
            0
        ),
      });

      setSelectedbank(memMeetingData.memBankName || []);
    }
  }, [memMeetingData]);

  let {
    savingAmtCash,
    savingAmtBank,

    subscriptionAmtCash,
    subscriptionAmtBank,

    otherIncomeCash,
    otherIncomeBank,

    securityDepositMemToShgCash,
    securityDepositMemToShgBank,

    TotSavingTillDate,

    otherContributionCash,
    otherContributionBank,

    seedMoneyCash,
    seedMoneyBank,

    membershipFeesCash,
    membershipFeesBank,

    //expense
    savingWithdrawalsCash,
    savingWithdrawalsBank,

    securityDepositCash,
    securityDepositBank,

    dividendDistributedCash,
    dividendDistributedBank,
  } = formData;

  const handleKeyPress = (e) => {
    // Allow only numbers, decimal point, and prevent negative sign
    ["e", "E", "+", "-","." ].includes(e.key) && e.preventDefault();
  };

  const onChangeBankName = (e)=>{
    setSelectedbank(e)
    setDisableBankDropDown(true);
  }

  const[lowBalanceError,setLowBalanceError] = useState("")

  const onInputChange = (e) => {
    let label = e.target.name;
    let enteredAmount = e.target.value;
    let count = 0;
    if(isThisFirstMeeting){
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }else{
      // const selectedBankObj = bankAmount &&  bankAmount.find(
      //   (bank) => bank.bankId === selectedBank.bankId
      // );
      // switch (label) {
      //   case "savingWithdrawalsBank":
      //   case "securityDepositBank":
      //   case "dividendDistributedBank":
      //     if (selectedBankObj != undefined) {
      //       if (selectedBankObj.totalBalance < enteredAmount) {
      //         count++;
      //         // setLowBalanceError(
      //         //   `Insufficient balance in ${selectedBankObj.bankName}. Available balance: ${selectedBankObj.totalBalance}`
      //         // );
      //         document.getElementById("bankBalance").style.color="red";
      //       } else {
      //         document.getElementById("bankBalance").style.color="";
      //         setLowBalanceError("");
      //         setFormData({ ...formData, [e.target.name]: e.target.value });
      //       }
      //     } 
  
      //     break;
      //   case "savingWithdrawalsCash":
      //     case "securityDepositCash":
      //       case "dividendDistributedCash":
      //       if(enteredAmount >totalBankAndCashAmt ){
      //         count++;
      //         setLowBalanceError("Cash In Hand is Less")
      //         document.getElementById("cashInHand").style.color="red";
      //       }else{
      //         document.getElementById("cashInHand").style.color="";
      //         setLowBalanceError("")
      //       }
            
      //       break;
            
      //       default:
      //         break;
      //       }
            setFormData({ ...formData, [e.target.name]: e.target.value });
  
  
    // if(count == 0){
    //   setFormData({ ...formData, [e.target.name]: e.target.value });
    // }
    }
    
  };

  const getSum = (cash, bank) => {
    const sum = Number(cash) + Number(bank);
    return sum; // This will return a string representation
  };

  //new IL TAb

  const [allPurpose, setallPurpose] = useState([]);

  useEffect(() => {
    setallPurpose(
      loanreason.map((ele) => {
        return {
          loanReasonId: ele._id,
          label: ele.loanPurpose,
          value: ele.loanPurpose,
        };
      })
    );
  }, [loanreason]);

  const [showRepaymentModal, setShowRepaymentModal] = useState(false);
  const [loanRepaymentData, setLoanRepaymentData] = useState([]);
  const [clickedInternalLoan, setClickedInternalLoan] = useState("");
  const [showEditInternalLoanModal, setShowEditInternalLoanModal] = useState({
    status: false,
    data: null,
  });

  const [editCurLoan, setEditCurLoan] = useState(false);
  const [ILData, setILData] = useState({});

  const [NewInternalLoan, setNewInternalLoan] = useState([]);

  const [showPayment, setShowpayment] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);
  const [internalTypeName, setInternalTypeName] = useState("");
  const [internalLoanId, setInternalLoanId] = useState("");
  const [otherLoan, setOtherLoan] = useState([]);
  const clickPay = (loanData) => {
    setShowpayment(true);
    setInternalTypeName(loanData.intLoanType);

    setInternalLoanId(loanData._id || "New Loan");
    setClickedInternalLoan(loanData);
  };

  const onDeleteInternalPayment = (data) => {
    setPaymentArray(
      paymentArray.filter((e) => e.internalLoanType != data.internalLoanType)
    );
  };

  const onDeleteOthLoan = (othLoanData) => {
    setOtherLoan(
      otherLoan.filter(
        (ele) => ele.otherLoanType != othLoanData.otherLoanType
      ) || []
    );
  };

  const toEditCurMeetingLoan = (LoanData) => {
    setEditCurLoan(true);
    // setILData(LoanData);
  };

  const dateCheck = (thisdate, passedDate) =>
    new Date(thisdate) <= new Date(passedDate);

  const loanSancInThisMeeting = (loanArray) => {
    try {
      return loanArray.filter(
        (ele) =>
          ele.isLoanType == "Internal" &&
          ele.meetingHeldOnDate == memberData.meetingHeldOnDate
      );
    } catch (er) {
      return [];
    }
  };

  const getInternalLoanOnly = (loanArray) => {
    try {
      return loanArray.filter((ele) => ele.isLoanType == "Internal");
    } catch (er) {
      return [];
    }
  };

  const getActiveInternalLoanfromNow = (loanArray) => {
    try {
      return loanArray.filter((ele) => {
        return (
          dateCheck(ele.meetingHeldOnDate, memberData.meetingHeldOnDate) &&
          ele.isLoanType == "Internal"
        );
      });
    } catch (er) {
      console.log("some error occured")
      return [];
    }
  };

  const getActiveOtherLoanfromNow = (loanArray) => {
    try {
      return loanArray.filter(
        (ele) => ele.isLoanType != "Internal"
        // && dateCheck(memberData.meetingHeldOnDate, ele.meetingHeldOnDate)
      );
    } catch (er) {
      return [];
    }
  };

  const internalDelete = (loan) => {
    setNewInternalLoan(
      NewInternalLoan.filter((ele) => ele.intLoanType != loan.intLoanType)
    );
  };
  useEffect(() => {}, [NewInternalLoan], [paymentArray]);
  ///////////////////////////////////// ui Jsx
  const indianFormat = (dateData) => {
    try {
      return dateData
        .split("-")
        .reverse("")
        .join("-");
    } catch (er) {
      return "";
    }
  };

  const infoIcon = (titleValue) => {
    return (
      <>
        <img
          className="img_icon_size log mx-2"
          src={require("../../../static/images/info.png")}
          alt="Reason"
          style={{ height: "18px", width: "18px" }}
          title={titleValue}
        />
      </>
    );
  };

  //FINAL submit
  const isBankName = (passedBankValue) => {
    return passedBankValue != 0 ? selectedBank && selectedBank.value : "";
  };
  /////////////////TEST
  // console.log("paymentArray", paymentArray, {
  //   loanPaid: {
  //     cash: paymentArray.reduce(
  //       (acu, cur) => (acu += Number(cur.internalPrincipalPaid.cash)),
  //       0
  //     ),
  //     bankAmt: paymentArray.reduce(
  //       (acu, cur) => (acu += Number(cur.internalPrincipalPaid.bankAmt)),
  //       0
  //     ),
  //     bankName: selectedBank ? selectedBank.value : "",
  //   },
  //   interestPaid: {
  //     cash: paymentArray.reduce(
  //       (acu, cur) => (acu += Number(cur.internalIntrestPaid.cash)),
  //       0
  //     ),

  //     bankAmt: paymentArray.reduce(
  //       (acu, cur) => (acu += Number(cur.internalIntrestPaid.bankAmt)),
  //       0
  //     ),
  //     bankName: selectedBank ? selectedBank.value : "",
  //   },
  // });
  //////////////////////////////TEST

  // console.log("loan Paid", {
  //   cash: paymentArray.reduce(
  //     (acu, cur) => (acu += Number(cur.internalPrincipalPaid.cash)),
  //     0
  //   ),
  //   bankAmt: paymentArray.reduce(
  //     (acu, cur) => (acu += Number(cur.internalPrincipalPaid.bankAmt)),
  //     0
  //   ),
  //   bankName: selectedBank ? selectedBank.value : "",
  // });

  const [localBankDet, setlocalBankDet] = useState({
    // batchBankNameId: null,
    // batchBankName: null,
    batchBankNameObj: "",
    indBankInterest: 0,
    indBankCommission: 0,
    indBatchBankDeposit: 0,
    indBatchBankWithdrawals: 0,
  });
  const [attendance,setAttandence] = useState(false);
  const onChangeAttandence = ()=>{
    setAttandence(!attendance)
  }

  const onFinalSubmit = async (e) => {
    handleMemberMeetingModalCloses(e);
   
    
// Find the record within selectedDates array that matches the memberId
// console.log("selectedDatesArray",selectedDatesArray)
const foundRecord = selectedDatesArray && selectedDatesArray.selectedDates && selectedDatesArray.selectedDates.find(record => record.memberId === memMeetingData.memberId);

// Get the dates, if the record is found
const memberDates = foundRecord ? foundRecord.dates : undefined;
    
    const finalData = {
      _id: memMeetingData._id,

      batchId: memMeetingData.batchId,
      memberId: memMeetingData.memberId,
      memberName: memMeetingData.memberName,
      meetingHeldOnDate: memMeetingData.meetingHeldOnDate,
      meetingMonth: memMeetingData.meetingHeldOnDate.split("-")[1],
      meetingYear: memMeetingData.meetingHeldOnDate.split("-")[0],
      attendance:attendance,
      selectedDates:memberDates,
      //Basic
      savingAmt: {
        bankName: isBankName(savingAmtBank),
        bankAmt: savingAmtBank,
        cash: savingAmtCash,
      },

      subscriptionAmt: {
        bankName: isBankName(subscriptionAmtBank),
        bankAmt: subscriptionAmtBank,
        cash: subscriptionAmtCash,
      },

      otherIncome: {
        bankName: isBankName(otherIncomeBank),
        bankAmt: otherIncomeBank,
        cash: otherIncomeCash,
      },

      memSecurityDepositToShg: {
        bankName: isBankName(securityDepositMemToShgBank),
        bankAmt: securityDepositMemToShgBank,
        cash: securityDepositMemToShgCash,
      },

      otherContribution: {
        bankName: isBankName(otherContributionBank),
        bankAmt: otherContributionBank,
        cash: otherContributionCash,
      },

      memSeedMoney: {
        bankName: isBankName(seedMoneyBank),
        bankAmt: seedMoneyBank,
        cash: seedMoneyCash,
      },

      membershipFees: {
        bankName: isBankName(membershipFeesBank),
        bankAmt: membershipFeesBank,
        cash: membershipFeesCash,
      },

      //Expense
      savingWithdrawals: {
        bankName: isBankName(savingWithdrawalsBank),
        bankAmt: savingWithdrawalsBank,
        cash: savingWithdrawalsCash,
      },

      securityDeposit: {
        bankName: isBankName(securityDepositBank),
        bankAmt: securityDepositBank,
        cash: securityDepositCash,
      },

      dividendDistributed: {
        bankName: isBankName(dividendDistributedBank),
        bankAmt: dividendDistributedBank,
        cash: dividendDistributedCash,
      },
      // Internal loan Sanc
      internalLoan: NewInternalLoan,

      loanTakenAmt: {
        cash: NewInternalLoan.reduce(
          (acu, cur) =>
            (acu +=
              Number(cur.loanPayablePrincipal.cash) +
              Number(cur.loanPayableInterest.cash)),
          0
        ),
        bankAmt: NewInternalLoan.reduce(
          (acu, cur) =>
            (acu +=
              Number(cur.loanPayablePrincipal.bankAmt) +
              Number(cur.loanPayableInterest.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },

      //Internal loan repayment

      internalRepayment: paymentArray,
      loanPaid: {
        cash: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalPrincipalPaid.cash)),
          0
        ),
        bankAmt: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalPrincipalPaid.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },
      interestPaid: {
        cash: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalIntrestPaid.cash)),
          0
        ),

        bankAmt: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalIntrestPaid.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },

      //other loan
      otherLoan: otherLoan,

      //pri + intrest
      otherLoanTakenAmt: otherLoan.reduce(
        (acu, ele) =>
          (acu +=
            Number(ele.otherLoanTakenAmt.cash) +
            Number(ele.otherLoanTakenAmt.bankAmt)),
        0
      ),

      otherLoanPaid: otherLoan.reduce(
        (acu, ele) =>
          (acu +=
            Number(ele.otherLoanPaid.cash) + Number(ele.otherLoanPaid.bankAmt)),
        0
      ),

      otherInterestPaid: otherLoan.reduce(
        (acu, ele) =>
          (acu +=
            Number(ele.otherInterestPaid.cash) +
            Number(ele.otherInterestPaid.bankAmt)),
        0
      ),

      subsidyDistributed: {
        cash: otherLoan.reduce(
          (acu, ele) => (acu += Number(ele.indSubsidyDistributed.cash)),
          0
        ),

        bankAmt: otherLoan.reduce(
          (acu, ele) => (acu += Number(ele.indSubsidyDistributed.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },

      memBankName: selectedBank ? selectedBank : "",
    };

    // console.log("formData",finalData)
    await editMemberMeetingData(finalData);

   
    const meetingBatchInfo = {
      mdBatchId: memMeetingData.batchId,
      mdDate: memMeetingData.meetingHeldOnDate,
    };
    getMembersMeetingData(meetingBatchInfo);
  };

  

  const paymentSVG = (loan, RepaymentArray) => {
    return (
      <>
        {RepaymentArray.some(
          (paid) => paid.internalLoanType == loan.intLoanType
        ) ? (
          <>Paid</>
        ) : (
          <>
            <span
              className="ml-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                clickPay(loan);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-cash-coin"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                />
                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
              </svg>
            </span>
          </>
        )}
      </>
    );
  };

  const onDeleteInternalDbPayment = (data) => {
    let finalData = {
      ...data,
      institutionId: memMeetingData.institutionId,
      batchId: memMeetingData.batchId,
      memberId: memMeetingData.memberId,
    };

    deleteBankLoanDetail(finalData);
    // getIndividualMemberMeetingData(memberData);
  };

  const [otherLoanDeletion,setShowOtherLoanDeletion] = useState(false)
  const [otherLoanDeletionData,setShowOtherLoanDeletionData] = useState("");

  const [selectedBankValidation,setShowSelectedBankValidation]=useState(false)

  
  const onDeleteOtherDbpayment = (data) => {
   setShowOtherLoanDeletion(true)
   setShowOtherLoanDeletionData(data)
      
  };

  const onDeleteOtherLoan = ()=>{
   
 let finalDataoth = {
      ...otherLoanDeletionData,
      institutionId: memMeetingData.institutionId,
      batchId: memMeetingData.batchId,
      memberId: memMeetingData.memberId,
      meetingHeldOnDate: memMeetingData.meetingHeldOnDate,
    };

    // deleteInternalLoanDB(finalDataoth);
    setShowOtherLoanDeletion(false)
    deleteOtherLoanDB(finalDataoth);
  }

  const [showDeleteLoanModal,setShowDeleteLoanModal]=useState(false);
  const [deleteModalData,setDeleteModalData] = useState("")

  const onDBIntLoanDel = (data) => {
    setShowDeleteLoanModal(true)
    setDeleteModalData(data)
  
  };

  const DeleteLoan =()=>{
  // onDBIntLoanDel
    let finalDataInt = {
      ...deleteModalData,
      institutionId: memMeetingData.institutionId,
      batchId: memMeetingData.batchId,
      memberId: memMeetingData.memberId,
      meetingHeldOnDate: memMeetingData.meetingHeldOnDate,
    };
// console.log(finalDataInt)
setShowDeleteLoanModal(false)
    deleteInternalLoanDB(finalDataInt);
  }

  const onChangeBasicIndex = (tabIndex) => {
    setOtherRepaymentDetails(result)
    if (
      (savingAmtBank != 0 ||
        securityDepositBank != 0 ||
        membershipFeesBank != 0 ||
        seedMoneyBank != 0 ||
        otherContributionBank != 0 ||
        otherIncomeBank != 0 ||
        subscriptionAmtBank != 0) &&
      selectedBank.length == 0
    ) {
      setShowSelectedBankValidation(true);
      document.getElementById("banks").style.border = "2px solid red";

    } else {
      document.getElementById("banks").style.border = "none";
      setTabIndex(tabIndex);
    }
  }; 
  const onChangeExpenseTab = (tabIndex) => {
//  let count = 0;
//     const selectedBankObj = bankAmount &&  bankAmount.find(
//       (bank) => bank.bankId === selectedBank.bankId
//     );
//     let totalBankExpense =
//     (  Number(savingWithdrawalsBank || 0) + Number(dividendDistributedBank || 0) + Number(securityDepositBank || 0));
//     let totalCashExpense =
//       Number(dividendDistributedCash || 0) +
//         Number(securityDepositCash || 0) +
//         Number(savingWithdrawalsCash || 0) || 0;
//     if (
//       (savingWithdrawalsBank != 0 ||
//         dividendDistributedBank != 0 ||
//         securityDepositBank != 0) &&
//       selectedBank.length == 0
//     ) {
//       setShowSelectedBankValidation(true);
//       document.getElementById("banks").style.border = "2px solid red";
//     } else {
//       if(isThisFirstMeeting){
       
//         setLowBalanceError("");
//         document.getElementById("banks").style.border = "none";
//       }else{

      
//         if (totalCashExpense !== 0 && totalCashExpense > totalBankAndCashAmt) {
//           count++
        
//           document.getElementById("cashInHand").style.color = "red";
//           setLowBalanceError("Cash Amount is high");
//         } 
//         if (selectedBankObj) {
         
//           if((totalBankExpense !== 0 &&  totalBankExpense > selectedBankObj.totalBalance)){
//             count++
//             setLowBalanceError("Bank Amount is high");
//           }
//         }
//       }
     
//     }
    // if(count == 0){
    //   document.getElementById("banks").style.border = "none";
    // }
    setTabIndex(tabIndex);
  };
  const onChangeNewIlSanctionedTab = (tabIndex)=>{

    setTabIndex(tabIndex)
  }
const [showOtherLoanRepayModal,showOtherRepayModal] = useState(false);
const[repaymentArray,setRepaymentArray] =useState([]);
const onChangeRePayModal=(data)=>{
  showOtherRepayModal(true)

  const newArray = otherRepaymentDetails
  .map(repItem => {
    // For each item in otherRepaymentDetails, find all matching items in data
    return data
      .filter(dataItem =>
        dataItem.otherLoanTypeId === repItem.otherLoanTypeId // Match based on otherLoanTypeId
      )
      .map(dataItem => ({
        meetingHeldOnDate: repItem.meetingHeldOnDate, // Add meetingHeldOnDate from repItem
        otherLoanTypeId: dataItem.otherLoanTypeId,
        otherLoanTakenAmt: repItem.otherLoanTakenAmt, // Use the value from repItem
        otherLoanType: dataItem.otherLoanType, // Use the value from dataItem
        otherLoanPaid: repItem.otherLoanPaid, // Use the value from repItem
        otherInterestPaid: repItem.otherInterestPaid, // Use the value from repItem
      }));
  })
  .flat(); // Flatten the array of arrays to get a single array
  setRepaymentArray(newArray)
// console.log("newArray", newArray);

}

  return (
    <>
      {memMeetingData ? (
        <Tabs selectedIndex={tabIndex}>
          <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
            <TabList>
              <Tab tabfor="0">Basic</Tab>
              <Tab tabfor="1">Expense</Tab>
              <Tab tabfor="2">New IL Sanc</Tab>
              <Tab tabfor="3">IL Repayment</Tab>
              <Tab tabfor="4">Other Loan</Tab>
            </TabList>
          </div>

          <div className="row col-12" style={{ backgroundColor: "#144c5a" }}>
            <div className="col-lg-3 col-sm-12">
              <label className="label-control" style={{ color: "white" }}>
                Meeting Date : {indianFormat(memMeetingData.meetingHeldOnDate)}
              </label>
            </div>
            <div className="col-lg-3 col-sm-12">
              <label className="label-control" style={{ color: "white" }}>
                Member Name : {memMeetingData.memberName}
              </label>
            </div>
            <div className="row col-lg-3 col-sm-12">
              <div className="col-lg-10"> 
                <Select
                id="banks"
                options={activeBank}
                className="mt-2"
                value={selectedBank}
                placeholder="Select Bank"
                onChange={(e) => {
                  onChangeBankName(e);
                  document.getElementById("banks").style.border = "none";
                }}
                isDisabled={disableBankDropDown}
              />
              </div>
              <div className="col-lg-2 mt-3">
              <img
          className="img_icon_size log"
          src={require("../../../static/images/information.png")}
          alt="Reason"
          style={{ height: "18px", width: "18px" }}
          title={"Only one bank can be selected for a member"}
        />
              {/* {infoIcon("")} */}
              </div>
             
             

            </div>
            {/* <div className="col-lg-3 col-sm-12">
              {allPermission && allPermission.includes("Attendance") ? (
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <label
                    className="label-control mx-2"
                    style={{ color: "white" }}
                  >
                    Is Present :
                  </label>
                  <input
                    type="checkbox"
                    id="Unconfirmed"
                    checked={attendance}
                    onChange={() => onChangeAttandence()}
                    style={{ display: "inline-block" }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div> */}
          </div>

          <TabPanel tabId="0">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onChangeBasicIndex(1);
              }}
            >
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                  <label className="label-control">
                    Savings Amount
                    {infoIcon("Savings amount Cash and Bank Total value")}
                    :&nbsp;(
                    {getSum(savingAmtCash, savingAmtBank)})
                    <i className="colorRed">*</i>
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="savingAmtBank"
                          value={savingAmtBank}
                          disabled={(selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false}
                          required={selectedBank ? true : false}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                        />
                      </div>
                    </div>

                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash</div>

                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="savingAmtCash"
                          value={savingAmtCash}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          required
                        />
                       
                        <label className="" style={{ fontSize: "13px" }}>
                          Total&nbsp;:&nbsp;
                          {memberSavingTotal &&
                           memberSavingTotal[0] && 
                           Number(memberSavingTotal[0].saving || 0) -
                              Number(memberSavingTotal[0].withdrawal || 0) || 0}
                        </label>
                       
                      </div>
                    </div>
                  </div>

                  <label className="label-control">
                    Subscription Amount
                    {infoIcon("Subscription Amount Cash and Bank Total value")}
                    :&nbsp;({getSum(subscriptionAmtCash, subscriptionAmtBank)})
                    <i className="colorRed">*</i>
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="subscriptionAmtBank"
                          value={subscriptionAmtBank}
                          disabled={(selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false}
                          required={selectedBank ? true : false}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                        />
                      </div>
                    </div>

                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="subscriptionAmtCash"
                          value={subscriptionAmtCash}
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <label className="label-control">
                    Other Income
                    {infoIcon("Other Income Amount Cash and Bank Total value")}
                    :&nbsp;({getSum(otherIncomeCash, otherIncomeBank)})
                  </label>

                  {/* {memMeetingData && memMeetingData.institutionId === "63c0e52501d22dcc13f12c32" ? <></> : <></>} */}
                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="otherIncomeBank"
                          value={otherIncomeBank}
                          disabled={
                            memMeetingData &&
                            memMeetingData.institutionId ===
                              "63c0e52501d22dcc13f12c32"
                              ? true
                              : (selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false
                          }
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          // required
                        />
                      </div>
                    </div>

                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="otherIncomeCash"
                          value={otherIncomeCash}
                          // placeholder="Enter Cash Amount"
                          disabled={
                            memMeetingData &&
                            memMeetingData.institutionId ===
                              "63c0e52501d22dcc13f12c32"
                              ? true
                              : false
                          }
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          // required
                        />
                      </div>
                    </div>
                  </div>

                  <label className="label-control">
                    Security Deposit Member To SHG
                    {infoIcon(
                      "Security Deposit Member to SHG Cash and bank Total value"
                    )}
                    :&nbsp;(
                    {getSum(
                      securityDepositMemToShgCash,
                      securityDepositMemToShgBank
                    )}
                    )
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="securityDepositMemToShgBank"
                          value={securityDepositMemToShgBank}
                          disabled={(selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          // required
                        />
                      </div>
                    </div>

                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="securityDepositMemToShgCash"
                          value={securityDepositMemToShgCash}
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                  {/* <label className="label-control">
                    Total Saving (
                    {indianFormat(memMeetingData.meetingHeldOnDate)})
                    {infoIcon("Membership Fees	Cash and Bank Total Value")}:
                  </label>
                  <div className="row col-12">
                    <input
                      type="number"
                      name="TotSavingTillDate"
                      value={TotSavingTillDate}
                      placeholder="Enter Cash Amount"
                      className="form-control"
                      disabled
                      required
                    />
                  </div> */}

                  <label className="label-control">
                    Other Contribution Amount
                    {infoIcon("Other Contribution Cash and Bank Total value")}
                    :&nbsp;(
                    {getSum(otherContributionCash, otherContributionBank)})
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="otherContributionBank"
                          value={otherContributionBank}
                          disabled={
                            memMeetingData &&
                            memMeetingData.institutionId ===
                              "63c0e52501d22dcc13f12c32"
                              ? true
                              : (selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false
                          }
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          // required
                        />
                      </div>
                    </div>

                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="otherContributionCash"
                          value={otherContributionCash}
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          disabled={
                            memMeetingData &&
                            memMeetingData.institutionId ===
                              "63c0e52501d22dcc13f12c32"
                              ? true
                              : false
                          }
                          // required
                        />
                      </div>
                    </div>
                  </div>

                  <label className="label-control">
                    Seed Money
                    {infoIcon("Seed Money Cash and Bank Total value")}
                    :&nbsp;({getSum(seedMoneyCash, seedMoneyBank)})
                  </label>
                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="seedMoneyBank"
                          value={seedMoneyBank}
                          className="form-control"
                          // placeholder="Enter Bank Amount"
                          onChange={(e) => onInputChange(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          disabled={
                            memMeetingData &&
                            memMeetingData.institutionId ===
                              "63c0e52501d22dcc13f12c32"
                              ? true
                              : (selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false
                          }
                          // required
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="seedMoneyCash"
                          value={seedMoneyCash}
                          disabled={
                            memMeetingData &&
                            memMeetingData.institutionId ===
                              "63c0e52501d22dcc13f12c32"
                              ? true
                              : false
                          }
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onKeyDown={(e) => handleKeyPress(e)}
                          onChange={(e) => onInputChange(e)}
                          // required
                        />
                      </div>
                    </div>
                  </div>

                  <label className="label-control">
                    Membership Fees
                    {infoIcon("Membership Fees Cash and Bank Total Value")}
                    :&nbsp;({getSum(membershipFeesCash, membershipFeesBank)})
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="membershipFeesBank"
                          value={membershipFeesBank}
                          className="form-control"
                          // placeholder="Enter Bank Amount"
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          disabled={(selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false}
                          // required
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="membershipFeesCash"
                          value={membershipFeesCash}
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          // required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <label className="label-control colorRed">
                  *Indicates mandatory fields, Please fill mandatory fields
                  before Submit
                </label>
              </div>
              <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                <input
                  type="submit"
                  name="submit"
                  value="Next"
                  className="btn sub_form btn_continue Save float-right"
                />
              </div>
            </form>
          </TabPanel>

          <TabPanel tabId="1">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onChangeExpenseTab(2);
              }}
            >
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                  {bankAmount && bankAmount.map((ele)=>{
                    return(
                      <div id="bankBalance">
                      <span><b>Bank Name</b>&nbsp;:&nbsp;</span><label>{(ele.bankName).slice(0,10)}</label>&nbsp;
                      <span><b>Balance</b>&nbsp;:&nbsp;</span><label>{ele.totalBalance && Number(ele.totalBalance || 0).toFixed(2) }</label>
                      <br/>
                      </div>
                    )
                  })}
                  <br/>
                  <label id="cashInHand"><b>Cash In Hand</b>&nbsp;:&nbsp;{totalBankAndCashAmt || 0}</label><br/>
                {/* <label className="bg-danger">{lowBalanceError}</label><br/> */}

                  <label className="label-control">
                    Savings Withdrawal
                    {infoIcon("Savings Withdrawal Cash and Bank Total value")}
                    :&nbsp;(
                    {getSum(savingWithdrawalsCash, savingWithdrawalsBank)})
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="savingWithdrawalsBank"
                          value={savingWithdrawalsBank}
                          className="form-control"
                          // placeholder="Enter Bank Amount"
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          disabled={(selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false}
                          // required
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="savingWithdrawalsCash"
                          value={savingWithdrawalsCash}
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onKeyDown={(e) => handleKeyPress(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onChange={(e) => onInputChange(e)}
                          // required
                        />
                        
                             <label className="" style={{ fontSize: "13px" }}>
                          Total&nbsp;:&nbsp;
                          {memberSavingTotal &&
                           memberSavingTotal[0] && 
                           Number(memberSavingTotal[0].saving || 0) -
                              Number(memberSavingTotal[0].withdrawal || 0) || 0}
                        </label> 
                      </div>
                    </div>
                  </div>

                  <label className="label-control">
                    Security Deposit SHG To Members
                    {infoIcon(
                      "Security Deposit SHG to Member Cash and Bank Total value"
                    )}
                    :&nbsp;({getSum(securityDepositCash, securityDepositBank)})
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="securityDepositBank"
                          value={securityDepositBank}
                          className="form-control"
                          // placeholder="Enter Bank Amount"
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          disabled={(selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false}
                          // required
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="securityDepositCash"
                          value={securityDepositCash}
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                  <label className="label-control">
                    Dividend Distributed
                    {infoIcon("Dividend Distributed Cash and Bank Total value")}
                    :&nbsp;(
                    {getSum(dividendDistributedCash, dividendDistributedBank)})
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="dividendDistributedBank"
                          value={dividendDistributedBank}
                          className="form-control"
                          // placeholder="Enter Bank Amount"
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          disabled={(selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false}
                          // required
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash</div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="dividendDistributedCash"
                          value={dividendDistributedCash}
                          // placeholder="Enter Cash Amount"
                          className="form-control"
                          onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <label className="label-control colorRed">
                  *Indicates mandatory fields, Please fill mandatory fields
                  before Submit
                </label>
              </div>
              <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                <input
                  type="submit"
                  name="submit"
                  value="Next"
                  className="btn sub_form btn_continue Save float-right"
                />
                <input
                  type="button"
                  name="Prtev"
                  value="Previous"
                  onClick={() => setTabIndex(0)}
                  className="btn sub_form btn_continue Save float-right"
                />
              </div>
            </form>
          </TabPanel>

          <TabPanel tabId="2">
            <div>
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <h5>Loans in this meeting:</h5>
                  <div className="body-inner no-padding table-responsive">
                    <table
                      className="tabllll table table-bordered table-striped table-hover mt-2"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>Info</th>
                          <th style={{ width: "10%" }}>IL Type</th>
                          <th style={{ width: "10%" }}>Sanc On</th>
                          <th style={{ width: "10%" }}>Sanc Prin</th>
                          <th style={{ width: "10%" }}>Sanc Int</th>
                          <th style={{ width: "10%" }}>Loan Status</th>
                          <th style={{ width: "5%" }}>Operation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loanSancInThisMeeting(curMemMettingLoanSanc).map(
                          (ele, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <span
                                    className="ml-1"
                                    onClick={() => {
                                      setShowRepaymentModal(true);
                                      setLoanRepaymentData(
                                        ele.loanRepayment || []
                                      );
                                    }}
                                  >
                                    {
                                      <img
                                        className="img_icon_size log mb-1"
                                        src={require("../../../static/images/information.png")}
                                        alt="Repayment Info"
                                      />
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>{ele.intLoanType || ""}</span>
                                </td>
                                <td>
                                  {indianFormat(
                                    memMeetingData.meetingHeldOnDate
                                  )}
                                </td>
                                <td>{ele.loanPayablePrincipal}</td>
                                <td>{ele.loanPayableInterest}</td>
                                <td>
                                  <span className="mx-2">{ele.loanStatus}</span>
                                </td>
                                <td>
                                  <>
                                    <img
                                      onClick={() =>
                                        setShowEditInternalLoanModal({
                                          status: true,
                                          data: ele,
                                        })
                                      }
                                      className="img_icon_size log mx-2"
                                      src={require("../../../static/images/edit_icon.png")}
                                      alt="Edit"
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                    />
                                    {ele.loanStatus === "Closed" ||
                                    ele.loanStatus === "Oclosed" ? (
                                      <></>
                                    ) : (
                                      <img
                                        onClick={() => onDBIntLoanDel(ele)}
                                        className="img_icon_size log mx-2"
                                        src={require("../../../static/images/delete.png")}
                                        alt="Delete"
                                        title="Delete"
                                        style={{ cursor: "pointer" }}
                                      />
                                    )}
                                  </>
                                </td>
                              </tr>
                            );
                          }
                        )}

                        {NewInternalLoan &&
                          NewInternalLoan.map((loan, idx) => {
                            return (
                              <tr key={idx}>
                                <td></td>
                                <td>{loan.intLoanType}</td>
                                <td>
                                  {indianFormat(
                                    memMeetingData.meetingHeldOnDate
                                  )}
                                </td>
                                <td>
                                  {Number(loan.loanPayablePrincipal.cash) +
                                    Number(loan.loanPayablePrincipal.bankAmt)}
                                </td>
                                <td>
                                  {Number(loan.loanPayableInterest.cash) +
                                    Number(loan.loanPayableInterest.bankAmt)}
                                </td>
                                <td>Active</td>
                                <td>
                                  <span
                                    style={{ cursor: "pointer", color: "red" }}
                                    onClick={() => {
                                      internalDelete(loan);
                                    }}
                                  >
                                    <img
                                      src={require("../../../static/images/delete.png")}
                                      alt="help"
                                      id="img_tool_admin"
                                      className="pass_admin_help_icon_question mx-2"
                                    />
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <>
                {editCurLoan ? (
                  <>
                    {/* <EditCurLoan
                      ILData={ILData}
                      setEditCurLoan={setEditCurLoan}
                      user={user}
                    /> */}
                  </>
                ) : (
                  <ShgInternalLoan
                    Internalonly={getInternalLoanOnly(curMemMettingLoanSanc)}
                    NewInternalLoan={NewInternalLoan}
                    setNewInternalLoan={setNewInternalLoan}
                    internalLoanData={{}}
                    allPurpose={allPurpose}
                    selectedBank={selectedBank}
                  />
                )}
              </>

              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <label className="label-control colorRed">
                  *Indicates mandatory fields, Please fill mandatory fields
                  before Submit
                </label>
              </div>
              <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                <input
                  type="button"
                  name="submit"
                  value="Next"
                  onClick={() => setTabIndex(3)}
                  className="btn sub_form btn_continue Save float-right"
                />
                <input
                  type="button"
                  name="Prtev"
                  value="Previous"
                  onClick={() => setTabIndex(1)}
                  className="btn sub_form btn_continue Save float-right"
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel tabId="3">
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <h5>Active Loans</h5>
            </div>
            <div className="body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>Info</th>
                    <th>IL Type</th>
                    <th>Sanc On</th>
                    <th>Sanc Prin</th>
                    <th>Total Prin Paid</th>
                    <th>Remaining Prin</th>
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {getActiveInternalLoanfromNow(curMemMettingLoanSanc).map(
                    (loan, idx) => {
                      let paidPrincipal = loan.loanRepayment.reduce(
                        (acu, cur) => (acu += cur.loanPrincipalPaid),
                        0
                      );

                      let remaingLoan =
                        loan.loanPayablePrincipal - paidPrincipal;

                      return (
                        <tr key={idx}>
                          <td>
                            <span
                              className="ml-1"
                              onClick={() => {
                                setShowRepaymentModal(true);
                                setLoanRepaymentData(loan.loanRepayment || []);
                              }}
                            >
                              {
                                <img
                                  className="img_icon_size log mb-1"
                                  src={require("../../../static/images/information.png")}
                                  alt="Repayment Info"
                                />
                              }
                            </span>
                          </td>
                          <td>{loan.intLoanType}</td>
                          <td>{indianFormat(loan.meetingHeldOnDate)}</td>
                          <td>{loan.loanPayablePrincipal}</td>
                          <td>{paidPrincipal}</td>
                          <td>{remaingLoan}</td>
                          <td>
                            {paymentArray.some(
                              (item) =>
                                item.internalLoanType == loan.intLoanType
                            ) ||
                            (exstMemILData &&
                              exstMemILData.internalRepayment &&
                              exstMemILData.internalRepayment.some(
                                (item) =>
                                  item.internalLoanType == loan.intLoanType
                              )) ? (
                              <>Paid</>
                            ) : (
                              <>{paymentSVG(loan, paymentArray)}</>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}

                  {NewInternalLoan &&
                    NewInternalLoan.map((loan, idx) => {
                      return (
                        <tr key={idx}>
                          <td></td>
                          <td>{loan.intLoanType}</td>
                          <td>
                            {indianFormat(memMeetingData.meetingHeldOnDate)}
                          </td>
                          <td>
                            {Number(loan.loanPayablePrincipal.cash) +
                              Number(loan.loanPayablePrincipal.bankAmt)}
                          </td>
                          <td>0</td>
                          <td>
                            {Number(loan.loanPayablePrincipal.cash) +
                              Number(loan.loanPayablePrincipal.bankAmt)}
                          </td>
                          <td>{paymentSVG(loan, paymentArray)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {showPayment ? (
              <LoanPayment
                setShowpayment={setShowpayment}
                setPaymentArray={setPaymentArray}
                paymentArray={paymentArray}
                internalTypeName={internalTypeName}
                meetingHeldOnDate={memMeetingData.meetingHeldOnDate}
                selectedBank={selectedBank}
                internalLoanId={internalLoanId}
                curMemMettingLoanSanc={curMemMettingLoanSanc}
                clickedInternalLoan={clickedInternalLoan}
              />
            ) : (
              <></>
            )}

            <div className=" body-inner no-padding table-responsive">
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <h5> Repayment Details</h5>
              </div>

              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>IL Type</th>
                    <th>Paid Principal</th>
                    <th>Paid Interest</th>
                    {/* <th>Penalty</th> */}
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentArray &&
                    paymentArray.map((paid, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{paid.internalLoanType}</td>
                          <td>
                            <span className="mx-4">
                              Total (
                              {Number(paid.internalPrincipalPaid.cash) +
                                Number(paid.internalPrincipalPaid.bankAmt)}
                              )
                            </span>
                            <span className="mx-4">
                              Bank(
                              {Number(paid.internalPrincipalPaid.bankAmt)})
                            </span>
                            <span className="mx-4">
                              Cash(
                              {Number(paid.internalPrincipalPaid.cash)})
                            </span>
                          </td>
                          <td>
                            <span className="mx-4">
                              Total (
                              {Number(paid.internalIntrestPaid.cash) +
                                Number(paid.internalIntrestPaid.bankAmt)}
                              )
                            </span>
                            <span className="mx-4">
                              Bank(
                              {Number(paid.internalIntrestPaid.bankAmt)})
                            </span>
                            <span className="mx-4">
                              Cash(
                              {Number(paid.internalIntrestPaid.cash)})
                            </span>
                          </td>
                          {/* <td>{paid.penalty}</td> */}
                          <td>
                            <span onClick={() => onDeleteInternalPayment(paid)}>
                              <img
                                src={require("../../../static/images/delete.png")}
                                alt="help"
                                id="img_tool_admin"
                                className="pass_admin_help_icon_question"
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}

                  {exstMemILData &&
                    exstMemILData.internalRepayment &&
                    exstMemILData.internalRepayment.map((paid, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{paid.internalLoanType}</td>
                          <td>
                            <span className="mx-4">
                              Total (
                              {paid.internalPrincipalPaid
                                ? Number(paid.internalPrincipalPaid.cash) +
                                  Number(paid.internalPrincipalPaid.bankAmt)
                                : 0}
                              )
                            </span>
                            <span className="mx-4">
                              {" "}
                              {"Bank("}
                              {Number(paid.internalPrincipalPaid.bankAmt)}
                              {")"}
                            </span>
                            <span className="mx-4">
                              {"Cash("}
                              {Number(paid.internalPrincipalPaid.cash)}
                              {")"}
                            </span>
                          </td>
                          <td>
                            <span className="mx-4">
                              Total (
                              {paid.internalIntrestPaid
                                ? Number(paid.internalIntrestPaid.cash) +
                                  Number(paid.internalIntrestPaid.bankAmt)
                                : 0}
                              )
                            </span>
                            <span className="mx-4">
                              {"Bank("}
                              {Number(paid.internalIntrestPaid.bankAmt)}
                              {")"}
                            </span>
                            <span className="mx-4">
                              {"Cash("}
                              {Number(paid.internalIntrestPaid.cash)}
                              {")"}
                            </span>
                          </td>
                          {/* <td>{paid.penalty}</td> */}
                          <td>
                            {getActiveInternalLoanfromNow(
                              curMemMettingLoanSanc
                            ).find(
                              (loan) =>
                                loan.intLoanType == paid.internalLoanType
                            ) &&
                            getActiveInternalLoanfromNow(
                              curMemMettingLoanSanc
                            ).find(
                              (loan) =>
                                loan.intLoanType == paid.internalLoanType
                            ).loanStatus !== "Closed" ? (
                              <>
                                <span
                                  onClick={() => {
                                    onDeleteInternalDbPayment(paid);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../static/images/delete.png")}
                                    alt="help"
                                    id="img_tool_admin"
                                    className="pass_admin_help_icon_question"
                                  />
                                </span>
                              </>
                            ) : (
                              "Closed"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                *Indicates mandatory fields, Please fill mandatory fields before
                Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="button"
                onClick={() => setTabIndex(4)}
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <input
                type="button"
                name="Previous"
                value="Previous"
                onClick={() => setTabIndex(2)}
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </TabPanel>

          <TabPanel tabId="4">
            <div>
              <ShgOtherLoan
                activeOtherLoan={getActiveOtherLoanfromNow(
                  curMemMettingLoanSanc
                ).filter(
                  (ele) =>
                    ele.loanStatus !== "Closed" && ele.loanStatus !== "Oclosed"
                )}
                activeOtherLoanTypes={activeOtherLoanTypes}
                setOtherLoan={setOtherLoan}
                otherLoan={otherLoan}
                memberRepay={memMeetingData && memMeetingData.otherLoan}
                allpurpose={allPurpose}
                selectedBank={selectedBank}
              />

              <div className=" body-inner no-padding table-responsive">
                <table
                  className="tabllll table table-bordered table-striped table-hover mt-2"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Info</th>
                      <th>OL Type</th>
                      <th>OL Sanctioned Principal</th>
                      <th>OL Sanctioned Interest</th>
                      <th>Duration</th>
                      <th>OL Total Sanctioned Amount</th>
                      <th>OL Purpose</th>
                      <th>Subsidy Distributed</th>
                      <th>Nominee</th>
                      <th>OL Principal Paid</th>
                      <th>OL Interest Paid</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {otherLoan &&
                      otherLoan.map((memberData, idx) => {
                        
                        let otherLoanTaken =
                          Number(memberData.otherloanPayablePrincipal.cash) +
                          Number(memberData.otherloanPayablePrincipal.bankAmt);
                        let otherLoanPaid =
                          Number(memberData.otherLoanPaid.cash) +
                          Number(memberData.otherLoanPaid.bankAmt);
                        return (
                          <tr key={idx}>
                            <td></td>
                            <td>{memberData.otherLoanType}</td>
                            <td>
                              {Number(
                                memberData.otherloanPayablePrincipal.cash
                              ) +
                                Number(
                                  memberData.otherloanPayablePrincipal.bankAmt
                                )}
                            </td>
                            <td>
                              {Number(memberData.otherloanPayableInterst.cash) +
                                Number(
                                  memberData.otherloanPayableInterst.bankAmt
                                )}
                            </td>
                            <td>{memberData.otherLoanDuration}</td>
                            <td>
                              {Number(memberData.otherLoanTakenAmt.cash) +
                                Number(memberData.otherLoanTakenAmt.bankAmt)}
                            </td>
                            <td>{memberData.otherloanpurpose}</td>
                            <td>
                              {Number(memberData.indSubsidyDistributed.cash) +
                                Number(
                                  memberData.indSubsidyDistributed.bankAmt
                                )}
                            </td>
                            <td>{memberData.externalNominee}</td>
                            <td>
                              {Number(memberData.otherLoanPaid.cash) +
                                Number(memberData.otherLoanPaid.bankAmt)}
                            </td>
                            <td>
                              {Number(memberData.otherInterestPaid.cash) +
                                Number(memberData.otherInterestPaid.bankAmt)}
                            </td>

                            <td>
                              <img
                                className="img_icon_size log"
                                onClick={() => onDeleteOthLoan(memberData)}
                                src={require("../../../static/images/delete.png")}
                                alt="Deactivate"
                                title="Deactivate"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    {memMeetingData &&
                      memMeetingData.otherLoan &&
                      memMeetingData.otherLoan.map((memberData, idx) => {
                        let otherLoanPaid =
                          Number(memberData.otherLoanPaid.cash) +
                          Number(memberData.otherLoanPaid.bankAmt);
                        let otherLoanSanctioned =
                          Number(memberData.otherloanPayablePrincipal.cash) +
                          Number(memberData.otherloanPayablePrincipal.bankAmt);
                        return (
                          <tr>
                             <td> 
                              <button style={{border:"none",background:"transparent"}} onClick={()=>onChangeRePayModal( memMeetingData.otherLoan)}>
                              <img
                                  className="img_icon_size log mb-1"
                                  src={require("../../../static/images/information.png")}
                                  alt="Repayment Info"
                                />
                                </button>
                                </td>
                            <td>{memberData.otherLoanType}</td>
                            <td>
                              {" "}
                              {Number(
                                memberData.otherloanPayablePrincipal.cash
                              ) +
                                Number(
                                  memberData.otherloanPayablePrincipal.bankAmt
                                )}
                            </td>
                            <td>
                              {Number(memberData.otherloanPayableInterst.cash) +
                                Number(
                                  memberData.otherloanPayableInterst.bankAmt
                                )}
                            </td>
                            <td>{memberData.otherLoanDuration}</td>
                            <td>
                              {" "}
                              {Number(memberData.otherLoanTakenAmt.cash) +
                                Number(memberData.otherLoanTakenAmt.bankAmt)}
                            </td>
                            <td>{memberData.otherloanpurpose}</td>
                            <td>
                              {" "}
                              {Number(memberData.indSubsidyDistributed.cash) +
                                Number(
                                  memberData.indSubsidyDistributed.bankAmt
                                )}
                            </td>
                            <td>{memberData.externalNominee}</td>
                            <td>
                              {Number(memberData.otherLoanPaid.cash) +
                                Number(memberData.otherLoanPaid.bankAmt)}
                            </td>
                            <td>
                              {Number(memberData.otherInterestPaid.cash) +
                                Number(memberData.otherInterestPaid.bankAmt)}
                            </td>

                            <td>
                              {otherLoanPaid >= otherLoanSanctioned ? (
                                <img
                                className="img_icon_size log"
                                onClick={() =>
                                  onDeleteOtherDbpayment(memberData)
                                }
                                src={require("../../../static/images/delete.png")}
                                alt="Delete"
                                title="Delete"
                              />
                              ) : (
                                <img
                                  className="img_icon_size log"
                                  onClick={() =>
                                    onDeleteOtherDbpayment(memberData)
                                  }
                                  src={require("../../../static/images/delete.png")}
                                  alt="Delete"
                                  title="Delete"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {/* <div className="row col-lg-12 col-md-9 col-sm-9 col-12">loan</div> */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                <label className="label-control colorRed mt-2">
                  *Indicates mandatory fields, Please fill mandatory fields
                  before Submit
                </label>
              </div>
              <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                <input
                  type="button"
                  onClick={() => {
                    setEditDisabled(true);
                    onFinalSubmit();
                  }}
                  name="submit"
                  value="ADD"
                  className="btn sub_form btn_continue Save float-right"
                  disabled={editDisabled}
                />
                <input
                  type="button"
                  name="Prtev"
                  value="Previous"
                  onClick={() => setTabIndex(3)}
                  className="btn sub_form btn_continue Save float-right"
                />
              </div>
            </div>
          </TabPanel>
        </Tabs>
      ) : (
        <></>
      )}

      <Modal
        show={showRepaymentModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Repayment Details</h3>
          </div>
          <div className="col-lg-2">
            <button
              onClick={() => setShowRepaymentModal(false)}
              className="close"
            >
              <img
                src={require("../../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <LoanRepaymentDetails
            loanRepaymentData={loanRepaymentData}
            watchingDate={memberData.meetingHeldOnDate}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditInternalLoanModal.status}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Edit Internal Loan</h3>
          </div>
          <div className="col-lg-2">
            <button
              onClick={() =>
                setShowEditInternalLoanModal({
                  status: false,
                  data: showEditInternalLoanModal.data,
                })
              }
              className="close"
            >
              <img
                src={require("../../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <MemInternalLoanEdit
            user={user}
            setShowEditInternalLoanModal={setShowEditInternalLoanModal}
            showEditInternalLoanModal={showEditInternalLoanModal}
            memMeetingData={memMeetingData}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteLoanModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-11 ">
            <h3 className="modal-title ">Attention</h3>
          </div>
          <div className="col-lg-1 mr-3">
            <button
              onClick={() => setShowDeleteLoanModal(false)}
              className="close"
            >
              <img
                src={require("../../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h5>
              Are you sure you want to delete the loan details and repayment
              records? This action cannot be reversed.
            </h5>
            <span>
              {" "}
              Note: If applicable, please ensure that any consecutive repayments
              related to this loan in the upcoming batch meetings are deleted as
              well.
            </span>
          </div>
          <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
            <input
              type="button"
              onClick={() => {
                DeleteLoan();
              }}
              name="submit"
              value="Delete"
              className="btn sub_form btn_continue Save float-right"
              disabled={editDisabled}
            />
            <input
              type="button"
              name="Prtev"
              value="Cancel"
              onClick={() => setShowDeleteLoanModal(false)}
              className="btn sub_form btn_continue Save float-right"
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* //////////////////////////////////////////////// for other loan deletion */}
      <Modal
        show={otherLoanDeletion}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-11 ">
            <h3 className="modal-title ">Attention</h3>
          </div>
          <div className="col-lg-1 mr-3">
            <button
              onClick={() => setShowOtherLoanDeletion(false)}
              className="close"
            >
              <img
                src={require("../../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h5>
              Are you sure you want to delete the loan details and repayment
              records? This action cannot be reversed.
            </h5>
            <span>
              {" "}
              Note: If applicable, please ensure that any consecutive repayments
              related to this loan in the upcoming batch meetings are deleted as
              well.
            </span>
          </div>
          <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
            <input
              type="button"
              onClick={() => {
                onDeleteOtherLoan();
              }}
              name="submit"
              value="Delete"
              className="btn sub_form btn_continue Save float-right"
              disabled={editDisabled}
            />
            <input
              type="button"
              name="Prtev"
              value="Cancel"
              onClick={() => setShowOtherLoanDeletion(false)}
              className="btn sub_form btn_continue Save float-right"
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* ////////////////////////bank warning modal////////////////////// */}
      <Modal
        show={selectedBankValidation}
        backdrop=""
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-11 ">
            <h3 className="modal-title " style={{ fontWeight: "bold" }}>
              Attention
            </h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            You have entered an amount in the bank field. Please select the bank
            to proceed.
          </div>
          <div className="">
            <input
              type="button"
              name="Prtev"
              value="Ok"
              onClick={() => setShowSelectedBankValidation(false)}
              className="btn sub_form btn_continue Save float-right"
            />
          </div>
        </Modal.Body>
      </Modal>

{/* ////////////////////////modal for other Repayment start//////////////////////////////////////// */}
<Modal
        show={showOtherLoanRepayModal}
        backdrop=""
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-11 ">
            <h3 className="modal-title " style={{ fontWeight: "bold" }}>
              Repayment Details
            </h3>
          </div>
          <div className="col-lg-1 mr-3">
            <button
              onClick={() => showOtherRepayModal(false)}
              className="close"
            >
              <img
                src={require("../../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className=" body-inner no-padding table-responsive">
                <table
                  className="tabllll table table-bordered table-striped table-hover mt-2"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Meeting Date</th>
                      <th>Paid Principal</th>
                      <th>Paid Interest</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    {otherRepaymentDetails &&
                      otherRepaymentDetails.map((memberData, idx) => {
                        
                     let date = memberData.meetingHeldOnDate && (memberData.meetingHeldOnDate).split("-").reverse().join("-")
                        return (
                         <tr>
                          <td>{date}</td>
                          <td>{Number(memberData.otherLoanPaid.cash || 0) + Number(memberData.otherLoanPaid.bankAmt || 0) }</td>
                          <td>{Number(memberData.otherInterestPaid.cash || 0) + Number(memberData.otherInterestPaid.bankAmt || 0)}</td>
                         </tr>
                        );
                      })}
                   
                  </tbody>
                </table>
              </div>
        </Modal.Body>
      </Modal>
{/* /////////////////////////modal for other Repayment end///////////////////////////////////////// */}
    </>
  );
};

EditMemberModel.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getActiveBankData,
  getIndividualMemberMeetingData,
  getCurMemMettingLoanSanc,
  getActiveOtherLoanType,
  getExistingMemberILData,
  getExistingMemberOLData,
  getParticularLoanReason,
  editMemberMeetingData,
  getMembersMeetingData,
  deleteBankLoanDetail,
  deleteInternalLoanDB,
  deleteOtherLoanDB,
  getMemberSavingTotal,
  getOtherLoanRepaymentDetails
})(EditMemberModel);
