import React from "react";
import { useRef } from "react";
import { useEffect, useState, useLayoutEffect } from "react";
import logo from "../landing/assets/Images/Swashakthi-SHG-Logo.webp";
import "../landing/assets/vendor/bootstrap/css/bootstrap.min.css";
import "../landing/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../landing/assets/css/main.css";
import { Link } from "react-router-dom";

export default function LandingPage() {

  const [currentYear, setCurrentYear] = useState("");
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year.toString());
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease",
      once: false,
    });
  }, []);

  useLayoutEffect(() => {
    const initializeAOS = () => {
      AOS.init({ duration: 1000 });
    };

    window.addEventListener("load", initializeAOS);

    return () => {
      window.removeEventListener("load", initializeAOS);
    };
  }, []);

  // back to top icon
  const [isVisible, setIsVisible] = useState(false);

  // Toggle visibility of the back-to-top button
  const toggleScrollTop = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page
  const scrollToTop = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", toggleScrollTop);
    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener("scroll", toggleScrollTop);
    };
  }, []);

  // // active tabs

  const navRef = useRef(null);
  useEffect(() => {
    const handleTabClick = (event) => {
      const links = navRef.current.querySelectorAll(".navmenu a");
      links.forEach((link) => link.classList.remove("active")); // Remove active from all links
      event.target.classList.add("active"); // Add active to the clicked link
    };
    const links = navRef.current.querySelectorAll(".navmenu a");
    links.forEach((link) => link.addEventListener("click", handleTabClick));
    // Cleanup event listeners on component unmount
    return () => {
      links.forEach((link) =>
        link.removeEventListener("click", handleTabClick)
      );
    };
  }, []);

  useEffect(() => {
    /**
     * Mobile nav toggle
     */
    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    function mobileNavToogle() {
      document.querySelector(".LP").classList.toggle("mobile-nav-active");
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    }
    mobileNavToggleBtn.addEventListener("click", mobileNavToogle);

    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });
  }, []);

  return (
    <>
      <div className="LP">
        <header
          id="header"
          className="header d-flex align-items-center sticky-top"
        >
          <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center">
              <img
                src={logo}
                alt="logo"
                className="img-fluid logoImg"
                height={80}
                width={90}
              />
            </a>
            <nav id="navmenu" className="navmenu" ref={navRef}>
              <ul>
                <li>
                 <a href="/" className="inActiveLink"> Home</a>
                </li>
                <li>
                  <a href="/">About</a>
                </li>
                <li>
                  <a href="/">Features</a>
                </li>
                <li>
                  <a href="/">Benefits</a>
                </li>
                <li>
                  <a href="/">Pricing</a>
                </li>
                <li>
                  <a href="/">Case Studies</a>
                </li>
                <li>
                  <a href="/">Contact</a>
                </li>
                <li>
                  <a href="/Login" className="btn-get-started">
                    Login
                  </a>
                </li>
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list" />
            </nav>
          </div>
        </header>
        <main className="main">
      
          <section id="about" className="about section light-background">
            <div className="container">
            
                <div
                  className="col-lg-12 content"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Terms and Conditions</h3>
                  <p className="secondaryText-color fontText-small">
                    Last updated: June-2024
                  </p>
                 

                
                  <div className="mt-4">
                    
                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">BACKGROUND: </span> Welcome to
                    Swashakthi SHG! These Terms and Conditions (&quot;Terms&quot;,
                    &quot;Terms and Conditions&quot;) govern your use of the
                    Swashakthi SHG application (&quot;Service&quot;), which is
                    operated by [Your Company Name] (&quot;us&quot;,
                    &quot;we&quot;, or &quot;our&quot;). Swashakthi SHG is a
                    comprehensive, cross-platform application designed to offer
                    a seamless NGO experience . Please read these Terms and
                    Conditions carefully before using our Service.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">GRANT OF LICENSE:</span> Subject to
                    your compliance with these Terms, we grant you a limited,
                    non-exclusive, non-transferable license to download,
                    install, and use the Swashakthi SHG application on your devices,
                    solely for your personal and non-commercial use.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                  <span className="textBold">SCOPE OF WORK:</span> Swashakthi SHG
                    provides a platform for users to add SHG and add loan  across various sectors.
                     We offer information and
                    tools to facilitate this process, but we are not responsible
                    for the actual services provided by third-party vendors
                    listed on our platform. The scope of our work is limited to
                    providing an easy-to-use interface .
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">USE OF THE SITE: </span>You agree to
                    use the Service only for lawful purposes and in accordance
                    with these Terms. You must not use our Service in any way
                    that violates any applicable local, national, or
                    international law or regulation.
                  </p>

                  

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">PRIVACY: </span> Your privacy is
                    important to us. Please review our Privacy Policy to
                    understand how we collect, use, and protect your personal
                    information.
                  </p>

                
                

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold"> USE OF THE SITE: </span> You agree to
                    use the Service only for lawful purposes and in accordance
                    with these Terms. You must not use our Service in any way
                    that violates any applicable local, national, or
                    international law or regulation.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">
                      INTELLECTUAL PROPERTY RIGHTS – TRADEMARK NOTICE{" "}
                    </span>{" "}
                    The Swashakthi SHG application and its original content,
                    features, and functionality are and will remain the
                    exclusive property of “Pinnacle Technologies” and its
                    licensors. The trademarks, logos, and service marks
                    displayed on the Service are the property of “Pinnacle
                    Technologies” or other third parties. You are not permitted
                    to use these trademarks without the prior written consent of
                    “Pinnacle Technologies” or the third party that owns the
                    trademark.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">DISCLAIMER OF WARRANTIES </span>
                    The Service is provided on an &quot;AS IS&quot; and &quot;AS
                    AVAILABLE&quot; basis. We do not warrant that the Service
                    will be uninterrupted, error-free, or free of viruses or
                    other harmful components. We disclaim all warranties,
                    whether express or implied, including the implied warranties
                    of merchantability and fitness for a particular purpose.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">
                      GENERAL LIMITATION OF LIABILITY{" "}
                    </span>{" "}
                    In no event shall “Pinnacle Technologies”, its directors,
                    employees, partners, agents, suppliers, or affiliates be
                    liable for any indirect, incidental, special, consequential,
                    or punitive damages, including without limitation, loss of
                    profits, data, use, goodwill, or other intangible losses,
                    resulting from your access to or use of or inability to
                    access or use the Service.
                  </p>
                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">MISCELLANEOUS </span> These Terms
                    constitute the entire agreement between you and “Pinnacle
                    Technologies” regarding the use of the Service. Any failure
                    by us to exercise or enforce any right or provision of these
                    Terms shall not constitute a waiver of such right or
                    provision.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">LICENSE TERM AND LICENSE FEES </span>{" "}
                    The license granted to you under these Terms will remain in
                    effect until terminated by either you or us. The license is
                    subject to payment of applicable fees, if any, which are
                    specified at the time of purchase.
                  </p>


                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">LICENSEE DATA </span> You retain
                    ownership of any data you provide to the Service. However,
                    you grant us a non-exclusive, worldwide, royalty-free
                    license to use, store, and process your data as necessary to
                    provide the Service.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">REPRESENTATIONS AND WARRANTIES:</span>{" "}
                    You represent and warrant that you have the legal capacity
                    to enter into these Terms and that you will comply with all
                    applicable laws and regulations.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                  <span className="textBold">
                      RIGHTS, OBLIGATIONS, AND COVENANTS OF LICENSOR: 
                    </span>{" "}
                    We will provide the Service in accordance with these Terms
                    and will make reasonable efforts to ensure the Service is
                    available and functioning as described.{" "}
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                  <span className="textBold">INDEMNITY: </span> You agree to
                    indemnify and hold harmless “Pinnacle Technologies”, its
                    affiliates, and their respective officers, directors,
                    employees, agents, and representatives from and against any
                    and all claims, damages, obligations, losses, liabilities,
                    costs, or debt, and expenses (including but not limited to
                    attorney&apos;s fees) arising from your use of the Service
                    or your violation of these Terms.{" "}
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                  <span className="textBold">CONFIDENTIALITY:</span> You agree not
                    to disclose any confidential information you obtain through
                    your use of the Service to any third party without our prior
                    written consent.
                  </p>


                  <p className="mb-4 secondaryText-color fontText-small">
                  <span className="textBold">SUSPENSION AND TERMINATION:</span> We
                    may suspend or terminate your access to the Service at any
                    time, without notice, for conduct that we believe violates
                    these Terms or is harmful to other users of the Service, us,
                    or third parties, or for any other reason in our sole
                    discretion.
                  </p>


                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">LIMITATION OF LIABILITY:</span> Our
                    total liability to you for any claim arising out of or
                    relating to these Terms or your use of the Service shall not
                    exceed the amount you paid us for the use of the Service.
                  </p>


                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">GOVERNING LAW:</span> These Terms
                    shall be governed and construed in accordance with the laws
                    of [India/Karnataka], without regard to its conflict of law
                    provisions.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">FORCE MAJEURE:</span> We shall not be
                    liable for any failure to perform our obligations under
                    these Terms if such failure results from any cause beyond
                    our reasonable control, including, but not limited to,
                    mechanical, electronic, or communications failure or
                    degradation.
                  </p>


                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">WAIVER:</span> Our failure to enforce
                    any right or provision of these Terms will not be considered
                    a waiver of those rights. Any waiver of any provision of
                    these Terms will be effective only if in writing and signed
                    by us.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">ASSIGNMENT:</span> You may not assign
                    any of your rights or obligations under these Terms without
                    our prior written consent. We may assign our rights and
                    obligations under these Terms at any time without notice to
                    you.
                  </p>
                  <p className="mb-4 secondaryText-color fontText-small">
                    <a className="textBold">SEVERABILITY: </a>If any provision
                    of these Terms is held to be invalid or unenforceable, the
                    remaining provisions of these Terms will remain in effect.
                    These Terms constitute the entire agreement between us
                    regarding our Service, and supersede and replace any prior
                    agreements we might have between us regarding the Service.
                  </p>
                 


                   
             
                  </div>

                 
                
                 
                </div>

                <div
                  className="col-lg-12 content mt-5"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Terms of Services</h3>              
                  <div className="mt-4">                  
                  <p className="mb-4  secondaryText-color fontText-small">
                  <span className="textBold">ACCEPTANCE OF TERMS: </span> By using
                  Swashakthi SHG, you acknowledge that you have read, understood,
                    and agree to be bound by these Terms of Service, as well as
                    our Privacy Policy, which is incorporated by reference. If
                    you do not agree to these terms, please do not use our
                    application.
                  </p>

               

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">USER RESPONSIBILITIES:</span>{" "}
                    <ol type="a" style={{ marginLeft: "30px" }}>
                      <li>
                        <strong>Account Registration:</strong> You may need to
                        register an account to access certain features of
                        Swashakthi SHG. You agree to provide accurate, complete, and
                        current information during the registration process.
                      </li>
                      <li>
                        <strong>Account Security:</strong> You are responsible
                        for maintaining the confidentiality of your account
                        password and for all activities that occur under your
                        account. You agree to notify us immediately of any
                        unauthorized use of your account.
                      </li>
                      <li>
                        <strong>Compliance:</strong> You agree to use Swashakthi SHG
                        in compliance with all applicable laws and regulations.
                        You will not use our services for any unlawful or
                        prohibited activities.
                      </li>
                    </ol>
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">SUBSCRIPTION AND PAYMENT: </span>{" "}
                    <ol type="a" style={{ marginLeft: "20px" }}>
                      <li>
                        <strong>Subscription Plans:</strong> Swashakthi SHG offers
                        various subscription plans. By subscribing, you agree to
                        pay the subscription fees and any applicable taxes.
                      </li>
                      <li>
                        <strong>Billing:</strong> You will provide a valid
                        payment method when subscribing. Subscription fees will
                        be billed at the beginning of your subscription period
                        and at regular intervals thereafter.
                      </li>
                      <li>
                        <strong>Cancellations:</strong> You may cancel your
                        subscription at any time. However, no refunds will be
                        provided for any unused portion of the subscription
                        period.
                      </li>
                    </ol>
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">INTELLECTUAL PROPERTY: </span>{" "}
                    <ol type="a" style={{ marginLeft: "20px" }}>
                      <li>
                        <strong>Ownership:</strong> All content, features, and
                        functionality on Swashakthi SHG, including but not limited
                        to text, graphics, logos, and software, are the
                        exclusive property of Swashakthi SHG and are protected by
                        copyright, trademark, and other intellectual property
                        laws.
                      </li>
                      <li>
                        <strong>License:</strong> Swashakthi SHG grants you a
                        limited, non-exclusive, non-transferable license to
                        access and use the application for personal and business
                        use in accordance with these terms.
                      </li>
                    </ol>
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">USER CONTENT: </span>
                    <ol type="a" style={{ marginLeft: "20px" }}>
                      <li>
                        <strong>Responsibility:</strong> You are solely
                        responsible for any content you upload, post, or
                        otherwise make available through Swashakthi SHG. You warrant
                        that you own or have the necessary rights to use and
                        distribute such content.
                      </li>
                      <li>
                        <strong>License to Swashakthi SHG:</strong> By submitting
                        content, you grant Swashakthi SHG a worldwide,
                        non-exclusive, royalty-free license to use, reproduce,
                        modify, and display the content.
                      </li>
                    </ol>
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">PROHIBITED USES:</span> you agree not
                    to
                    <ol type="a" style={{ marginLeft: "20px" }}>
                      <li>
                        Use Swashakthi SHG for any unlawful purpose or in violation
                        of any applicable laws.
                      </li>
                      <li>Upload or transmit viruses or other harmful code.</li>
                      <li>
                        Interfere with or disrupt the integrity or performance
                        of our services.
                      </li>
                      <li>
                        Attempt to gain unauthorized access to any part of
                        Swashakthi SHG or its systems.
                      </li>
                    </ol>
                  </p>
                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">LIMITATION OF LIABILITY: </span>{" "}
                    <ol type="a" style={{ marginLeft: "30px" }}>
                      <li>
                        <strong>Disclaimer:</strong> Swashakthi SHG is provided
                        &quot;as is&quot; and &quot;as available&quot; without
                        warranties of any kind, either express or implied. We do
                        not warrant that the application will be uninterrupted,
                        error-free, or secure.
                      </li>
                      <li>
                        <strong>Limitation:</strong> To the maximum extent
                        permitted by law, Swashakthi SHG shall not be liable for any
                        indirect, incidental, special, consequential, or
                        punitive damages, or any loss of profits or revenues,
                        whether incurred directly or indirectly, or any loss of
                        data, use, goodwill, or other intangible losses,
                        resulting from (i) your use or inability to use the
                        service; (ii) any unauthorized access to or use of our
                        servers and/or any personal information stored therein;
                        (iii) any bugs, viruses, or the like that may be
                        transmitted to or through our service by any third
                        party; (iv) any errors or omissions in any content or
                        for any loss or damage incurred as a result of your use
                        of any content posted, emailed, transmitted, or
                        otherwise made available through the service; and/or (v)
                        the defamatory, offensive, or illegal conduct of any
                        third party.
                      </li>
                    </ol>
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">INDEMNIFICATION</span> You agree to
                    indemnify, defend, and hold harmless Swashakthi SHG, its
                    affiliates, and their respective officers, directors,
                    employees, and agents from and against any claims,
                    liabilities, damages, losses, and expenses, including
                    reasonable attorneys&apos; fees, arising out of or in any
                    way connected with your access to or use of Swashakthi SHG or
                    your violation of these Terms of Service.
                  </p>
                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">TERMINATION: </span>We may terminate
                    or suspend your access to Swashakthi SHG immediately, without
                    prior notice or liability, if you breach any of these terms.
                    Upon termination, your right to use the application will
                    cease immediately
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">CHANGES TO TERMS:</span> Swashakthi SHG
                    reserves the right to modify these Terms of Service at any
                    time. We will notify you of any changes by posting the new
                    terms on our application. Your continued use of Swashakthi SHG
                    after such modifications will constitute your acknowledgment
                    of the modified terms and your agreement to abide by them.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    <span className="textBold">GOVERNING LAW: </span> These terms
                    shall be governed and construed in accordance with the laws
                    of the jurisdiction in which Swashakthi SHG operates, without
                    regard to its conflict of law provisions.
                  </p>

                  <p className="mb-4 secondaryText-color fontText-small">
                    By using Swashakthi SHG, you agree to these Terms of Service.
                    Thank you for choosing Swashakthi SHG for your booking needs!
                  </p>

             
                  </div>

                 
                
                 
                </div>
             
            </div>
          </section>

        </main>
        <footer id="footer" className="LP-footer accent-background">
          <div className="container footer-top">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-12 footer-about">
                <a href="index.html" className="logo d-flex align-items-center">
                  <span className="sitename">Pinnacle Technologies</span>
                </a>
                <p>
                  We are a leading software development company offering
                  services in Software &amp; Website Development, Digital
                  Marketing, Web App Development, Code Mentoring, and UI/UX
                  Design across India.
                </p>
                <p>
                  Our mission is to exceed client expectations with end-to-end
                  solutions that boost business efficiency and productivity.
                  With a proven track record, we provide high-quality services
                  to help clients achieve their business goals and drive
                  success.
                </p>
                <div className="mt-3">
                  <a
                    href="https://www.pinnacletechnologies.in/"
                    target="_blank"
                    className="btn-get-started"
                  >
                    Visit Website
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a href="#" className="inactiveLink">
                      Software Development
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Website Development
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Digital Marketing
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Code Mentoring
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      UI/UX Design
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 footer-links">
                <h4>Our Products</h4>
                <ul>
                  <li>
                    <a href="https://bookbetter.app/" target="_blank">
                      Book Better (Booking Application)
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://swaraj-lease-hub.s3-website.ap-south-1.amazonaws.com/"
                      target="_blank"
                    >
                      Swaraj (Lease Management System)
                    </a>
                  </li>
                  <li>
                    <a href="#">Swastik (Temple Management System)</a>
                  </li>
                  <li>
                    <a
                      href="http://swayamfinance.in.s3-website.ap-south-1.amazonaws.com/"
                      target="_blank"
                    >
                      Swayam (Loan Tracking Application)
                    </a>
                  </li>
                  <li>
                    <a href="#">Swajan (Directory Address Generator)</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>2nd floor, Kadiyali Complex, </p>
                <p>Kadiyali, Kunjibettu, </p>
                <p>Udupi 576102</p>
                <p className="mt-3">
                  <strong>Phone:</strong>{" "}
                  <span>+91 9916213542 / +91 9663514001</span>
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <span>sales@pinnacletechnologies.in</span>
                </p>
                <div className="social-links d-flex mt-4 ">
                  <a
                    href="https://www.youtube.com/channel/UC_LGu8JbSBOqtvLLS_i7KaQ"
                    target="_blank"
                  >
                    <i className="bi bi-youtube" />
                  </a>
                  <a
                    href="https://www.facebook.com/pinnacletechnologiesindia/"
                    target="_blank"
                  >
                    <i className="bi bi-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/pinnacletechnologiesindia/"
                    target="_blank"
                  >
                    <i className="bi bi-instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/pinnacle-technologies-india"
                    target="_blank"
                  >
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
                <div className="mt-4">
                  <p>
                    <a href="/privacy-policy">Privacy Policy </a> &nbsp;| &nbsp;
                    <a href="/terms-conditions" className="mt-3">
                      Terms &amp; conditions{" "}
                    </a>
                      {/* <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;| &nbsp;
                     <Link to="/terms-conditions" className="mt-3">Terms &amp; conditions</Link> */}
                    
                
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container copyright  mt-4 ">
            <div className="row">
              <div className="col-lg-6">
                <p>
                  Copyright © <span>{currentYear}</span> |{" "}
                  <span>All Rights Reserved</span>
                </p>
              </div>
              <div className="col-lg-6">
                <p className="policyLink">
                  Designed &amp; Developed by
                  <span>
                    <a
                      href="https://www.pinnacletechnologies.in/"
                      target="_blank"
                    >
                      {" "}
                      Pinnacle Technologies
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </footer>



        {isVisible && (
          <a
            href="#"
            onClick={scrollToTop}
            className="scroll-top d-flex align-items-center justify-content-center back-to-top active"
          >
            <i className="bi bi-arrow-up-short"></i>
          </a>
        )}
      </div>
    </>
  );
}
