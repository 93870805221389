import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getALLSkillReport, getActivePrograms } from "../../actions/skill";
import Spinner from "../layout/Spinner";
import EditSkillTrainingDetail from "../dashboard/EditSkillTrainingDetail";
import DeactiveSkillTrainingDetails from "../dashboard/DeactiveSkillTrainingDetails";
import { CSVLink } from "react-csv";

const SkillTrainingReport = ({
  auth: { isAuthenticated, user, users },
  shg: { incomeandexpensereport },
  skill: { activeprogram, skillReportData },
  getActivePrograms,
  getALLSkillReport,
}) => {
  useEffect(() => {
    getALLSkillReport();
  }, [getALLSkillReport]);
  useEffect(() => {
    getActivePrograms();
  }, [getActivePrograms]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allProgramList = [];
  activeprogram.map((program) =>
    allProgramList.push({
      programId: program._id,
      label: program.programName,
      value: program.programName,
    })
  );

  const [program, getprogramData] = useState();
  const [programId, setprogramId] = useState();
  const [programName, setprogramName] = useState();
  let changeData = "";
  const onprogramChange = (e) => {
    var programId = "";
    var programName = "";
    getprogramData(e);
    programId = e.programId;
    programName = e.value;
    setprogramId(programId);
    setprogramName(programName);
    changeData = {
      programIdVal: e.programId,
    };
    getALLSkillReport(changeData);
  };

  const onClickReset = () => {
    getALLSkillReport("");
    getprogramData("");
    setFromDate("");
    setToDate("");
  };

  const [fromSelectedDate, setFromDate] = useState("");
  const [toSelectedDate, setToDate] = useState("");
  const onDateChange = (e) => {
    setFromDate(e.target.value);
    changeData = {
      programIdVal: programId,
      fromDate: e.target.value,
      toDate: toSelectedDate,
    };
    // getALLSkillReport(changeData);
    // getIncomeAndExpenseOpBal(changeData);
  };

  const onDateChanges = (e) => {
    setToDate(e.target.value);
    changeData = {
      programIdVal: programId,
      fromDate: fromSelectedDate,
      toDate: e.target.value,
    };
    getALLSkillReport(changeData);
    // getIncomeAndExpenseOpBal(changeData);
  };

  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (skillReportData, idx) => {
    setShowUpdateModal(true);
    setUserData(skillReportData);
  };

  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (skillReportData, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(skillReportData);
  };

  const csvFreeZonedData = [
    [
      "Batch Name",
      "Member Name",
      "Member Role",
      "Training Date",
      "Training Type",
      "Description",
    ],
  ];
  skillReportData.map((skillReportData) => {
    var ED = skillReportData.trainingDate.split(/\D/g);
    var trainingDate = [ED[2], ED[1], ED[0]].join("-");
    return csvFreeZonedData.push([
      skillReportData.batchName,
      skillReportData.memberName,
      skillReportData.memberDesg,
      trainingDate,
      skillReportData.programName,
      skillReportData.trainingDescription,
    ]);
  });

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Skill Training Report </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-11 no_padding">
            <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
              <Select
                name="programName"
                options={allProgramList}
                isSearchable={true}
                value={program}
                placeholder="Select Program"
                onChange={(e) => onprogramChange(e)}
              />
            </div>
            {/* <div>
              {" "}
              <img
                className="img_icon_size log"
                onClick={() => onEdit()}
                src={require("../../static/images/edit_icon.png")}
                alt="Edit"
                title="Edit"
              />
            </div> */}

            <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
              {/* <label className="label-control">From Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="fromDate"
                value={fromSelectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-2 col-md-5 col-sm-4 col-11 ">
              {/* <label className="label-control">To Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="toDate"
                value={toSelectedDate}
                onChange={(e) => onDateChanges(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-6 col-md-3 col-sm-4 col-11 py-3">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              <CSVLink data={csvFreeZonedData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
            </div>
          </div>

          <div className="row col-11">
            <div className="col-lg-12 col-md-11 col-sm-11 col-11 ">
              <div className=" body-inner no-padding table-responsive fixTableHead mt-3">
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Batch</th>
                      <th>Member Name</th>
                      <th>Member Role</th>
                      <th>Training Date</th>
                      <th>Training Type</th>
                      <th>Description</th>
                      <th>Op</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillReportData &&
                      skillReportData.map((skillReportData, idx) => {
                        var ED = skillReportData.trainingDate.split(/\D/g);
                        var trainingDate = [ED[2], ED[1], ED[0]].join("-");
                        return (
                          <tr key={idx}>
                            <td> {skillReportData.batchName} </td>
                            <td>{skillReportData.memberName} </td>
                            <td>{skillReportData.memberDesg}</td>
                            <td>{trainingDate}</td>
                            <td>{skillReportData.programName}</td>
                            <td>{skillReportData.trainingDescription}</td>
                            <td>
                              <img
                                className="img_icon_size log"
                                onClick={() => onUpdate(skillReportData, idx)}
                                src={require("../../static/images/delete.png")}
                                alt="Deactivate"
                                title="Deactivate"
                              />{" "}
                              &nbsp;
                              <img
                                className="img_icon_size log"
                                onClick={() => onEdit(skillReportData, idx)}
                                src={require("../../static/images/edit_icon.png")}
                                alt="Edit"
                                title="Edit"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12 col-md-6 col-sm-11 col-11 align_right">
                <label>
                  <b>Count: {skillReportData.length}</b>
                </label>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">
                Edit Training Type Details{" "}
              </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditSkillTrainingDetail
              skillData={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">
                Delete Skill Training Details
              </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveSkillTrainingDetails
              trainingDeactiveData={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

SkillTrainingReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  skill: PropTypes.object.isRequired,
  getALLSkillReport: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
  skill: state.skill,
});

export default connect(mapStateToProps, {
  getActivePrograms,
  getALLSkillReport,
})(SkillTrainingReport);
