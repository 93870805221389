import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getActiveInstitutions } from "../../actions/shg";
import AllReligion from "./AllReligion";
import AllCaste from "./AllCaste";
import AllSubCaste from "./AllSubCaste";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Spinner from "../layout/Spinner";
import { useTranslation } from 'react-i18next';

const AllReligionCasteTypes = ({
  auth: { isAuthenticated, user, users,language },
  shg: { activeInstitutions },
  getActiveInstitutions,
}) => {
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);

  const { t } = useTranslation(); // Get the translation function `t` from i18next
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">{t("All Caste Settings")}</h2>
          <hr />
        </div>
        <section className="sub_reg">
          <Tabs>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab  style={{cursor: 'pointer'}}>{t("Religion")}</Tab>
                <Tab  style={{cursor: 'pointer'}}>{t("Caste")}</Tab>
                <Tab  style={{cursor: 'pointer'}}>{t("SubCaste")}</Tab>
              </TabList>
            </div>

            <TabPanel>
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <AllReligion />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <AllCaste />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <AllSubCaste />
              </div>
            </TabPanel>
          </Tabs>
        </section>
      </div>
    </Fragment>
  );
};

AllReligionCasteTypes.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getActiveInstitutions: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getActiveInstitutions,
})(AllReligionCasteTypes);
