import React, { useEffect, useState } from "react";
import Select from "react-select";

function ShgInternalLoan({
  setNewInternalLoan,
  NewInternalLoan,
  internalLoanData,
  allPurpose,
  Internalonly,
  selectedBank,
}) {
  const [formdata, setFormData] = useState({
    loanPayablePrincipalCash: 0, //internalLoanData?.loanPayablePrincipal || "",
    loanPayablePrincipalBank: 0,
    loanPayableInterestCash: 0, //internalLoanData?.loanPayableInterest || "",
    loanPayableInterestBank: 0,

    internalDuration: "", //internalLoanData?.internalDuration || "",
    TotalPayableAmt: "", //internalLoanData?.TotalPayableAmt || "",
    internalNominee: "", //internalLoanData?.internalNominee || "",
    //internalLoanData?.loanReason || "",
  });

  const {
    loanPayablePrincipalCash,
    loanPayablePrincipalBank,
    loanPayableInterestCash,
    loanPayableInterestBank,
    internalDuration,
    internalNominee,
    // loanReason,
  } = formdata;

  let drpdown  = [
    { label: "Loan 1", value: "Loan 1",loanStatus:"Closed" },
    { label: "Loan 2", value: "Loan 2",loanStatus:"Closed" },
    { label: "Loan 3", value: "Loan 3",loanStatus:"Closed" },
    { label: "Loan 4", value: "Loan 4",loanStatus:"Closed" },
    { label: "Loan 5", value: "Loan 5",loanStatus:"Closed" },
  ]
  // .filter((itemA) => {
  //   if (!Internalonly || Internalonly.length === 0) {
  //     // If Internalonly is empty or undefined, return all items
  //     return true;
  //   }
    
  //   // If Internalonly has data, filter based on your condition
  //   return Internalonly.some(
  //     (itemB) => itemA.value !== itemB.intLoanType && itemB.loanStatus === "Closed"
  //   );
  // });

  const [internalLoanTypeList, setInternalLoanTypeList] = useState();

  useEffect(() => {
    setInternalLoanTypeList(
      drpdown.filter(
        (itemA) =>
          !Internalonly.some((itemB) => itemA.value === itemB.intLoanType && itemA.loanStatus !== itemB.loanStatus)
      )
    );

  }, [NewInternalLoan]);

  const [intLoanType, setIntLoanType] = useState("");
  const [intLoanPurpose, setIntLoanPurpose] = useState("");
  const [showPurpose, setShowPurpose] = useState(false);

  const onSetIntLoanPurpose = (e) => {
    if (e.label === "Other") {
      setShowPurpose(true);
      setIntLoanPurpose(e);
    } else {
      setShowPurpose(false);
      setIntLoanPurpose(e);
    }
  };
  const handleKeyPress = (e) => {
    ["e", "E", "+", "-","." ].includes(e.key) && e.preventDefault();
  };
  const onInputChange = (e) => {
    setFormData({ ...formdata, [e.target.name]: e.target.value });

    // let targetField = e.target.name;
    // const value = e.target.value;
    // Regular expression to allow only numbers and one decimal point, up to 2 decimal places      
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // switch (targetField) {
    //   case "internalDuration":
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       setFormData({ ...formdata, [e.target.name]: value });
    //     }    
    //     break;
    //   case "loanPayableInterestCash":
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       setFormData({ ...formdata, [e.target.name]: value });
    //     }    
    //     break;
    //   case "loanPayableInterestBank":
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       setFormData({ ...formdata, [e.target.name]: value });
    //     }    
    //     break;
    //   case "loanPayablePrincipalCash":
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       setFormData({ ...formdata, [e.target.name]: value });
    //     }    
    //     break;
    //   case "loanPayablePrincipalBank":
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       setFormData({ ...formdata, [e.target.name]: value });
    //     }    
    //     break;
    //   default:
    //     setFormData({ ...formdata, [e.target.name]: e.target.value });
    //     break;
    // }
  };
  const onDone = (e) => {
    e.preventDefault();


      const isLoanTypeValid = !!intLoanType;
      const isLoanReasonValid = !!intLoanPurpose;
  
      if (!isLoanTypeValid) {
        console.error("Form is not valid. Please select Internal Loan Type.");
        setIsValidLoanType(false);
        return;
      }
  
      if (!isLoanReasonValid) {
        console.error("Form is not valid. Please select Loan Reason.");
        setIsValidLoanReason(false);
        return;
      }
  
      let {
        loanPayablePrincipalCash,
        loanPayableInterestCash,
        loanPayablePrincipalBank,
        loanPayableInterestBank,
        ...finalFormData
      } = formdata;
  
      setNewInternalLoan([
        {
          ...finalFormData,
          loanPayablePrincipal: {
            cash: loanPayablePrincipalCash || 0,
            bankAmt: loanPayablePrincipalBank || 0,
            bankName:
              loanPayablePrincipalBank != 0
                ? selectedBank && selectedBank.value
                : "",
          },
          loanPayableInterest: {
            cash: loanPayableInterestCash || 0,
            bankAmt: loanPayableInterestBank || 0,
            bankName:
              loanPayableInterestBank != 0
                ? selectedBank && selectedBank.value
                : "",
          },
  
          intLoanType: intLoanType.value,
          intLoanPurpose: intLoanPurpose.value,
          loanReasonId: intLoanPurpose._id,
          loanStatus: "Active",
          TotalPayableAmt:
            Number(loanPayableInterestBank || 0) +
            Number(loanPayablePrincipalBank || 0) +
            Number(loanPayableInterestCash || 0) +
            Number(loanPayablePrincipalCash || 0),
        },
        ...NewInternalLoan,
      ]);
  
      setFormData({
        loanPayablePrincipalCash: 0,
        loanPayableInterestCash: 0,
        loanPayablePrincipalBank: 0,
        loanPayableInterestBank: 0,
        internalDuration: "",
        TotalPayableAmt: "",
        internalNominee: "",
        loanPayablePrincipalCash: "",
  
        // loanReason: "",
      });
  
      setIntLoanType("");
      setIntLoanPurpose("");
      setIsValidLoanType(true);
      setIsValidLoanReason(true);

    
  };

  useEffect(() => {
    setFormData({
      loanPayablePrincipal:
        (internalLoanData && internalLoanData.loanPayablePrincipal) || "",
      loanPayableInterest:
        (internalLoanData && internalLoanData.loanPayableInterest) || "",
      internalDuration:
        (internalLoanData && internalLoanData.internalDuration) || "",
      TotalPayableAmt:
        (internalLoanData && internalLoanData.TotalPayableAmt) || "",
      internalNominee:
        (internalLoanData && internalLoanData.internalNominee) || "",
      // loanReason: (internalLoanData && internalLoanData.loanReason) || "",
    });
    setIntLoanType((internalLoanData && internalLoanData.intLoanType) || "");
    setIntLoanPurpose(
      (internalLoanData && internalLoanData.intLoanPurpose) || ""
    );
  }, []);

  const infoIcon = (titleValue) => {
    return (
      <>
        <img
          className="img_icon_size log mx-2"
          // src={require("../../../static/images/info.png")}
          src={require("../../static/images/info.png")}
          alt="Reason"
          style={{ height: "18px", width: "18px" }}
          title={titleValue}
        />
      </>
    );
  };
  const getSum = (cash, bank) => {
    const cashValue = isNaN(cash) ? 0 : Number(cash);
    const bankValue = isNaN(bank) ? 0 : Number(bank);
    return cashValue + bankValue;
  };

  const [isValidLoanType, setIsValidLoanType] = useState(true);
  const [isValidLoanReason, setIsValidLoanReason] = useState(true);
  const handleIntLoanTypeChange = (selectedOption) => {
    setIntLoanType(selectedOption);
    setIsValidLoanType(!!selectedOption);
  };
  const onSetIntLoanPurpose1 = (selectedOption) => {
    setIntLoanPurpose(selectedOption);
    setIsValidLoanReason(!!selectedOption);
  };

  return (
    <form
      onSubmit={(e) => {
        onDone(e);
      }}
    >
      <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
        <div className="col-lg-6  col-md-12 col-sm-12 col-12 cardUpdate">
          <label className="label-control">
            Internal Loan Type : <i className="colorRed ">*</i>{" "}
          </label>

          <Select
            name="loanPurpose"
            options={internalLoanTypeList}
            isSearchable={true}
            value={intLoanType}
            placeholder="Select Internal Loan Type"
            onChange={(e) => setIntLoanType(e)}
            theme={(theme) => ({
              ...theme,
              height: 26,
              minHeight: 26,
              borderRadius: 1,
              colors: {
                ...theme.colors,
                primary: "black",
              },
            })}
          />
          {!isValidLoanType && (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please select Internal Loan Type
            </div>
          )}

          <label className="label-control">
            Loan Sanctioned Principal
            {infoIcon("Loan Sanctioned Principal Cash and Bank Total value")}:&nbsp;(
            {getSum(loanPayablePrincipalCash, loanPayablePrincipalBank)})
            <i className="colorRed ">*</i>
          </label>
          <div className="row col-12">
            <div className=" row col-lg-6">
              <div className="col-lg-2 mt-2">Bank </div>
              <div className="col-lg-10">
                <input
                  type="number"
                  name="loanPayablePrincipalBank"
                  value={loanPayablePrincipalBank}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={handleKeyPress}
                  disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                  required={selectedBank ? true : false}
                  // required
                  // onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className=" row col-lg-6">
              <div className="col-lg-2 mt-2">Cash </div>{" "}
              <div className="col-lg-10">
                <input
                  type="number"
                  name="loanPayablePrincipalCash"
                  value={loanPayablePrincipalCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={handleKeyPress}
                  title="Enter Amount Greater Than 0"
                  pattern="[1-9][0-9]*"
                  min={loanPayablePrincipalBank ? "0" : "1"}
                  required
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </div>
          <label className="label-control">
            Duration (In Months) &nbsp;:<i className="colorRed">*</i>{" "}
          </label>

          <input
            type="number"
            name="internalDuration"
            value={internalDuration}
            placeholder="Enter Duration"
            className="form-control"
            onWheel={() => document.activeElement.blur()}
            onChange={(e) => onInputChange(e)}
            onKeyPress={handleKeyPress}
            title="Enter Month Greater Than 0"
            pattern="[1-9][0-9]*"
            min="1"
            required
          />
          <label className="label-control">Nominee : </label>
          <input
            type="text"
            name="internalNominee"
            value={internalNominee}
            className="form-control"
            placeholder="Enter Nominee"
            onWheel={() => document.activeElement.blur()}
            onChange={(e) => onInputChange(e)}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
          <label className="label-control">
            {" "}
            Loan Reason : <i className="colorRed">*</i>
          </label>
          <Select
            name="loanPurpose"
            options={allPurpose}
            isSearchable={true}
            value={intLoanPurpose}
            placeholder="Select Reason"
            onChange={(e) => onSetIntLoanPurpose(e)}
            theme={(theme) => ({
              ...theme,
              height: 26,
              minHeight: 26,
              borderRadius: 1,
              colors: {
                ...theme.colors,
                primary: "black",
              },
            })}
            required
          />
          {!isValidLoanReason && (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please select Loan Reason
            </div>
          )}
          <label className="label-control">
           Loan Receivable Interest{infoIcon("Loan Sanctioned Interest Cash and Bank Total value")}
            :&nbsp;(
            {getSum(loanPayableInterestCash, loanPayableInterestBank)})
            <i className="colorRed">*</i>
          </label>
          <div className="row col-12">
            <div className=" row col-lg-6">
              <div className="col-lg-2 mt-2">Bank </div>
              <div className="col-lg-10">
                <input
                  type="number"
                  name="loanPayableInterestBank"
                  value={loanPayableInterestBank}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={handleKeyPress}
                  disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                  required={selectedBank ? true : false}
                  // onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className=" row col-lg-6">
              <div className="col-lg-2 mt-2">Cash </div>{" "}
              <div className="col-lg-10">
                <input
                  type="number"
                  name="loanPayableInterestCash"
                  value={loanPayableInterestCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyPress={handleKeyPress}
                  required
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </div>
          <label className="label-control">Total Sanctioned Amount&nbsp;:&nbsp;</label>
          <input
            type="number"
            name="TotalPayableAmt"
            value={
              (Number(loanPayableInterestBank || 0) +
              Number(loanPayableInterestCash || 0) +
              Number(loanPayablePrincipalBank || 0) +
              Number(loanPayablePrincipalCash || 0))
            }
            className="form-control"
            // onWheel={() => document.activeElement.blur()}
            // onChange={(e) => onInputChange(e)}
            // onKeyDown={(e) => funcKeyDown(e)}
            disabled={true}
          />
          {/* {showPurpose ? (
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">Purpose Reason :</label>
              <input
                type="text"
                name="loanReason"
                value={loanReason}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          ) : (
            <></>
          )} */}
        </div>
      </div>

      <>
        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <button
              type="submit"
              name="Add"
              className="btn sub_form btn_continue Save float-right"
              disabled={
                loanPayablePrincipalBank == 0 && loanPayablePrincipalCash == 0
                  ? true
                  : false
              }
            >
              Add
            </button>
          </div>
        </div>
      </>
    </form>
  );
}

export default ShgInternalLoan;
