import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './locales/en.json';
import hn from './locales/hn.json';
// import kn from './locales/kn.json';  // Kannada
// import or from './locales/or.json';  // Oriya
// import ta from './locales/ta.json';  // Tamil

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    hn: { translation: hn },
    // kn: { translation: kn },
    // or: { translation: or },
    // ta: { translation: ta },
  },
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
