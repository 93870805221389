import React from "react";
import { useRef } from "react";
import { useEffect, useState, useLayoutEffect } from "react";
import logo from "../landing/assets/Images/Swashakthi-SHG-Logo.webp";
import banner from "../landing/assets/Images/Swashakthi-SHG-Banner.webp";
import about1 from "../landing/assets/Images/Swashakthi-SHG-About1.webp";
import about2 from "../landing/assets/Images/Swashakthi-SHG-About2.webp";
import about3 from "../landing/assets/Images/Swashakthi-SHG-About3.webp";
import SHGUsed from "../landing/assets/Images/Swashakthi-SHG-shg-can be used.webp";
import callToAction1 from "../landing/assets/Images/Swashakthi-SHG-call-to-action-1.webp";
import keyBenefits from "../landing/assets/Images/Swashakthi-SHG-Key-Benefits.webp";
import callToAction2 from "../landing/assets/Images/Swashakthi-SHG-call-to-action-2.webp";
import caseStudy1 from "../landing/assets/Images/Swashakthi-SHG-case-study-1.webp";
import caseStudy2 from "../landing/assets/Images/Swashakthi-SHG-case-study-2.webp";
import SHGWorks from "../landing/assets/Images/Swashakthi-SHG-How-SHG-works.webp";
import testimonial1 from "../landing/assets/Images/testimonials/Swashakthi-SHG-testimonial-1.webp";
import testimonial2 from "../landing/assets/Images/testimonials/Swashakthi-SHG-testimonial-2.webp";
import testimonial3 from "../landing/assets/Images/testimonials/Swashakthi-SHG-testimonial-3.webp";
import testimonial4 from "../landing/assets/Images/testimonials/Swashakthi-SHG-testimonial-4.webp";
import testimonial5 from "../landing/assets/Images/testimonials/Swashakthi-SHG-testimonial-5.webp";
import blog1 from "../landing/assets/Images/Swashakthi-SHG-Blog-1.webp";
import blog2 from "../landing/assets/Images/Swashakthi-SHG-Blog-2.webp";
import blog3 from "../landing/assets/Images/Swashakthi-SHG-Blog-3.webp";

import "../landing/assets/vendor/bootstrap/css/bootstrap.min.css";
import "../landing/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../landing/assets/vendor/glightbox/css/glightbox.min.css";
// import "../landing/assets/vendor/swiper/swiper-bundle.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GLightbox from "glightbox";
import emailjs from '@emailjs/browser';
import "../landing/assets/css/main.css";

import { Link } from "react-router-dom";

export default function LandingPage() {
  const [currentYear, setCurrentYear] = useState("");
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year.toString());
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease",
      once: false,
    });
  }, []);

  useLayoutEffect(() => {
    const initializeAOS = () => {
      AOS.init({ duration: 1000 });
    };

    window.addEventListener("load", initializeAOS);

    return () => {
      window.removeEventListener("load", initializeAOS);
    };
  }, []);

  // testimonial carousel
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      // dotListClass: "hide-dots",
    },
  };

  let carouselRef = React.createRef();

  // back to top icon
  const [isVisible, setIsVisible] = useState(false);

  // Toggle visibility of the back-to-top button
  const toggleScrollTop = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page
  const scrollToTop = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", toggleScrollTop);
    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener("scroll", toggleScrollTop);
    };
  }, []);

  // // active tabs

  const navRef = useRef(null);
  useEffect(() => {
    const handleTabClick = (event) => {
      const links = navRef.current.querySelectorAll(".navmenu a");
      links.forEach((link) => link.classList.remove("active")); // Remove active from all links
      event.target.classList.add("active"); // Add active to the clicked link
    };
    const links = navRef.current.querySelectorAll(".navmenu a");
    links.forEach((link) => link.addEventListener("click", handleTabClick));
    // Cleanup event listeners on component unmount
    return () => {
      links.forEach((link) =>
        link.removeEventListener("click", handleTabClick)
      );
    };
  }, []);

  // Frequently Asked Questions Toggle
  useEffect(() => {
    const faqItems = document.querySelectorAll(
      ".faq-item h3, .faq-item .faq-toggle"
    );
    const handleClick = (event) => {
      const parent = event.target.closest(".faq-item");
      if (parent) {
        parent.classList.toggle("faq-active");
      }
    };

    // Add event listeners
    faqItems.forEach((item) => {
      item.addEventListener("click", handleClick);
    });

    // Cleanup event listeners on unmount
    return () => {
      faqItems.forEach((item) => {
        item.removeEventListener("click", handleClick);
      });
    };
  }, []); // Empty dependency array ensures this runs once on mount

  // blogs
  function toggleContent(event) {
    event.preventDefault(); // Prevent the default link action

    // Find the parent post-content container
    const button = event.target;
    const postContent = button.closest(".post-content");

    // Find the extra-content div within the post
    const extraContent = postContent.querySelector(".extra-content");

    // Toggle visibility
    if (
      extraContent.style.display === "none" ||
      extraContent.style.display === ""
    ) {
      extraContent.style.display = "block"; // Show the extra content
      button.querySelector("span").textContent = "View Less"; // Update button text
    } else {
      extraContent.style.display = "none"; // Hide the extra content
      button.querySelector("span").textContent = "View More"; // Reset button text
    }
  }

  useEffect(() => {
    document.querySelectorAll(".btn-toggle").forEach((button) => {
      button.addEventListener("click", function() {
        const hiddenItems = this.parentElement.querySelector(".hidden-items");
        const isVisible = hiddenItems.style.display === "block";
        hiddenItems.style.display = isVisible ? "none" : "block";
        this.textContent = isVisible ? "Show More" : "Show Less";
      });
    });
  }, []);

  useEffect(() => {
    /**
     * Mobile nav toggle
     */
    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    function mobileNavToogle() {
      document.querySelector(".LP").classList.toggle("mobile-nav-active");
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    }
    mobileNavToggleBtn.addEventListener("click", mobileNavToogle);

    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });
  }, []);

  //  watch video
  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox",
    });
    return () => {
      lightbox.destroy();
    };
  }, []);




// form 
  const form = useRef();
  const [mailSentMsg, setMailSentMsg] = useState(false);
  const [errors, setErrors] = useState({});
  
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    user_city: '',
    user_subject: '',
    message: ''

  });

  // const formatDateTime = () => {
  //   const now = new Date();
  //   return now.toLocaleString('en-US', {
  //     year: 'numeric',
  //     month: 'short',
  //     day: 'numeric',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: true
  //   });
  // };

  const validateField = (name, value) => {
    switch (name) {
      case 'user_name':
        return /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets and spaces';
      
      case 'user_phone':
        return /^\d{10}$/.test(value) ? '' : 'Phone number should be exactly 10 digits';
      
      case 'user_email':
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Please enter a valid email address';
      
      case 'user_city':
        return /^[A-Za-z\s]+$/.test(value) ? '' : 'Location should contain only alphabets and spaces';
      
      case 'message':
        return value.length <= 1000 ? '' : 'Message should not exceed 1000 characters';
      
      default:
        return value.trim() ? '' : 'This field is required';
    }
  };

  const inputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Validate field on change
    const error = validateField(name, value);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate all fields
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };



const sendEmail = (e) => {
  e.preventDefault();
  if (validateForm()) {
  // Get current date time
  const now = new Date();
  const formattedDate = now.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  // Create template parameters
  const templateParams = {
    user_name: formData.user_name,
    user_email: formData.user_email,
    user_phone: formData.user_phone,
    user_city: formData.user_city,
    user_subject: formData.user_subject,
    message: formData.message,
    DateTime: formattedDate,
  };

  emailjs.send(
    process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY1
  )
  .then((response) => {
    setMailSentMsg(true);
    // Reset form
    setFormData({
      user_name: '',
      user_email: '',
      user_phone: '',
      user_city: '',
      user_subject: '',
      message: ''
    });
  })
  .catch((err) => {
    console.log("FAILED...", err);
  });
}
};
  return (
    <>
      <div className="LP">
        <header
          id="header"
          className="header d-flex align-items-center sticky-top"
        >
          <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center">
           
              <img
                src={logo}
                alt="logo"
                className="img-fluid logoImg"
                height={80}
                width={90}
              />
            </a>
            <nav id="navmenu" className="navmenu" ref={navRef}>
              <ul>
                <li>
                  <a href="#hero" className="active">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#keyFeatures">Features</a>
                </li>
                <li>
                  <a href="#keyBenefits">Benefits</a>
                </li>
                <li>
                  <a href="#pricing">Pricing</a>
                </li>
                <li>
                  <a href="#caseStudies">Case Studies</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="/Login" className="btn-get-started">
                    Login
                  </a>
                </li>
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list" />
            </nav>
          </div>
        </header>
        <main className="main">
          {/* Hero Section */}
          <section id="hero" className="hero section light-background">
            <div
              className="container position-relative"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="row gy-5">
                <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                  <h2>Swashakthi SHG</h2>
                  <p>
                    The portal helps across agencies to manage their SHG
                    functions, automating processes for better financial
                    reporting
                  </p>
                  <div className="d-flex">
                    <a href="#contact" className="btn-get-started">
                      Get Started
                    </a>
                    {/* <a href="https://www.youtube.com/watch?v=gF-SmZjEg_g" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle" /><span>Watch Video</span></a> */}
                    {/* <a href="https://www.youtube.com/watch?v=gF-SmZjEg_g" class="glightbox2">
              <span>Watch Video</span>
</a> */}
                    <a
                      href="https://www.youtube.com/watch?v=gF-SmZjEg_g"
                      className="glightbox btn-watch-video d-flex align-items-center"
                    >
                      <i className="bi bi-play-circle" />
                      <span>Watch Video</span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <img src={banner} className="img-fluid" alt="banner" />
                </div>
              </div>
            </div>
            <div
              className="icon-boxes position-relative"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="container position-relative">
                <div className="row gy-4 mt-5">
                  <div className="col-xl-3 col-md-6">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-gear" />
                      </div>
                      <h4 className="title">
                        <a href="#" className="stretched-link inactiveLink">
                          Streamlined SHG Operations
                        </a>
                      </h4>
                    </div>
                  </div>
                  {/*End Icon Box */}
                  <div className="col-xl-3 col-md-6">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-cloud" />
                      </div>
                      <h4 className="title">
                        <a href="#" className="stretched-link inactiveLink">
                          Remote Data Access
                        </a>
                      </h4>
                    </div>
                  </div>
                  {/*End Icon Box */}
                  <div className="col-xl-3 col-md-6">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-bar-chart" />
                      </div>
                      <h4 className="title">
                        <a href="#" className="stretched-link inactiveLink">
                          Improved Financial Reporting
                        </a>
                      </h4>
                    </div>
                  </div>
                  {/*End Icon Box */}
                  <div className="col-xl-3 col-md-6">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-chat-dots" />
                      </div>
                      <h4 className="title">
                        <a href="#" className="stretched-link inactiveLink">
                          Efficiency Support
                        </a>
                      </h4>
                    </div>
                  </div>
                  {/*End Icon Box */}
                </div>
              </div>
            </div>
          </section>
          {/* /Hero Section */}
          {/* About Section */}
          <section id="about" className="about section">
            <div className="container">
              <div className="row gy-4">
                <div
                  className="col-lg-6 content"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <p className="who-we-are">About the Application </p>
                  <h3>Unleashing Potential with Creative Strategy</h3>
                  <p className="fst-italic secondaryText-color fontText-small">
                    SwaShakthi SHG Portal: Empowering NGOs for Seamless
                    Self-Help Group Management The portal helps NGOs manage
                    Self-Help Groups (SHGs) with ease, automation, and remote
                    access.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle" />{" "}
                      <span>
                        Automated SHG Management: Streamlines group operations.
                      </span>
                    </li>
                    <li>
                      <i className="bi bi-check-circle" />{" "}
                      <span>
                        Remote Accessibility: Easy access to data from any
                        location.
                      </span>
                    </li>
                    <li>
                      <i className="bi bi-check-circle" />{" "}
                      <span>
                        Enhanced Financial Reporting: Improves financial
                        tracking and management.
                      </span>
                    </li>
                  </ul>
                  <a href="#contact" className="read-more">
                    <span>Get Started</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
                <div
                  className="col-lg-6 about-images"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <img src={about1} className="img-fluid" alt="about" />
                    </div>
                    <div className="col-lg-6">
                      <div className="row gy-4">
                        <div className="col-lg-12">
                          <img src={about2} className="img-fluid" alt="about" />
                        </div>
                        <div className="col-lg-12">
                          <img src={about3} className="img-fluid" alt="about" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /About Section */}
          {/* used Section */}
          <section id="features" className="features section light-background">
            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
              <h2>Applications</h2>
              <div>
                <span>Where </span>{" "}
                <span className="description-title">SHG</span> can be Used
              </div>
            </div>
            {/* End Section Title */}
            <div className="container">
              <div className="row gy-5 justify-content-between">
                <div
                  className="col-xl-5 d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay={100}
                >
                  <div>
                    <img src={SHGUsed} className="img-fluid" alt="shg used" />
                  </div>
                </div>
                <div className="col-xl-6 d-flex">
                  <div className="row align-self-center gy-4">
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Non-Governmental Organizations (NGOs)</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Community Development Institutions</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={400}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Government Programmes &amp; Agencies</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={500}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Civil Society Groups</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={600}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>States &amp; Commercial Banks</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={700}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Rural Livelihoods Programs</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={600}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Financial Institutions</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={700}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Poverty Management programmes</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={600}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>Rural Development Institutions</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={700}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className="bi bi-check" />
                        <h3>SHG Federations</h3>
                      </div>
                    </div>
                    {/* End Feature Item */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /used Section */}
          {/* Call To Action Section */}
          <section
            id="call-to-action"
            className="call-to-action section dark-background"
          >
            <div className="container">
              <img src={callToAction1} alt="call to action" />
              <div
                className="content row justify-content-center"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="col-xl-10">
                  <div className="text-center">
                    <a
                      href="https://www.youtube.com/watch?v=gF-SmZjEg_g"
                      className="glightbox play-btn"
                    />
                    <h3>Transform Your SHG Management Today</h3>
                    <p>
                      Empower your NGO with seamless Self-Help Group management,
                      enhanced financial tracking, and real-time reporting. Take
                      control with Swashakthi SHG Portal for improved efficiency
                      and transparency.
                    </p>
                    <a className="cta-btn" href="#contact">
                      Get Started Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Call To Action Section */}
          {/* Key Features Section */}
          <section
            id="keyFeatures"
            className="keyFeatures section light-background"
          >
            <div className="container section-title" data-aos="fade-up">
              <h2>Key Features </h2>
              <div>
                <span>Swashakthi SHG Application </span>{" "}
                <span className="description-title"> Key Features </span>
              </div>
            </div>
            <div className="container">
              <div className="row gy-4">
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="keyFeatures-item  position-relative">
                    <div className="icon">
                      <i className="bi bi-wallet" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Finance Management</h3>
                    </a>
                    <p>
                      The SHG application provides tools to track and manage
                      loans, savings, repayments, and financial balances. It
                      includes features like generating Income &amp; Expense
                      Reports, Loan Status Reports, and Asset &amp; Liabilities
                      Reports to give a clear picture of the group’s financial
                      health. Additionally, it offers detailed tracking of loan
                      disbursal, interest rates, repayments, and balances for
                      each member.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-people" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Member Management</h3>
                    </a>
                    <p>
                      The application tracks a comprehensive range of member
                      information, including personal data (e.g., Aadhar, Pan
                      Card), social details (e.g., Caste, Religion, Sub-Caste),
                      and financial details (e.g., Monthly Income, Occupation).
                      It also enables attendance tracking for meetings and
                      events, and provides Member-wise Reports to monitor
                      participation and financial transactions related to each
                      individual.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-calendar" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Meeting &amp; Event Management</h3>
                    </a>
                    <p>
                      The app allows users to schedule and track upcoming
                      meetings and events, ensuring better organization and
                      communication within the SHG. Members’ attendance can be
                      recorded for each meeting, and meeting screenshots can be
                      uploaded as evidence. The Verify Meeting feature ensures
                      that all meetings are documented and confirmed, with
                      options for Partial Meeting Tracking when full attendance
                      isn’t possible.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-bank" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Loan Management System</h3>
                    </a>
                    <p>
                      The loan management module enables tracking of loans
                      across different categories, such as SHG to SHG Loans and
                      Other Loan Types. It keeps a record of each loan’s status,
                      including disbursal, repayments, interest rates, and due
                      dates. Members can view detailed loan reports to monitor
                      their loan balances, repayments, and outstanding amounts.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-graph-up" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Reporting &amp; Analytics</h3>
                    </a>
                    <p>
                      The application generates a variety of customizable
                      reports, such as Blockwise, Tankwise, and Villagewise
                      Reports, offering valuable insights into group
                      performance, financial health, and historical data.
                      Reports can be filtered by year, batch, and SHG code to
                      analyze trends over time, making it easier for
                      administrators to make data-driven decisions.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-lightbulb" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Skill Development &amp; Training</h3>
                    </a>
                    <p>
                      The SHG app includes a feature for adding and tracking
                      skill programs and training sessions. Members can
                      participate in various training programs to enhance their
                      skills, with specific dates set for each training session.
                      The Skill Report provides an overview of member progress
                      in skill-building initiatives, supporting both personal
                      and professional development.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-geo-alt" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Location &amp; Area Management</h3>
                    </a>
                    <p>
                      The app allows users to manage and track SHG groups across
                      different regions, such as Blocks, Taluks, Tanks, Wards,
                      and Villages. You can create detailed location-based
                      reports and monitor performance and participation based on
                      geographical areas. This helps in targeted outreach and
                      better management of SHG activities across different
                      regions.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-person-plus" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>User &amp; Role Management</h3>
                    </a>
                    <p>
                      The SHG application features a robust user management
                      system, allowing admins to assign specific roles and
                      permissions to users (e.g., Coordinators, Incharges etc.).
                      This ensures smooth operations by clearly defining
                      responsibilities and access within the platform. Users can
                      be assigned roles that align with their responsibilities,
                      ensuring secure and effective management of the SHG.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <div className="keyFeatures-item position-relative">
                    <div className="icon">
                      <i className="bi bi-shield-lock" />
                    </div>
                    <a href="#" className="stretched-link inactiveLink">
                      <h3>Secure Document &amp; Cloud Storage</h3>
                    </a>
                    <p>
                      The app provides a secure document management system that
                      allows storing, retrieving, and organizing important
                      SHG-related documents. All data, including financial
                      records, member details, and meeting documents, are stored
                      in the cloud, ensuring that data is easily accessible and
                      protected from loss. This enhances data security and
                      allows for real-time updates and access.
                    </p>
                  </div>
                </div>
                {/* End Service Item */}
              </div>
            </div>
          </section>
          {/* /Services Section */}
          {/* /key benefits Section */}
          <section id="keyBenefits" className="keyBenefits section">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-7"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="container section-title" data-aos="fade-up">
                    <h2>Key Benefits</h2>
                    <div>
                      <span>Swashakthi SHG Application </span>{" "}
                      <span className="description-title"> Key Benefits</span>
                    </div>
                  </div>
                  {/* <h3>Your Business</h3> */}
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-cash-stack"
                          style={{ color: "#9dce3f" }}
                        />
                        <span>Enhanced Financial Management</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-person-lines-fill"
                          style={{ color: "#154c60" }}
                        />
                        <span>Efficient Member Management</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-clock"
                          style={{ color: "#ebc530" }}
                        />
                        <span>Time-Saving Automation</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-bar-chart"
                          style={{ color: "#4caf50" }}
                        />
                        <span>Customizable Reporting</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-translate"
                          style={{ color: "#4fa3d1" }}
                        />
                        <span>Multi-Language Support</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-lock"
                          style={{ color: "#ffab40" }}
                        />
                        <span>Secure Storage and Remote Access</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-check-circle"
                          style={{ color: "#333333" }}
                        />
                        <span>Compliance and Regulatory Tracking</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-arrows-expand"
                          style={{ color: "#f44336" }}
                        />
                        <span>Scalability and Flexibility</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-coin"
                          style={{ color: "#b39ddb" }}
                        />
                        <span>Cost Efficiency</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-shield-lock"
                          style={{ color: "#7e57c2" }}
                        />
                        <span>Increased Transparency and Accountability</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-graph-up"
                          style={{ color: "#c9ac0c" }}
                        />
                        <span>Data-Driven Insights for Decision Making</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-person-up"
                          style={{ color: "#81d4fa" }}
                        />
                        <span>Enhanced Member Development and Training</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-chat-dots"
                          style={{ color: "#00897b" }}
                        />
                        <span>Improved Communication and Coordination</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i
                          className="bi bi-folder"
                          style={{ color: "#ff6f61" }}
                        />
                        <span>Less Paperwork and Admin Tasks</span>
                      </div>
                    </div>
                    {/* End Icon List Item*/}
                  </div>
                </div>
                <div
                  className="col-lg-5 position-relative"
                  data-aos="zoom-out"
                  data-aos-delay={200}
                >
                  <div className="phone-wrap">
                    <img src={keyBenefits} alt="Image" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="details">
              <div className="container">
                <div className="row">
                  {/* <div class="col-md-6" data-aos="fade-up" data-aos-delay="300">
      <h4>Labore Sdio Lidui<br>Bonde Naruto</h4>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam nostrum molestias doloremque quae delectus odit minima corrupti blanditiis quo animi!</p>
      <a href="#about" class="btn-get-started">Get Started</a>
    </div> */}
                </div>
              </div>
            </div>
          </section>
          {/* /key benefits Section */}
          {/* Call To Action2 Section */}
          <section
            id="call-to-action2"
            className="call-to-action2 section dark-background"
          >
            <img src={callToAction2} alt="call to action" />
            <div className="container">
              <div
                className="row justify-content-center"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="col-xl-10">
                  <div className="text-center">
                    <h3>Revolutionize Your SHG Management with Ease</h3>
                    <p>
                      Unlock the power of efficient SHG management with
                      Swashakthi. Simplify financial tracking, enhance
                      reporting, and boost transparency for your NGO’s success.
                    </p>
                    <a className="cta-btn" href="#contact">
                      Start Your Journey Today
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Call To Action2 Section */}
          {/* caseStudies Section */}
          <section id="caseStudies" className="caseStudies section">
            <div className="container section-title" data-aos="fade-up">
              <h2>Case Studies</h2>
              <div>
                <span>Swashakthi SHG Application </span>{" "}
                <span className="description-title"> Case Studies</span>{" "}
              </div>
            </div>
            <div className="container">
              <div className="row gy-4 align-items-center caseStudies-item">
                <div
                  className="col-md-5 d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay={100}
                >
                  <img
                    src={caseStudy1}
                    className="img-fluid"
                    alt="case Study"
                  />
                </div>
                <div
                  className="col-md-7"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>ASSA Case Study</h3>
                  <p className="fst-italic secondaryText-color fontText-small">
                    Amar Seva Sangam (ASSA) is a non-profit based in Tamil Nadu,
                    India, that supports people with disabilities. They had been
                    running Self-Help Groups to empower people with disabilities
                    but faced difficulties in managing SHG finances and
                    accessing real-time data.
                  </p>
                  <ul>
                    {/* <li><i class="bi bi-check"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></li> */}
                    <li>
                      <i className="bi bi-check" />
                      <span>
                        OBJECTIVE &amp; SOLUTION : ASSA sought a solution for
                        better SHG financial management, data access, and
                        reporting. With the implementation of Swashakthi SHG,
                        ASSA was able to track loans, savings, and interest in
                        real-time, enabling better financial decision-making.
                        The customized reports allowed them to gain operational
                        insights, while the system's responsiveness addressed
                        queries promptly, improving the overall SHG management
                        process..
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Features Item */}
              <div className="row gy-4 align-items-center caseStudies-item">
                <div
                  className="col-md-5 order-1 order-md-2 d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay={200}
                >
                  <img
                    src={caseStudy2}
                    className="img-fluid"
                    alt="case study"
                  />
                </div>
                <div
                  className="col-md-7 order-2 order-md-1"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <h3>SAMPADA Case Study</h3>
                  <p className="fst-italic secondaryText-color fontText-small">
                    SAMPADA is a non-profit based in Udupi, Karnataka, focused
                    on the upliftment of people from socio-economic backgrounds.
                    The organization had been using a free software solution but
                    faced challenges in updating it and managing SHG operations.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check" />
                      <span>
                        OBJECTIVE &amp; SOLUTION : SAMPADA needed a robust
                        platform to manage loans, savings, and interests, and
                        ensure access to real-time data. After customizing
                        Swashakthi SHG to their needs, SAMPADA could track all
                        financial transactions in real time and generate reports
                        for better decision-making. The platform helped them
                        address their operational concerns and improve their
                        financial reporting processes.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Features Item */}
            </div>
          </section>
          {/* caseStudies Section */}
          {/* SHGWorks Section */}
          <section id="SHGWorks" className="SHGWorks section">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <img
                      src={SHGWorks}
                      alt="Image "
                      className="img-fluid img-overlap"
                      data-aos="zoom-out"
                    />
                  </div>
                  <div
                    className="col-lg-5 "
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <h3 className="content-subtitle text-white opacity-50">
                      How Swashakthi SHG Works?{" "}
                    </h3>
                    <h2 className="content-title mb-4">
                      Seamless Setup, Tailored Solutions, and Continuous
                      Assistance
                      {/* <strong>50 year experience</strong> */}
                    </h2>
                    <p className="opacity-50">
                      From easy onboarding to tailored solutions and continuous
                      support, Swashakthi SHG ensures a smooth and efficient
                      experience for your Self-Help Groups, empowering you to
                      manage and grow with ease.
                    </p>
                    <div className="row my-5">
                      <div className="col-lg-12 d-flex align-items-start mb-4">
                        <i className="bi bi-person-plus me-4 display-6" />
                        <div>
                          <h4 className="m-0 h5 text-white">Onboarding</h4>
                          <p className="text-white opacity-50">
                            Guided setup to integrate your SHG operations
                            seamlessly into the platform.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 d-flex align-items-start mb-4">
                        <i className="bi bi-gear me-4 display-6" />
                        <div>
                          <h4 className="m-0 h5 text-white">Customization</h4>
                          <p className="text-white opacity-50">
                            Personalize workflows, reports, and features to
                            align with your specific needs.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 d-flex align-items-start">
                        <i className="bi bi-headset me-4 display-6" />
                        <div>
                          <h4 className="m-0 h5 text-white">
                            Training &amp; Support
                          </h4>
                          <p className="text-white opacity-50">
                            Support and training resources to keep your SHG
                            operations running smoothly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /SHGWorks Section */}
          {/* Pricing Section */}
          <section id="pricing" className="pricing section">
            <div className="container section-title" data-aos="fade-up">
              <h2>Pricing</h2>
              <div>
                <span>Swashakthi SHG Application </span>{" "}
                <span className="description-title">Pricing</span>{" "}
              </div>
            </div>
            <div className="container">
              <div className="row gy-4">
                <div
                  className="col-lg-3 col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="pricing-tem">
                    <h3 style={{ color: "#20c997" }}>Basic Plan</h3>
                    <div className="price">
                      <sup>₹</sup>999*<span> / mo</span>
                    </div>
                    <div className="icon">
                      <i className="bi bi-box" style={{ color: "#20c997" }} />
                    </div>
                    <ul>
                      {/* Always visible items */}
                      <li>Dashboard</li>
                      <li>Add Batches</li>
                      <li>Add Members</li>
                      <li>Add Meeting</li>
                      <li>Partial Meeting</li>
                      <li>Verify Meeting</li>
                      <li>Area Management</li>
                      <li>MIS Reports</li>
                      <li>User Management</li>
                      {/* Hidden items */}
                      <div className="hidden-items" style={{ display: "none" }}>
                        <li className="na">Profile Management</li>
                        <li className="na">Training Videos</li>
                        <li className="na">Member Module-1</li>
                        <li className="na">Batch Module</li>
                        <li className="na">Co-Ordinator Module</li>
                        <li className="na">Incharge Module</li>
                        <li className="na">Category Module</li>
                        <li className="na">Occupation Module</li>
                        <li className="na">Member Module-2</li>
                        <li className="na">Blockwise Module</li>
                        <li className="na">Report Module</li>
                        <li className="na">Skill Module</li>
                        <li className="na">Meeting Module</li>
                        <li className="na">Member Family Module</li>
                        <li className="na">Multilingual Module</li>
                        <li className="na">Scan Module</li>
                      </div>
                    </ul>
                    <button
                      className="btn-toggle viewMoreLess-btn"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      Show More
                    </button>
                    <a href="#contact" className="btn-buy">
                      Get Started Now
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  <div className="pricing-tem">
                    <span className="featured">Featured</span>
                    <h3 style={{ color: "#0dcaf0" }}>Standard Plan</h3>
                    <div className="price">
                      <sup>₹</sup>1999*<span> / mo</span>
                    </div>
                    <div className="icon">
                      <i className="bi bi-send" style={{ color: "#0dcaf0" }} />
                    </div>
                    <ul>
                      {/* Always visible items */}
                      <li>Dashboard</li>
                      <li>Add Batches</li>
                      <li>Add Members</li>
                      <li>Add Meeting</li>
                      <li>Partial Meeting</li>
                      <li>Verify Meeting</li>
                      <li>Area Management</li>
                      <li>MIS Reports</li>
                      <li>User Management</li>
                      {/* Hidden items */}
                      <div className="hidden-items" style={{ display: "none" }}>
                        <li>Profile Management</li>
                        <li>Training Videos</li>
                        <li>Member Module-1</li>
                        <li>Batch Module</li>
                        <li>Co-Ordinator Module</li>
                        <li>Incharge Module</li>
                        <li>Category Module</li>
                        <li>Occupation Module</li>
                        <li className="na">Member Module-2</li>
                        <li className="na">Blockwise Module</li>
                        <li className="na">Report Module</li>
                        <li className="na">Skill Module</li>
                        <li className="na">Meeting Module</li>
                        <li className="na">Member Family Module</li>
                        <li className="na">Multilingual Module</li>
                        <li className="na">Scan Module</li>
                      </div>
                    </ul>
                    <button
                      className="btn-toggle viewMoreLess-btn"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      Show More
                    </button>
                    <a href="#contact" className="btn-buy">
                      Get Started Now
                    </a>
                  </div>
                </div>
                {/* End Pricing Item */}
                <div
                  className="col-lg-3 col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay={300}
                >
                  <div className="pricing-tem">
                    <h3 style={{ color: "#fd7e14" }}>Advanced Plan</h3>
                    <div className="price">
                      <sup>₹</sup>2999*<span> / mo</span>
                    </div>
                    <div className="icon">
                      <i
                        className="bi bi-airplane"
                        style={{ color: "#fd7e14" }}
                      />
                    </div>
                    <ul>
                      <li>Dashboard</li>
                      <li>Add Batches</li>
                      <li>Add Members</li>
                      <li>Add Meeting</li>
                      <li>Partial Meeting</li>
                      <li>Verify Meeting</li>
                      <li>Area Management</li>
                      <li>MIS Reports</li>
                      <li>User Management</li>
                      {/* Hidden items */}
                      <div className="hidden-items" style={{ display: "none" }}>
                        <li>Profile Management</li>
                        <li>Training Videos</li>
                        <li>Member Module-1</li>
                        <li>Batch Module</li>
                        <li>Co-Ordinator Module</li>
                        <li>Incharge Module</li>
                        <li>Category Module</li>
                        <li>Occupation Module</li>
                        <li>Member Module-2</li>
                        <li>Blockwise Module</li>
                        <li>Report Module</li>
                        <li>Skill Module</li>
                        <li className="na">Meeting Module</li>
                        <li className="na">Member Family Module</li>
                        <li className="na">Multilingual Module</li>
                        <li className="na">Scan Module</li>
                      </div>
                    </ul>
                    <button
                      className="btn-toggle viewMoreLess-btn"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      Show More
                    </button>
                    <a href="#contact" className="btn-buy">
                      Get Started Now
                    </a>
                  </div>
                </div>
                {/* End Pricing Item */}
                <div
                  className="col-lg-3 col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay={400}
                >
                  <div className="pricing-tem">
                    <h3 style={{ color: "#0d6efd" }}>Ultimate Plan</h3>
                    <div className="price">
                      <sup />
                      <span> contact us</span>
                    </div>
                    <div className="icon">
                      <i
                        className="bi bi-rocket"
                        style={{ color: "#0d6efd" }}
                      />
                    </div>
                    <ul>
                      <li>Dashboard</li>
                      <li>Add Batches</li>
                      <li>Add Members</li>
                      <li>Add Meeting</li>
                      <li>Partial Meeting</li>
                      <li>Verify Meeting</li>
                      <li>Area Management</li>
                      <li>MIS Reports</li>
                      <li>User Management</li>
                      {/* Hidden items */}
                      <div className="hidden-items" style={{ display: "none" }}>
                        <li>Profile Management</li>
                        <li>Training Videos</li>
                        <li>Member Module-1</li>
                        <li>Batch Module</li>
                        <li>Co-Ordinator Module</li>
                        <li>Incharge Module</li>
                        <li>Category Module</li>
                        <li>Occupation Module</li>
                        <li>Member Module-2</li>
                        <li>Blockwise Module</li>
                        <li>Report Module</li>
                        <li>Skill Module</li>
                        <li>Meeting Module</li>
                        <li>Member Family Module</li>
                        <li>Multilingual Module</li>
                        <li>Scan Module</li>
                      </div>
                    </ul>
                    <button
                      className="btn-toggle viewMoreLess-btn"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      Show More
                    </button>
                    <a href="#contact" className="btn-buy">
                      Get Started Now
                    </a>
                  </div>
                </div>
                {/* End Pricing Item */}
              </div>
              {/* End pricing row */}
              <div className="gy-4 mt-5E text-left">
                <p
                  className="my-5 fontText-small"
                  style={{ fontStyle: "italic" }}
                >
                  * The above prices are inclusive of GST and represent the
                  starting price, with the actual price depending on the
                  client's requirements.
                </p>
              </div>
            </div>
          </section>
          {/* /Pricing Section */}
          {/* Faq Section */}
          <section id="faq" className="faq section light-background">
            <div className="container">
              <div className="row gy-4">
                <div
                  className="col-lg-4"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="content px-xl-5">
                    <h3>
                      <span>Frequently Asked </span>
                      <strong>Questions</strong>
                    </h3>
                    <p>
                      This section will address common questions clients often
                      have regarding the Swashakthi SHG application, providing
                      clarity on key aspects of the process and requirements.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-8"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <div className="faq-container">
                    <div className="faq-item faq-active">
                      <h3>
                        <span className="num">1.</span>{" "}
                        <span>What is Swashakthi SHG?</span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Swashakthi SHG is a SaaS-based portal that helps
                          organizations such as NGOs, government agencies, and
                          financial institutions efficiently manage Self-Help
                          Groups (SHGs), automating processes and improving
                          financial reporting.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">2.</span>{" "}
                        <span>Who can use the Swashakthi SHG portal?</span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          The portal is designed for use by various
                          organizations, including NGOs, government programs,
                          commercial banks, rural development institutions, and
                          SHG federations to enhance group management and
                          reporting.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">3.</span>{" "}
                        <span>
                          How does Swashakthi SHG help improve financial
                          management?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Swashakthi SHG offers tools to track loans, savings,
                          and repayments while generating reports that provide
                          insights into the financial health of the group,
                          enabling better decision-making.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">4.</span>{" "}
                        <span>
                          Can the application track member information?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Yes, Swashakthi SHG tracks detailed member information
                          such as personal, social, and financial data,
                          including attendance for meetings and events, ensuring
                          efficient member management.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">5.</span>{" "}
                        <span>
                          How does the meeting and event management feature
                          work?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Swashakthi SHG allows users to schedule, track, and
                          document meetings and events, recording attendance and
                          providing options to upload meeting screenshots for
                          verification and documentation.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">6.</span>{" "}
                        <span>Is there a feature for loan management?</span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Yes, the portal includes a loan management system that
                          tracks various types of loans, including SHG-to-SHG
                          loans, and monitors disbursements, repayments,
                          interest rates, and due dates.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">7.</span>{" "}
                        <span>What types of reports can be generated?</span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Swashakthi SHG generates a range of customizable
                          reports, including financial and member-specific
                          reports, with the ability to filter by location, year,
                          and SHG code for in-depth analysis.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">8.</span>{" "}
                        <span>
                          How does Swashakthi SHG support skill development and
                          training?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          The portal includes a feature to track skill
                          development programs, allowing users to add training
                          sessions, monitor member progress, and generate
                          reports on skills development for each participant.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">9.</span>{" "}
                        <span>Is the data stored securely?</span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Yes, all data in Swashakthi SHG is stored securely in
                          the cloud, ensuring easy access, data protection, and
                          real-time updates for members and organizations.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">10.</span>{" "}
                        <span>Can the portal be accessed remotely?</span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Yes, Swashakthi SHG allows for remote access to data,
                          enabling users to manage and monitor SHG operations
                          from any location at their convenience.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">11.</span>{" "}
                        <span>
                          What are the subscription plans for the Swashakthi SHG
                          portal?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Swashakthi SHG operates on a yearly subscription
                          basis, with pricing based on the organization’s size
                          and specific feature requirements; please contact us
                          for detailed pricing information.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">12.</span>{" "}
                        <span>
                          Can Swashakthi SHG be customized to meet specific
                          needs?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Yes, the portal is customizable to meet the specific
                          needs of different organizations, allowing for
                          tailored reports, loan management features, and member
                          tracking options.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">13.</span>{" "}
                        <span>
                          How can I get support for using the Swashakthi SHG
                          portal?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Swashakthi SHG provides responsive customer support
                          through various channels, including email, phone, and
                          live chat, ensuring assistance is available whenever
                          you need it.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">14.</span>{" "}
                        <span>Is there multi-language support available?</span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          Yes, Swashakthi SHG offers multi-language support to
                          accommodate users across different regions, making the
                          platform more accessible to diverse communities.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                    <div className="faq-item">
                      <h3>
                        <span className="num">15.</span>{" "}
                        <span>
                          How can I request a demo or trial of the portal?
                        </span>
                      </h3>
                      <div className="faq-content">
                        <p>
                          To request a demo or trial, simply contact us via our
                          website or customer support, and a representative will
                          guide you through the platform’s features and
                          benefits.
                        </p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right" />
                    </div>
                    {/* End Faq item*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Faq Section */}
          {/* Testimonials Section */}
          <section id="testimonials" className="testimonials section">
            <div className="container section-title" data-aos="fade-up">
              <h2>Testimonials</h2>
              <div>
                <span>What Our Client Says About the</span>{" "}
                <span className="description-title">
                  Swashakthi SHG Application
                </span>{" "}
              </div>
            </div>
            <div className="container" data-aos="fade-up" data-aos-delay={100}>
              <div
                className="col-lg-12 carousel-wrapper pb-5"
                data-aos="zoom-in-up"
              >
                <Carousel
                  responsive={responsive}
                  swipeable={false}
                  draggable={true}
                  showDots={true}
                  ssr={true}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={5000}
                  keyBoardControl={true}
                  customTransition="all .5s ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  // removeArrowOnDeviceType={["desktop","tablet"]}
                  // dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  className="userSegmentSection"
                  ref={carouselRef}
                  arrows={false}
                  dotListClass="custom-dot-list-style"
                >
                  <div className="item ">
                    <div className="testimonial-item">
                      <img src={testimonial1} className="testimonial-img" alt />
                      <h3>Fr. Reginald</h3>
                      <h4>Director</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left" />
                        <span>
                          The product delivered to us "SwashakthiSHG Management'
                          is tailored made as per our request.In each and every
                          step, the staff of Pinnacle Technologies, clarified
                          their doubts and made sure that what we require is
                          implemented without any hitches.They clarified our
                          doubts whenever we asked, immediately.The app they
                          prepared is very useful to SAMPADA UDUPI.Now the data
                          is in our fingertips. Thank you, Mr. Joel and team.
                        </span>
                        <i className="bi bi-quote quote-icon-right" />
                      </p>
                    </div>
                  </div>
                  <div className="item ">
                    <div className="testimonial-item">
                      <img
                        src={testimonial2}
                        className="testimonial-img"
                        alt="testimonial"
                      />
                      <h3>Fr. Teyol</h3>
                      <h4>Director</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left" />
                        <span>
                          The Swashakthi SHG Portal has been a real asset to our
                          operations. The ability to automate financial
                          management, track loans, and generate reports has
                          streamlined everything.The remote access feature has
                          made it easy for our team to stay updated.With
                          improved reporting, we can make more informed
                          decisions, and member management is now much more
                          efficient.A truly transformative tool! Thank you
                          Pinnacle Technologies for this software
                        </span>
                        <i className="bi bi-quote quote-icon-right" />
                      </p>
                    </div>
                  </div>
                  <div className="item ">
                    <div className="testimonial-item">
                      <img src={testimonial3} className="testimonial-img" alt />
                      <h3>Theodore</h3>
                      <h4>Director</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left" />
                        <span>
                          A great service provider for us in terms of setting up
                          an application to track our NGO's Self Help Groups.
                          The team is proactive and responds to our queries as
                          early as possible.
                        </span>
                        <i className="bi bi-quote quote-icon-right" />
                      </p>
                    </div>
                  </div>
                  <div className="item ">
                    <div className="testimonial-item">
                      <img src={testimonial4} className="testimonial-img" alt />
                      <h3>Mathubala</h3>
                      <h4>Manager</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left" />
                        <span>
                          The Swashakthi SHG Portal has revolutionized how we
                          manage our groups.It allows for seamless tracking of
                          loans, repayments, and member data, all in one
                          place.The ability to generate reports quickly has
                          saved us a lot of time.I particularly appreciate the
                          member management system and its detailed
                          tracking.It's a comprehensive solution that brings
                          efficiency and transparency to our operations.
                        </span>
                        <i className="bi bi-quote quote-icon-right" />
                      </p>
                    </div>
                  </div>
                  <div className="item ">
                    <div className="testimonial-item">
                      <img src={testimonial5} className="testimonial-img" alt />
                      <h3>Fr. Don</h3>
                      <h4>Director</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                        <i className="bi bi-star-fill" />
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left" />
                        <span>
                          Swashakthi SHG has brought tremendous improvement to
                          our work.It’s made tracking loans and financial data
                          so much simpler.The ability to generate customized
                          reports means we can track group performance more
                          effectively.I particularly love the remote access
                          feature – it allows us to stay connected even when
                          we're on the go.The app has helped streamline many of
                          our administrative tasks. Highly recommend it!
                        </span>
                        <i className="bi bi-quote quote-icon-right" />
                      </p>
                    </div>
                  </div>

                  {/* Add more divs as needed */}
                </Carousel>
             
              </div>

            
            </div>
          </section>
          {/* /Testimonials Section */}
          {/* Contact Section */}
          <section id="contact" className="contact section light-background">
            <div className="container section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <div>
                <span>Get Started With The</span>{" "}
                <span className="description-title">
                  Swashakthi SHG Application
                </span>{" "}
                Today!
              </div>
            </div>
            <div className="container" data-aos="fade" data-aos-delay={100}>
              <div className="row gy-4">
                <div className="col-lg-4">
                  <div
                    className="info-item d-flex"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <i className="bi bi-geo-alt flex-shrink-0" />
                    <div>
                      <h3>Address</h3>
                      <p>
                        2nd floor, Kadiyali Complex, Kadiyali,
                        <br /> Kunjibettu, Udupi 576102
                      </p>
                    </div>
                  </div>
                  {/* End Info Item */}
                  <div
                    className="info-item d-flex"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <i className="bi bi-telephone flex-shrink-0" />
                    <div>
                      <h3>Call Us</h3>
                      <p>+91 9916213542 / +91 9663514001</p>
                    </div>
                  </div>
                  {/* End Info Item */}
                  <div
                    className="info-item d-flex"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    <i className="bi bi-envelope flex-shrink-0" />
                    <div>
                      <h3>Email Us</h3>
                      <p>sales@pinnacletechnologies.in</p>
                    </div>
                  </div>
                  {/* End Info Item */}
                </div>
                <div className="col-lg-8">

<form
      ref={form}
      onSubmit={sendEmail}
      className="php-email-form"
      name="google-sheet"
      data-aos="fade-up"
      data-aos-delay={200}
    >
      
      <div className="row gy-4">
        <div className="col-md-6 ">
          <input
            type="text"
            className={`form-control ${errors.user_name ? 'is-invalid' : ''}`}
            id="user_name"
            name="user_name"
            placeholder="Your Name"
            value={formData.user_name}
            onChange={inputChange}
            required
          />
          &nbsp;{errors.user_name && (
            <span id="selectErrorName" style={{ color: "#ff0000", fontSize: "14px" }}>
              {errors.user_name}
            </span>
          )}
        </div>

        <div className="col-md-6 ">
          <input
            type="email"
            className={`form-control ${errors.user_email ? 'is-invalid' : ''}`}
            id="user_email"
            name="user_email"
            value={formData.user_email}
            onChange={inputChange}
            placeholder="Your Email"
            required
          />
          &nbsp;{errors.user_email && (
            <span id="selectErrorEmail" style={{ color: "#ff0000", fontSize: "14px" }}>
              {errors.user_email}&nbsp;
            </span>
          )}
        </div>

        <div className="col-md-6 formInputTop">
          <input
            type="tel"
            className={`form-control ${errors.user_phone ? 'is-invalid' : ''}`}
            id="user_phone"
            name="user_phone"
            value={formData.user_phone}
            onChange={inputChange}
            placeholder="Your Phone"
            required
          />
          &nbsp;{errors.user_phone && (
            <span id="selectErrorPhone" style={{ color: "#ff0000", fontSize: "14px" }}>
              {errors.user_phone}&nbsp;
            </span>
          )}
        </div>

        <div className="col-md-6 formInputTop">
          <input
            type="text"
            className={`form-control ${errors.user_city ? 'is-invalid' : ''}`}
            id="user_city"
            name="user_city"
            value={formData.user_city}
            placeholder="Your Location"
            onChange={inputChange}
            required
          />
          &nbsp;{errors.user_city && (
            <span style={{ color: "#ff0000", fontSize: "14px" }}>
              {errors.user_city}&nbsp;
            </span>
          )}
        </div>

        <div className="col-md-12 formInputTop">
          <input
            type="text"
            className={`form-control ${errors.user_subject ? 'is-invalid' : ''}`}
            id="user_subject"
            name="user_subject"
            value={formData.user_subject}
            placeholder="Your Subject"
            onChange={inputChange}
            required
          />
          &nbsp;{errors.user_subject && (
            <span id="selectError" style={{ color: "#ff0000", fontSize: "14px" }}>
              {errors.user_subject}&nbsp;
            </span>
          )}
        </div>

        <div className="col-md-12 formInputTop">
          <textarea
            className={`form-control ${errors.message ? 'is-invalid' : ''}`}
            placeholder="Leave a message here"
            id="message"
            name="message"
            value={formData.message}
            rows={6}
            onChange={inputChange}
            // style={{ height: "120px" }}
            required
          />
         &nbsp; {errors.message && (
            <span style={{ color: "#ff0000", fontSize: "14px" }}>
              {errors.message}&nbsp;
            </span>
          )}
        </div>

        <div className="col-md-12 text-center">
          <button type="submit">Send Message</button>
        </div>
      </div>

      {mailSentMsg && (
        <div className="d-flex justify-content-center align-item-center my-3 sent-message">
          <span>
            Thank you for contacting us. One of our representatives
            will be in touch with you soon.
          </span>
        </div>
      )}
    </form>

                </div>
                {/* End Contact Form */}
              </div>
            </div>
          </section>
          {/* /Contact Section */}
          {/* Recent Posts Section */}
          <section id="recent-posts" className="recent-posts section">
            <div className="container section-title" data-aos="fade-up">
              <h2>Blogs</h2>
              <div>
                <span>Explore Our Latest Posts Related to the </span>{" "}
                <span className="description-title">
                  Swashakthi SHG Application
                </span>{" "}
              </div>
            </div>
            <div className="container">
              <div className="row gy-5">
                <div className="col-xl-4 col-md-6">
                  <div
                    className="post-item position-relative h-100"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <div className="post-img position-relative overflow-hidden">
                      <img src={blog1} className="img-fluid" alt="blog" />
                      <span className="post-date">01st Jan'25</span>
                    </div>
                    <div className="post-content d-flex flex-column">
                      <h3 className="post-title">
                        Empowering NGOs: How Swashakthi SHG Revolutionizes
                        Self-Help Group Management
                      </h3>
                      <div className="meta">
                        <span>
                          Discover how Swashakthi SHG Portal transforms
                          Self-Help Group (SHG) operations for NGOs, offering
                          seamless management, enhanced financial reporting, and
                          remote access.
                        </span>
                      </div>
                      {/* Hidden content for "View More" */}
                      <div
                        className="extra-content"
                        style={{ display: "none" }}
                      >
                        <p>
                          Managing Self-Help Groups (SHGs) can be a complex and
                          time-consuming task for NGOs, especially when dealing
                          with financial tracking, member management, and
                          meeting coordination. With multiple data points to
                          consider, SHG leaders often struggle to maintain
                          transparency and efficiency. This is where Swashakthi
                          SHG Portal comes in. By automating essential
                          processes, the platform empowers NGOs to streamline
                          operations and improve financial reporting.
                          <br />
                          One of the standout features is its ability to provide
                          real-time access to data, regardless of location. This
                          remote accessibility allows NGOs to manage multiple
                          SHGs across regions, enhancing coordination and
                          communication. Through automated loan tracking, member
                          records, and meeting schedules, organizations save
                          time while improving transparency and accountability.
                          <br />
                          Swashakthi's advanced financial reporting tools ensure
                          that NGOs have accurate, up-to-date financial
                          insights, enabling better decision-making. Whether
                          it's tracking savings, loans, repayments, the portal's
                          robust system simplifies what used to be a tedious
                          process.
                        </p>
                      </div>
                      <hr />
                     
                      <a
                        href="#"
                        className="readmore stretched-link"
                        onClick={(event) => toggleContent(event)}
                      >
                        <span>View More</span>
                        <i className="bi bi-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                {/* End post item */}
                <div className="col-xl-4 col-md-6">
                  <div
                    className="post-item position-relative h-100"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <div className="post-img position-relative overflow-hidden">
                      <img src={blog2} className="img-fluid" alt="blog" />
                      <span className="post-date">6th Jan'25</span>
                    </div>
                    <div className="post-content d-flex flex-column">
                      <h3 className="post-title">
                        The Importance of Real-Time Financial Tracking in SHGs:
                        How Swashakthi Makes a Difference
                      </h3>
                      <div className="meta">
                        <span>
                          Learn why real-time financial tracking is absolutely
                          crucial for SHGs and how Swashakthi’s advanced
                          analytics tools help NGOs stay on top of their
                          finances and make better decisions.
                        </span>
                      </div>
                      {/* Hidden content for "View More" */}
                      <div
                        className="extra-content"
                        style={{ display: "none" }}
                      >
                        <p>
                          Financial management is the backbone of any Self-Help
                          Group (SHG), yet many organizations face challenges in
                          tracking loans, repayments, savings, and overall
                          balances. Without real-time financial tracking,
                          organizations risk errors, delayed decisions, and
                          inefficiencies that can impact the entire SHG’s
                          success. Swashakthi SHG Portal addresses these
                          challenges head-on with its automated finance
                          management tools.
                          <br />
                          With features like Income &amp; Expense Reports, Loan
                          Status Reports, and Asset &amp; Liabilities Reports,
                          the platform offers a comprehensive view of each SHG’s
                          financial health. These tools not only track financial
                          transactions but also ensure transparency, making it
                          easier for NGOs to stay accountable to their members
                          and stakeholders.
                          <br />
                          The real-time nature of the system provides immediate
                          visibility into loan disbursals, repayments, interest
                          rates, and balances. This accessibility allows NGOs to
                          make timely, informed decisions, preventing any
                          financial mismanagement. The Swashakthi SHG Portal
                          simplifies finances for community growth.
                        </p>
                      </div>
                      <hr />
                      {/* Button toggles between "View More" and "View Less" */}
                      <a
                        href="#"
                        className="readmore stretched-link"
                        onClick={(event) => toggleContent(event)}
                      >
                        <span>View More</span>
                        <i className="bi bi-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                {/* End post item */}
                <div
                  className="col-xl-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <div
                    className="post-item position-relative h-100"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <div className="post-img position-relative overflow-hidden">
                      <img src={blog3} className="img-fluid" alt="blog" />
                      <span className="post-date">13th Jan'25</span>
                    </div>
                    <div className="post-content d-flex flex-column">
                      <h3 className="post-title">
                        How Swashakthi SHG Enhances Member Management for NGOs
                        and Rural Development Programs
                      </h3>
                      <div className="meta">
                        <span>
                          Swashakthi SHG offers advanced member management
                          tools, helping NGOs streamline member records, track
                          participation, and ensure better communication within
                          SHGs.
                        </span>
                      </div>
                      {/* Hidden content for "View More" */}
                      <div
                        className="extra-content"
                        style={{ display: "none" }}
                      >
                        <p>
                          Managing members is one of the most critical yet
                          challenging aspects of SHG operations. From personal
                          information to financial data and meeting attendance,
                          every detail needs to be tracked to ensure that each
                          member is fully supported. Swashakthi SHG Portal
                          provides a comprehensive solution for member
                          management, enabling NGOs to track member data with
                          ease.
                          <br />
                          The platform includes features for recording personal
                          details (e.g., Aadhar, Pan Card), financial
                          information (e.g., income, loans), and social data
                          (e.g., caste, religion). Additionally, Swashakthi
                          makes it easy to track meeting attendance and even
                          upload meeting screenshots for evidence. This ensures
                          that all members are engaged and that their
                          participation is recorded accurately.
                          <br />
                          The ability to generate Member-wise Reports allows
                          NGOs to monitor the involvement and financial
                          activities of each individual, ensuring that no one is
                          left behind. By simplifying these processes,
                          Swashakthi SHG boosts communication and coordination,
                          creating a more connected and efficient SHG
                          environment.
                        </p>
                      </div>
                      <hr />
                      {/* Button toggles between "View More" and "View Less" */}
                      <a
                        href="#"
                        className="readmore stretched-link"
                        onClick={(event) => toggleContent(event)}
                      >
                        <span>View More</span>
                        <i className="bi bi-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                {/* End post item */}
              </div>
            </div>
          </section>
          {/* /Recent Posts Section */}
        </main>
        <footer id="footer" className="LP-footer accent-background">
          <div className="container footer-top">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-12 footer-about">
                <a href="index.html" className="logo d-flex align-items-center">
                  <span className="sitename">Pinnacle Technologies</span>
                </a>
                <p>
                  We are a leading software development company offering
                  services in Software &amp; Website Development, Digital
                  Marketing, Web App Development, Code Mentoring, and UI/UX
                  Design across India.
                </p>
                <p>
                  Our mission is to exceed client expectations with end-to-end
                  solutions that boost business efficiency and productivity.
                  With a proven track record, we provide high-quality services
                  to help clients achieve their business goals and drive
                  success.
                </p>
                <div className="mt-3">
                  <a
                    href="https://www.pinnacletechnologies.in/"
                    target="_blank"
                    className="btn-get-started"
                  >
                    Visit Website
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a href="#" className="inactiveLink">
                      Software Development
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Website Development
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Digital Marketing
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Code Mentoring
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      UI/UX Design
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 footer-links">
                <h4>Our Products</h4>
                <ul>
                  <li>
                    <a href="https://bookbetter.app/" target="_blank">
                      Book Better (Booking Application)
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://swaraj-lease-hub.s3-website.ap-south-1.amazonaws.com/"
                      target="_blank"
                    >
                      Swaraj (Lease Management System)
                    </a>
                  </li>
                  <li>
                    <a href="#">Swastik (Temple Management System)</a>
                  </li>
                  <li>
                    <a
                      href="http://swayamfinance.in.s3-website.ap-south-1.amazonaws.com/"
                      target="_blank"
                    >
                      Swayam (Loan Tracking Application)
                    </a>
                  </li>
                  <li>
                    <a href="#">Swajan (Directory Address Generator)</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>2nd floor, Kadiyali Complex, </p>
                <p>Kadiyali, Kunjibettu, </p>
                <p>Udupi 576102</p>
                <p className="mt-3">
                  <strong>Phone:</strong>{" "}
                  <span>+91 9916213542 / +91 9663514001</span>
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <span>sales@pinnacletechnologies.in</span>
                </p>
                <div className="social-links d-flex mt-4 ">
                  <a
                    href="https://www.youtube.com/channel/UC_LGu8JbSBOqtvLLS_i7KaQ"
                    target="_blank"
                  >
                    <i className="bi bi-youtube" />
                  </a>
                  <a
                    href="https://www.facebook.com/pinnacletechnologiesindia/"
                    target="_blank"
                  >
                    <i className="bi bi-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/pinnacletechnologiesindia/"
                    target="_blank"
                  >
                    <i className="bi bi-instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/pinnacle-technologies-india"
                    target="_blank"
                  >
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
                <div className="mt-4">
                  <p>
                    <a href="/privacy-policy">Privacy Policy </a> &nbsp;| &nbsp;
                    <a href="/terms-conditions" className="mt-3">
                      Terms &amp; conditions{" "}
                    </a>

                    {/* <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;| &nbsp;
                    <Link to="/terms-conditions" className="mt-3">Terms &amp; conditions</Link> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container copyright  mt-4 ">
            <div className="row">
              <div className="col-lg-6">
                <p>
                  Copyright © <span>{currentYear}</span> |{" "}
                  <span>All Rights Reserved</span>
                </p>
              </div>
              <div className="col-lg-6">
                <p className="policyLink">
                  Designed &amp; Developed by
                  <span>
                    <a
                      href="https://www.pinnacletechnologies.in/"
                      target="_blank"
                    >
                      {" "}
                      Pinnacle Technologies
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </footer>

     

        {isVisible && (
          <a
            href="#"
            onClick={scrollToTop}
            className="scroll-top d-flex align-items-center justify-content-center back-to-top active"
          >
            <i className="bi bi-arrow-up-short"></i>
          </a>
        )}
      </div>
    </>
  );
}
