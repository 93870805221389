import React, { useEffect, useState } from "react";
import Select from "react-select";
const ExpenseTab = ({
  allPermission,
  formData,
  setFormData,
  activeBank,
  membersMeetingData,
  NextBackBtn,
  totalBankAndCashAmt,
  isThisFirstMeeting,
  bankAmount,
  prevBankBalance,
  setCurrentTotalBalance
}) => {


const calculateTotalIncome = (data) => {
  // Destructuring required fields from the data object
  const {
    batchSavingAmt,
    batchSubAmt,
    batchOtherContribution,
    batchMembershipFees,
    batchLoanPaid, 
    sdUnionToSHG,
    batchInterestPaid,
    batchOtherIncome,
    seedMoney,
    sdSHGToUnion,
    deactiveMemberSaving,
    batchSecurityDepositMemToShg
  } = data;

 
  const totalIncome = (
   Number(batchSavingAmt ? batchSavingAmt.cash : 0) +
 
   Number(batchSubAmt ? batchSubAmt.cash : 0) +

   Number((batchOtherContribution && batchOtherContribution.cash !== undefined) ? batchOtherContribution.cash : 0) +
  
   Number(batchMembershipFees ? batchMembershipFees.cash : 0) +
 
   Number(batchLoanPaid ? batchLoanPaid.cash : 0) + 
  
   Number(sdUnionToSHG ? sdUnionToSHG.cash : 0) +
  
   Number(batchInterestPaid ? batchInterestPaid.cash : 0) +
  
   Number((batchOtherIncome && batchOtherIncome.cash !== undefined) ? batchOtherIncome.cash : 0) +
  
   Number((seedMoney && seedMoney.cash !== undefined) ? seedMoney.cash : 0) +
  
   Number(sdSHGToUnion ? sdSHGToUnion.cash : 0) +
 
   Number(deactiveMemberSaving ? deactiveMemberSaving.cash : 0) +
   Number(batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.cash : 0)
  
  );

  setCurrentTotalBalance(totalIncome)
  return totalIncome;
};

useEffect(()=>{
  calculateTotalIncome(formData);
},[formData])


  let dataOfExpenseTab = {
    batchSavingWithdrawalsCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.savingWithdrawals.cash)),
      0
    ),
    batchSavingWithdrawalsBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.savingWithdrawals.bankAmt)),
      0
    ),

    batchSecurityDepositCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.securityDeposit.cash)),
      0
    ),

    batchSecurityDepositBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.securityDeposit.bankAmt)),
      0
    ),

    batchDividendDistributedCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.dividendDistributed.cash)),
      0
    ),
    batchDividendDistributedBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.dividendDistributed.bankAmt)),
      0
    ),
  };

  const [expenseFormData, setExpenseFormData] = useState({
    travelExpensesBankName:
      formData.travellingExpenses && formData.travellingExpenses.bankName,
    travelExpensesBank:
      formData.travellingExpenses && formData.travellingExpenses.bankAmt,
    travelExpensesCash:
      formData.travellingExpenses && formData.travellingExpenses.cash,

    StationaryBankName:
      formData.stationaryExpenses && formData.stationaryExpenses.bankName,
    StationaryBank:
      formData.stationaryExpenses && formData.stationaryExpenses.bankAmt,
    StationaryCash:
      formData.stationaryExpenses && formData.stationaryExpenses.cash,

    paidToMahasanghaBankName:
      formData.paidToMahasangha && formData.paidToMahasangha.bankName,
    paidToMahasanghaBank:
      formData.paidToMahasangha && formData.paidToMahasangha.bankAmt,
    paidToMahasanghaCash:
      formData.paidToMahasangha && formData.paidToMahasangha.cash,

    serviceChargesBankName:
      formData.serviceCharges && formData.serviceCharges.bankName,
    serviceChargesBank:
      formData.serviceCharges && formData.serviceCharges.bankAmt,
    serviceChargesCash: formData.serviceCharges && formData.serviceCharges.cash,

    otherExpensesBankName:
      formData.otherExpenses && formData.otherExpenses.bankName,
    otherExpensesBank: formData.otherExpenses && formData.otherExpenses.bankAmt,
    otherExpensesCash: formData.otherExpenses && formData.otherExpenses.cash,

    donationToOutsiderBankName:
      formData.donationToOutsider && formData.donationToOutsider.bankName,
    donationToOutsiderBank:
      formData.donationToOutsider && formData.donationToOutsider.bankAmt,
    donationToOutsiderCash:
      formData.donationToOutsider && formData.donationToOutsider.cash,

    sdSHGToUnionBankName:
      formData.sdSHGToUnion && formData.sdSHGToUnion.bankName,
    sdSHGToUnionBank: formData.sdSHGToUnion && formData.sdSHGToUnion.bankAmt,
    sdSHGToUnionCash: formData.sdSHGToUnion && formData.sdSHGToUnion.cash,

    loanGivenToOtherSHGBankName:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankName,
    loanGivenToOtherSHGBank:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankAmt,
    loanGivenToOtherSHGCash:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.cash,
  });

  // membersMeetingData

  const {
    travelExpensesBankName,
    travelExpensesBank,
    travelExpensesCash,

    StationaryBankName,
    StationaryBank,
    StationaryCash,

    paidToMahasanghaBankName,
    paidToMahasanghaBank,
    paidToMahasanghaCash,

    serviceChargesBankName,
    serviceChargesBank,
    serviceChargesCash,

    otherExpensesBankName,
    otherExpensesBank,
    otherExpensesCash,

    donationToOutsiderBankName,
    donationToOutsiderBank,
    donationToOutsiderCash,

    sdSHGToUnionBankName,
    sdSHGToUnionBank,
    sdSHGToUnionCash,

    loanGivenToOtherSHGBankName,
    loanGivenToOtherSHGBank,
    loanGivenToOtherSHGCash,
  } = expenseFormData;
  const[lowBalanceError,setLowBalanceError] = useState("")
  const [respectiveBankBalance,setRespectiveBankBalance] = useState(0);



  const onInputChange = (e, labelName = "") => {
    let totalAmt  = 0;
    bankAmount && bankAmount.map((ele)=>{
     return(
     
       totalAmt +=ele.totalBalance
     
     )
   })
    if (labelName == "") {
      let label = e.target.name;
      let count = 0;
      let enteredAmount = e.target.value;
      if (isThisFirstMeeting) {
        setExpenseFormData({
          ...expenseFormData,
          [e.target.name]: e.target.value,
        });
      } else {
        switch (label) {
          case "travelExpensesBank":
            const travelExpensesBank = bankAmount.find(
              (bank) => bank.bankId === travelExpensesBankName.bankId
            );
            if (travelExpensesBank) {
              if (travelExpensesBank.totalBalance < enteredAmount) {
                count++;
                setLowBalanceError(
                  `Insufficient balance in ${travelExpensesBank.bankName}. Available balance: ${travelExpensesBank.totalBalance}`
                );
                document.getElementById("bankNames").style.color ="red";
                document.getElementById("CashInHand").style.color ="red";
              } else {
                document.getElementById("CashInHand").style.color ="";
                document.getElementById("bankNames").style.color ="";
                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            }
            break;
          case "StationaryBank":
            const StationaryBank = bankAmount.find(
              (bank) => bank.bankId === StationaryBankName.bankId
            );
            if (StationaryBank) {
              if (StationaryBank.totalBalance < enteredAmount) {
                count++;
                setLowBalanceError(
                  `Insufficient balance in ${StationaryBank.bankName}. Available balance: ${StationaryBank.totalBalance}`
                );
                document.getElementById("bankNames").style.color ="red";
                document.getElementById("CashInHand").style.color ="red";
              } else {
                document.getElementById("CashInHand").style.color ="";
                document.getElementById("bankNames").style.color ="";

                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            }
            break;
          case "paidToMahasanghaBank":
            case "paidToMahasanghaCash":
            

            const paidToMahasanghaBank = bankAmount.find(
              (bank) => bank.bankId === paidToMahasanghaBankName.bankId
            );
            // if (paidToMahasanghaBank) {
              if (
                // paidToMahasanghaBank.totalBalance
                (totalAmt + totalBankAndCashAmt + calculateTotalIncome(formData)) < enteredAmount) {
                count++;
                // setLowBalanceError(
                //   `Insufficient balance in ${paidToMahasanghaBank.bankName}. Available balance: ${paidToMahasanghaBank.totalBalance}`
                // );
                document.getElementById("bankNames").style.color ="red";
                document.getElementById("CashInHand").style.color ="red";
              } else {
                document.getElementById("bankNames").style.color ="";
                document.getElementById("CashInHand").style.color ="";
                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            // }
            break;
          case "serviceChargesBank":
            const serviceChargesBank = bankAmount.find(
              (bank) => bank.bankId === serviceChargesBankName.bankId
            );
            if (serviceChargesBank) {
              if (serviceChargesBank.totalBalance < enteredAmount) {
                count++;
                setLowBalanceError(
                  `Insufficient balance in ${serviceChargesBank.bankName}. Available balance: ${serviceChargesBank.totalBalance}`
                );
                document.getElementById("bankNames").style.color ="red";
                document.getElementById("CashInHand").style.color ="red";
              } else {
                document.getElementById("CashInHand").style.color ="";
                document.getElementById("bankNames").style.color ="";
                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            }
            break;
          case "otherExpensesBank":
            const otherExpensesBank = bankAmount.find(
              (bank) => bank.bankId === otherExpensesBankName.bankId
            );
            if (otherExpensesBank) {
              if (otherExpensesBank.totalBalance < enteredAmount) {
                count++;
                setLowBalanceError(
                  `Insufficient balance in ${otherExpensesBank.bankName}. Available balance: ${otherExpensesBank.totalBalance}`
                );
                document.getElementById("bankNames").style.color ="red";
                document.getElementById("CashInHand").style.color ="red";
              } else {
                document.getElementById("CashInHand").style.color ="";
                document.getElementById("bankNames").style.color ="";
                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            }
            break;
          case "donationToOutsiderBank":
            const donationToOutsiderBank = bankAmount.find(
              (bank) => bank.bankId === donationToOutsiderBankName.bankId
            );
            if (donationToOutsiderBank) {
              if (donationToOutsiderBank.totalBalance < enteredAmount) {
                count++;
                setLowBalanceError(
                  `Insufficient balance in ${donationToOutsiderBank.bankName}. Available balance: ${donationToOutsiderBank.totalBalance}`
                );
                document.getElementById("CashInHand").style.color ="red";
                document.getElementById("bankNames").style.color ="red";
              } else {
                document.getElementById("CashInHand").style.color ="";
                document.getElementById("bankNames").style.color ="";
                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            }
            break;

          case "loanGivenToOtherSHGBank":
            const loanGivenToOtherSHGBank = bankAmount.find(
              (bank) => bank.bankId === loanGivenToOtherSHGBankName.bankId
            );
            if (loanGivenToOtherSHGBank) {
              if (loanGivenToOtherSHGBank.totalBalance < enteredAmount) {
                count++;
                setLowBalanceError(
                  `Insufficient balance in ${loanGivenToOtherSHGBank.bankName}. Available balance: ${loanGivenToOtherSHGBank.totalBalance}`
                );
                document.getElementById("CashInHand").style.color ="red";
                document.getElementById("bankNames").style.color ="red";
              } else {
                document.getElementById("CashInHand").style.color ="";
                document.getElementById("bankNames").style.color ="";
                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            }
            break;
          case "sdSHGToUnionBank":
            case "sdSHGToUnionCash":
              
            // const sdSHGToUnionBank = bankAmount.find(
            //   (bank) => bank.bankId === sdSHGToUnionBankName.bankId
            // );
            // if (sdSHGToUnionBank) {
              if ((totalAmt + totalBankAndCashAmt + calculateTotalIncome(formData)) < enteredAmount) {
                count++;
                // setLowBalanceError(
                //   `Insufficient balance in ${sdSHGToUnionBank.bankName}. Available balance: ${sdSHGToUnionBank.totalBalance}`
                // );
                document.getElementById("CashInHand").style.color ="red";
                document.getElementById("bankNames").style.color ="red";
              } else {
                document.getElementById("CashInHand").style.color ="";
                document.getElementById("bankNames").style.color ="";
                setLowBalanceError("");
                setExpenseFormData({
                  ...expenseFormData,
                  [e.target.name]: e.target.value,
                });
              }
            // }
            break;

          case "travelExpensesCash":
          case "StationaryCash":
          // case "paidToMahasanghaCash":
          case "serviceChargesCash":
          case "otherExpensesCash":
          case "donationToOutsiderCash":
          // case "sdSHGToUnionCash":
            case "loanGivenToOtherSHGCash":
            if (enteredAmount > totalBankAndCashAmt + calculateTotalIncome(formData)) {
              count++;
              setLowBalanceError("Cash In Hand is Less");
              document.getElementById("CashInHand").style.color ="red";
            } else {
              document.getElementById("bankNames").style.color ="";
              setLowBalanceError("");
              setExpenseFormData({
                ...expenseFormData,
                [e.target.name]: e.target.value,
              });
            }

            break;

          default:
            break;
        }
      }
    } else {
      setExpenseFormData({ ...expenseFormData, [labelName]: e });
    }
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-","."].includes(e.key) && e.preventDefault();
  };

  const[expenseTabErrorMessage,setExpenseTabErrorMessage] = useState("")
  const expenseNextTab = () => {
    let count = 0;

    const finalExpenseData = {
      travellingExpenses: {
        cash: Number(travelExpensesCash),
        bankAmt: Number(travelExpensesBank),
        bankName: travelExpensesBankName,
      },
      stationaryExpenses: {
        cash: Number(StationaryCash),
        bankAmt: Number(StationaryBank),
        bankName: StationaryBankName,
      },
      paidToMahasangha: {
        cash: Number(paidToMahasanghaCash),
        bankAmt: Number(paidToMahasanghaBank),
        bankName: paidToMahasanghaBankName,
      },
      serviceCharges: {
        cash: Number(serviceChargesCash),
        bankAmt: Number(serviceChargesBank),
        bankName: serviceChargesBankName,
      },
      batchDividendDistributed: {
        cash: dataOfExpenseTab.batchDividendDistributedCash,
        bankAmt: dataOfExpenseTab.batchDividendDistributedBank,
        bankName: "",
      },
      otherExpenses: {
        cash: Number(otherExpensesCash),
        bankAmt: Number(otherExpensesBank),
        bankName: otherExpensesBankName,
      },
      batchSavingWithdrawals: {
        cash: dataOfExpenseTab.batchSavingWithdrawalsCash,
        bankAmt: dataOfExpenseTab.batchSavingWithdrawalsBank,
        bankName: "",
      },
      batchSecurityDeposit: {
        cash: dataOfExpenseTab.batchSecurityDepositCash,
        bankAmt: dataOfExpenseTab.batchSecurityDepositBank,
        bankName: "",
      },

      // req flag
      donationToOutsider: {
        cash: Number(donationToOutsiderCash),
        bankAmt: Number(donationToOutsiderBank),
        bankName: donationToOutsiderBankName,
      },
      sdSHGToUnion: {
        cash: Number(sdSHGToUnionCash),
        bankAmt: Number(sdSHGToUnionBank),
        bankName: sdSHGToUnionBankName,
      },
      loanGivenToOtherSHG: {
        bankName: loanGivenToOtherSHGBankName,
        bankAmt: Number(loanGivenToOtherSHGBank),
        cash: Number(loanGivenToOtherSHGCash),
      },
    };

    ///////////////////////////////////calculating the total expense start//////////////////////////////////////
    // Use formData to get bank details
    const bankDataArray = formData.bankDetails;
    const bankExpenses = {};
    const expenseFields = [
      "travellingExpenses",
      "stationaryExpenses",
      "paidToMahasangha",
      "serviceCharges",
      "otherExpenses",
      "donationToOutsider",
      "sdSHGToUnion",
      "loanGivenToOtherSHG",
    ];

    // Loop through the expense fields and construct the expense object
    expenseFields.forEach((field) => {
      const data = finalExpenseData[field];
      const bankId = data.bankName ? data.bankName.bankId : data.bankId;
      const bankName = data.bankName ? data.bankName.label : "";

      if (!bankId || bankId === "") {
        return;
      }
      const bank = bankDataArray.find((b) => b.bankId === bankId);

      if (bank) {
        if (!bankExpenses[bankId]) {
          bankExpenses[bankId] = {
            bankId: bank.bankId,
            batchBankNameId: bank.bankId,
            batchBankName: bankName || bank.bankName,
            indBankInterest: 0,
            indBankCommission: 0,
            indBatchBankDeposit: 0,
            indBatchBankWithdrawals: 0,
            transactionType: "B (I/E)",
            otherLoanTypeId: "",
          };
        }

        // Combine totals for withdrawals
        bankExpenses[bankId].indBatchBankWithdrawals +=
          Number(data.bankAmt) || 0;
      } else {
        if (!bankExpenses[bankId]) {
          bankExpenses[bankId] = {
            bankId: bankId,
            batchBankNameId: bankId,
            batchBankName: bankName || "Unknown",
            indBankInterest: 0,
            indBankCommission: 0,
            indBatchBankDeposit: 0,
            indBatchBankWithdrawals: 0,
            transactionType: "B (I/E)",
            otherLoanTypeId: "",
          };
        }
        // Combine totals for withdrawals even if no matching bank is found
        bankExpenses[bankId].indBatchBankWithdrawals +=
          Number(data.bankAmt) || 0;
      }
    });

    // Convert the totals object into an array and filter out records where all values are 0
    const batchExpenseArray = Object.values(bankExpenses).filter((expense) => {
      return (
        expense.indBankInterest !== 0 ||
        expense.indBankCommission !== 0 ||
        expense.indBatchBankDeposit !== 0 ||
        expense.indBatchBankWithdrawals !== 0
      );
    });
    ///////////////////////////////////calculating the total expesne end//////////////////////////////////////

    //////////////////////////////////////////////////////merging the income and expense start//////////////////////////////////////
    const mergedData = {};

    formData.batchIncomeArray.forEach((income) => {
      const { bankId, indBatchBankDeposit, indBatchBankWithdrawals } = income;

      if (!mergedData[bankId]) {
        mergedData[bankId] = { ...income, indBatchBankWithdrawals: 0 }; // Set withdrawals to 0 if no expenses
      } else {
        mergedData[bankId].indBatchBankDeposit += Number(indBatchBankDeposit);
      }
    });

    batchExpenseArray.forEach((expense) => {
      const { bankId, indBatchBankWithdrawals } = expense;

      if (!mergedData[bankId]) {
        mergedData[bankId] = { ...expense, indBatchBankDeposit: 0 }; // Set deposits to 0 if no income
      } else {
        mergedData[bankId].indBatchBankWithdrawals += Number(
          indBatchBankWithdrawals
        );
      }
    });
    // Convert the merged object back into an array
    const finalMergedArray = Object.values(mergedData);

    ////////////////////////////////////////////////////merging the income and expense end/////////////////////////////////////
    let totalExpense = 0;
    for (const label in finalExpenseData) {
      if (finalExpenseData[label]) {
        totalExpense +=
          (finalExpenseData[label].cash || 0) +
          (finalExpenseData[label].bankAmt || 0);
      }
    }

    const totalCash = Object.values(finalExpenseData).reduce(
      (total, expense) => {
        return total + expense.cash;
      },
      0
    );

    const totalBank = Object.values(finalExpenseData).reduce(
      (total, expense) => {
        return total + expense.bankAmt;
      },
      0
    );

    let cashAndBankTotal = Number(totalCash || 0) + Number(totalBank || 0);

    if (!isThisFirstMeeting) {
      if (
        Number(cashAndBankTotal) >
        totalBankAndCashAmt + calculateTotalIncome(formData) + prevBankBalance
      ) {
        count++;
        setExpenseTabErrorMessage("Total expense is exceding your overall avaiable balance")
                document.getElementById("CashInHand").style.color = "red";
      } else {
        document.getElementById("CashInHand").style.color = "";
      }

      // Function to compare
      batchExpenseArray.forEach((batchExpense) => {
        // Find the bank entry matching the bankId
        const matchingBank = bankAmount.find(
          (bank) => bank.bankId === batchExpense.bankId
        );

        // If a matching bank is found, compare the totalBalance with indBatchBankWithdrawals
        if (matchingBank) {
          const { totalBalance } = matchingBank;
          const { indBatchBankWithdrawals } = batchExpense;

          if (
            indBatchBankWithdrawals !== 0 &&
            indBatchBankWithdrawals > totalBalance
          ) {
            count++;
            setLowBalanceError(
              `Warning: Withdrawal amount (${indBatchBankWithdrawals}) exceeds the bank balance (${totalBalance}) for ${matchingBank.bankName}`
            );
          } else {
            setLowBalanceError("");
          }
        }
      });
    }

    if (count == 0) {
      setFormData({
        ...formData,
        ...finalExpenseData,
        cashAndBankTotal: cashAndBankTotal,
        batchIncomeAndExpenseArray: finalMergedArray,
      });
      NextBackBtn(6);
      calculateTotalIncome(formData);
    }
  };

  return (
    <div className="row card-new py-3 col-12">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <h5>Expense</h5>
        <div id="bankNames"></div>
        {bankAmount && bankAmount.map((ele)=>{
                    return(
                      <div id="bankNames">
                      <span><b>Bank Name</b>&nbsp;:&nbsp;</span><label>{(ele.bankName).slice(0,10)}</label>&nbsp;
                      <span><b>Bank Amount</b>&nbsp;:&nbsp;</span><label>{Number(ele.totalBalance || 0).toFixed(2)}</label>
                      <br/>
                      </div>
                    )
                  })}
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">Travelling Expense&nbsp;:&nbsp;</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="travelExpensesBankName"
              options={activeBank}
              isSearchable={true}
              value={travelExpensesBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "travelExpensesBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount
            <input
              type="number"
              name="travelExpensesBank"
              value={travelExpensesBank}
              disabled={travelExpensesBankName ? false : true}
              className="form-control"
              onChange={(e) => onInputChange(e)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="travelExpensesCash"
              value={travelExpensesCash}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">Stationary Expense&nbsp;:&nbsp;</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="StationaryBankName"
              options={activeBank}
              isSearchable={true}
              value={StationaryBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "StationaryBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>{" "}
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="StationaryBank"
              value={StationaryBank}
              disabled={StationaryBankName ? false : true}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="StationaryCash"
              value={StationaryCash}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          Paid to Union (Mahasangha)&nbsp;
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Amount Paid /Given to the Union/Mahasanga/Federation/General HUB"
          />&nbsp;:
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="paidToMahasanghaBankName"
              options={activeBank}
              isSearchable={true}
              value={paidToMahasanghaBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "paidToMahasanghaBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="paidToMahasanghaBank"
              value={paidToMahasanghaBank}
              disabled={paidToMahasanghaBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="paidToMahasanghaCash"
              value={paidToMahasanghaCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">Service Charge&nbsp;:&nbsp;</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="serviceChargesBankName"
              options={activeBank}
              isSearchable={true}
              value={serviceChargesBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "serviceChargesBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="serviceChargesBank"
              value={serviceChargesBank}
              disabled={serviceChargesBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="serviceChargesCash"
              value={serviceChargesCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          Dividend Distributed{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Profit Distribution to Members"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchDividendDistributedCash"
              value={Number(dataOfExpenseTab.batchDividendDistributedCash)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
          <div className="col-lg-6">
            Bank
            <input
              type="number"
              name="batchDividendDistributedBank"
              value={Number(dataOfExpenseTab.batchDividendDistributedBank)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">Other Expense :</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="otherExpensesBankName"
              options={activeBank}
              isSearchable={true}
              value={otherExpensesBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "otherExpensesBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount
            <input
              type="number"
              name="otherExpensesBank"
              value={otherExpensesBank}
              disabled={otherExpensesBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="otherExpensesCash"
              value={otherExpensesCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <label className="label-control">
          Savings Withdrawal{" "}
          <img
            className="img_icon_size log"
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Withdrawal of Saving Amount"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSavingWithdrawalsCash"
              value={Number(dataOfExpenseTab.batchSavingWithdrawalsCash)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchSavingWithdrawalsBank"
              value={Number(dataOfExpenseTab.batchSavingWithdrawalsBank)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          SD SHG To Member{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Security Deposit Amount Received from SHG to Members"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSecurityDepositCash"
              value={Number(dataOfExpenseTab.batchSecurityDepositCash)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchSecurityDepositBank"
              value={Number(dataOfExpenseTab.batchSecurityDepositBank)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          Donation To Outsider{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Donation/Contribution to the Outside from the SHG"
          />
          &nbsp;:&nbsp;
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="donationToOutsiderBankName"
              options={activeBank}
              isSearchable={true}
              value={donationToOutsiderBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "donationToOutsiderBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="donationToOutsiderBank"
              value={donationToOutsiderBank}
              disabled={donationToOutsiderBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="donationToOutsiderCash"
              value={donationToOutsiderCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>

        <label className="label-control">
          SD SHG To Union{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Security Deposit Amount from SHG to Union"
          />
          &nbsp;:&nbsp;
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="sdSHGToUnionBankName"
              options={activeBank}
              isSearchable={true}
              value={sdSHGToUnionBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "sdSHGToUnionBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>{" "}
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="sdSHGToUnionBank"
              value={sdSHGToUnionBank}
              disabled={sdSHGToUnionBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="sdSHGToUnionCash"
              onChange={(e) => onInputChange(e)}
              value={sdSHGToUnionCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              // onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>

        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <>
            <label className="label-control">Loan Given To Other SHG :</label>
            <div className="row col-12">
              <div className="col-lg-6">
                Bank List
                <Select
                  name="loanGivenToOtherSHGBankName"
                  options={activeBank}
                  isSearchable={true}
                  value={loanGivenToOtherSHGBankName}
                  placeholder="Select Bank"
                  onChange={(e) => onInputChange(e,"loanGivenToOtherSHGBankName")}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6">
                Bank Amount{" "}
                <input
                  type="number"
                  name="loanGivenToOtherSHGBank"
                  value={loanGivenToOtherSHGBank}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                Cash
                <input
                  type="number"
                  name="loanGivenToOtherSHGCash"
                  value={loanGivenToOtherSHGCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <label id="CashInHand" className="text-right col-12">
        {lowBalanceError ? lowBalanceError : expenseTabErrorMessage}</label>

        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => expenseNextTab()}
          // onClick={() => NextBackBtn(5)}
        >
          Next
        </button>
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(4)}
        >
          Previous
        </button>
      </div>
    </div>
  );
};

export default ExpenseTab;
