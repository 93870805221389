import React from "react";
import { useRef } from "react";
import { useEffect, useState, useLayoutEffect } from "react";
import logo from "../landing/assets/Images/Swashakthi-SHG-Logo.webp";
import "../landing/assets/vendor/bootstrap/css/bootstrap.min.css";
import "../landing/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import AOS from "aos";
import "aos/dist/aos.css";

import "../landing/assets/css/main.css";
import { Link } from "react-router-dom";

export default function LandingPage() {
  const [currentYear, setCurrentYear] = useState("");
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year.toString());
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease",
      once: false,
    });
  }, []);

  useLayoutEffect(() => {
    const initializeAOS = () => {
      AOS.init({ duration: 1000 });
    };

    window.addEventListener("load", initializeAOS);

    return () => {
      window.removeEventListener("load", initializeAOS);
    };
  }, []);

  // back to top icon
  const [isVisible, setIsVisible] = useState(false);

  // Toggle visibility of the back-to-top button
  const toggleScrollTop = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page
  const scrollToTop = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", toggleScrollTop);
    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener("scroll", toggleScrollTop);
    };
  }, []);

  // // active tabs

  const navRef = useRef(null);
  useEffect(() => {
    const handleTabClick = (event) => {
      const links = navRef.current.querySelectorAll(".navmenu a");
      links.forEach((link) => link.classList.remove("active")); // Remove active from all links
      event.target.classList.add("active"); // Add active to the clicked link
    };
    const links = navRef.current.querySelectorAll(".navmenu a");
    links.forEach((link) => link.addEventListener("click", handleTabClick));
    // Cleanup event listeners on component unmount
    return () => {
      links.forEach((link) =>
        link.removeEventListener("click", handleTabClick)
      );
    };
  }, []);

  useEffect(() => {
    /**
     * Mobile nav toggle
     */
    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    function mobileNavToogle() {
      document.querySelector(".LP").classList.toggle("mobile-nav-active");
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    }
    mobileNavToggleBtn.addEventListener("click", mobileNavToogle);

    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });
  }, []);

  return (
    <>
      <div className="LP">
        <header
          id="header"
          className="header d-flex align-items-center sticky-top"
        >
          <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center">
            
              <img
                src={logo}
                alt="logo"
                className="img-fluid logoImg"
                height={80}
                width={90}
              />
            </a>
            <nav id="navmenu" className="navmenu" ref={navRef}>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/">About</a>
                </li>
                <li>
                  <a href="/">Features</a>
                </li>
                <li>
                  <a href="/">Benefits</a>
                </li>
                <li>
                  <a href="/">Pricing</a>
                </li>
                <li>
                  <a href="/">Case Studies</a>
                </li>
                <li>
                  <a href="/">Contact</a>
                </li>
                <li>
                  <a href="/Login" className="btn-get-started">
                    Login
                  </a>
                </li>
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list" />
            </nav>
          </div>
        </header>
        <main className="main">
          {/* About Section */}
          <section id="about" className="about section light-background">
            <div className="container">
            
                <div
                  className="col-lg-12 content"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Privacy Policy</h3>
                  <p className=" secondaryText-color fontText-small mt-3">
                    This Privacy Policy describes our policies and procedures
                    regarding the collection, use and disclosure of your
                    information when you respond to our advertisements and
                    informs you of your privacy rights and obligations. How does
                    the law protect you? We use your personal information to
                    communicate and support you and to provide and improve the
                    Services. By accessing and utilizing the Service, you
                    consent to the gathering and application of information as
                    outlined in this Privacy Policy.
                  </p>

                  {/* explaination  */}
                  <div className="mt-5">
                    <p className="PP-TC-heading text-center ">
                    EXPLANATION AND DEFINITION
                    </p>
                    <p className="textBold text-left">Explanation</p>
                 


                    <p className="secondaryText-color fontText-small">
                  Words bearing the first letter carry the meaning defined
                  according to the following conditions. The following terms
                  have the same meaning whether used as words or in the
                  plural.(“We” or “us”) refers to swashakthishg.com.
                </p>
                <p className="secondaryText-color fontText-small">
                  Device is any device, such as a computer, mobile phone or
                  digital tablet, that can access the Service.
                </p>

                <p className="secondaryText-color fontText-small">
                  <strong className="textBold">Personal information </strong>
                  refers to data pertaining to a person who is either identified
                  or can be identified. Concerning third-party companies or
                  individuals engaged by the Company to facilitate the Service,
                  deliver the Services on the Company&apos;s behalf, perform
                  related tasks, or assist the Company in assessing the use of
                  the Service.
                </p>
                <p className="secondaryText-color fontText-small">
                  Data collection may occur through use of the Services or
                  through the Service itself (for example, the time a page was
                  accessed). A company or other legal entity that serves as a
                  service provider (if applicable).
                </p>
                  </div>

                  {/* COLLECTION AND USE OF YOUR PERSONAL INFORMATION */}
                  <div className="mt-5">
                  <p className="PP-TC-heading text-center">
                  COLLECTION AND USE OF YOUR PERSONAL INFORMATION{" "}
                </p>
                <p className="textBold text-left">
                  Types of Information Collected
                </p>
                <p className="textBold text-left">Personal Information</p>
                <p className="secondaryText-color fontText-small">
                  While utilizing our Services, we may request that you provide
                  personal information that can be used to contact or identify
                  you. Personal identifiable information may encompass, but is
                  not restricted to:
                </p>
                <ol type="a" style={{ marginLeft: "20px" }} >
                    <li>
                   
                      First name and last name
                     
                    </li>
                    <li>
                    
                      Email address
                    
                    </li>
                    <li>
                    
                      Phone number
                     
                    </li>
                    <li>
                     
                      Geographical information such as your address, city, or
                      country
                      
                    </li>
                    <li>
                     
                      Other information such as personal preferences, requests
                      or comments
                    
                    </li>
                  </ol>

                  <p className="textBold text-left mt-3">
                  Use of your personal information
                </p>
                <p className="mb-4 secondaryText-color fontText-small">
                  The Company will use personal information for the following
                  purposes:
                  <br />
                  <br />
                  Use of our services to provide and manage our services,
                  including monitoring our services. The personal information
                  you provide allows you to access different features of the
                  Service available to you as a registered user. Other
                  contractual products, goods or services.
                  <br />
                  <br />
                  Delivery of updates or information regarding the Service
                  Agreement, including security updates (where necessary or
                  appropriate for use). Other products, services and events are
                  similar to events unless you choose not to receive this
                  information. Consider or undertake any merger, distribution,
                  restructuring, reorganization, separation or other sale or
                  transfer of any or all of our assets, whether on a regular
                  basis or as part of a business, transaction or similar process
                  for which we maintain personal information about users.
                  <br />
                  <br />
                  All of our services are based on assets. To enhance our
                  offerings, products, marketing efforts, and your overall.
                  Information to track, analyse and communicate with you about
                  the use of our services. When negotiating to transfer all or
                  part of our business to another company.
                  <br />
                  <br />
                  This Privacy Policy. Partners include our parent company and
                  other organizations, joint ventures or other companies that we
                  control or are controlled by. to provide you with certain
                  products, services or support. and can be distributed to the
                  public outside. Your personal data will be retained for the
                  duration required to fulfil the purposes outlined in this
                  privacy policy.
                  <br />
                  <br />
                  We will retain and use your personal information as necessary
                  to comply with our legal obligations (for example, if we are
                  required to retain your information to comply with the law),
                  address disputes, and uphold our legal agreements and
                  policies.
                  <br />
                  <br />
                  The company will also retain information for internal audit
                  purposes. The study is based on. This means that this
                  information may be transferred to and stored on computers
                  located outside of your state, province, country or other
                  federal jurisdiction to which data protection laws apply; the
                  data may not comply with the data protection laws in your
                  different jurisdiction. and your personal information will not
                  be transferred to any organization or country unless there are
                  adequate controls in place including the security of your
                  information and other personal information.
                  <br />
                  <br />
                  In certain circumstances, the Company may be required to
                  disclose your Personal Information if necessary to comply with
                  applicable law or to respond to a valid governmental request
                  (such as a court or government agency). Your good faith
                  disclosure of Personal Information is necessary for the
                  following purposes:
                  <br />
                  <br />
                  <ol type="a" style={{ marginLeft: "20px" }}>
                    <li> To comply with the law</li>
                    <li>
                      {" "}
                      To safeguard and protect the rights or property of the
                      Company
                    </li>
                    <li>
                      {" "}
                      To prevent or detect errors that may be related to the
                      Service
                    </li>
                    <li>
                      {" "}
                      To ensure the personal safety of Service users or the
                      general public
                    </li>
                  </ol>
                </p>

                <p className="textBold text-left">
                  Security of your personal information
                </p>

                <p className="mb-4 secondaryText-color fontText-small">
                  We prioritize the security of your personal information;
                  however, please be aware that. While we endeavour to employ
                  commercially acceptable means to safeguard.
                </p>


                  </div>

                  {/* LINKS TO OTHER WEBSITES */}
                  <div className="mt-5">
                  <p className="PP-TC-heading text-center ">LINKS TO OTHER WEBSITES</p>
                    <p className="secondaryText-color fontText-small">
                  Our Services may contain links to other websites that are not
                  operated by us. If you click on a third-party link, you will
                  be directed to the third party&apos;s website. It is advisable
                  to review the privacy policy of each website.
                   </p>
                  </div>


                  {/* CHANGES TO THIS PRIVACY POLICY */}
                  <div className="mt-5">
                  <p className="PP-TC-heading text-center">
                  CHANGES TO THIS PRIVACY POLICY
                </p>
                <p className=" secondaryText-color fontText-small">
                  From time to time, we may revise our Privacy Policy. We will
                  inform you of any changes by updating the Privacy Policy on
                  this page.Changes to this Privacy Policy take effect
                  immediately upon.
                  <br />
                  <br />
                  This Privacy Policy was last updated on June 2024.
                </p>
                  </div>
                
                 
                </div>
             
            </div>
          </section>
          {/* /About Section */}
        </main>
        <footer id="footer" className="LP-footer accent-background">
          <div className="container footer-top">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-12 footer-about">
                <a href="index.html" className="logo d-flex align-items-center">
                  <span className="sitename">Pinnacle Technologies</span>
                </a>
                <p>
                  We are a leading software development company offering
                  services in Software &amp; Website Development, Digital
                  Marketing, Web App Development, Code Mentoring, and UI/UX
                  Design across India.
                </p>
                <p>
                  Our mission is to exceed client expectations with end-to-end
                  solutions that boost business efficiency and productivity.
                  With a proven track record, we provide high-quality services
                  to help clients achieve their business goals and drive
                  success.
                </p>
                <div className="mt-3">
                  <a
                    href="https://www.pinnacletechnologies.in/"
                    target="_blank"
                    className="btn-get-started"
                  >
                    Visit Website
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a href="#" className="inactiveLink">
                      Software Development
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Website Development
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Digital Marketing
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      Code Mentoring
                    </a>
                  </li>
                  <li>
                    <a href="#" className="inactiveLink">
                      UI/UX Design
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 footer-links">
                <h4>Our Products</h4>
                <ul>
                  <li>
                    <a href="https://bookbetter.app/" target="_blank">
                      Book Better (Booking Application)
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://swaraj-lease-hub.s3-website.ap-south-1.amazonaws.com/"
                      target="_blank"
                    >
                      Swaraj (Lease Management System)
                    </a>
                  </li>
                  <li>
                    <a href="#">Swastik (Temple Management System)</a>
                  </li>
                  <li>
                    <a
                      href="http://swayamfinance.in.s3-website.ap-south-1.amazonaws.com/"
                      target="_blank"
                    >
                      Swayam (Loan Tracking Application)
                    </a>
                  </li>
                  <li>
                    <a href="#">Swajan (Directory Address Generator)</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>2nd floor, Kadiyali Complex, </p>
                <p>Kadiyali, Kunjibettu, </p>
                <p>Udupi 576102</p>
                <p className="mt-3">
                  <strong>Phone:</strong>{" "}
                  <span>+91 9916213542 / +91 9663514001</span>
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <span>sales@pinnacletechnologies.in</span>
                </p>
                <div className="social-links d-flex mt-4 ">
                  <a
                    href="https://www.youtube.com/channel/UC_LGu8JbSBOqtvLLS_i7KaQ"
                    target="_blank"
                  >
                    <i className="bi bi-youtube" />
                  </a>
                  <a
                    href="https://www.facebook.com/pinnacletechnologiesindia/"
                    target="_blank"
                  >
                    <i className="bi bi-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/pinnacletechnologiesindia/"
                    target="_blank"
                  >
                    <i className="bi bi-instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/pinnacle-technologies-india"
                    target="_blank"
                  >
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
                <div className="mt-4">
                  <p>
                    <a href="/privacy-policy">Privacy Policy </a> &nbsp;| &nbsp;
                    <a href="/terms-conditions" className="mt-3">
                      Terms &amp; conditions{" "}
                    </a>
                      {/* <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;| &nbsp;
                       <Link to="/terms-conditions" className="mt-3">Terms &amp; conditions</Link> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container copyright  mt-4 ">
            <div className="row">
              <div className="col-lg-6">
                <p>
                  Copyright © <span>{currentYear}</span> |{" "}
                  <span>All Rights Reserved</span>
                </p>
              </div>
              <div className="col-lg-6">
                <p className="policyLink">
                  Designed &amp; Developed by
                  <span>
                    <a
                      href="https://www.pinnacletechnologies.in/"
                      target="_blank"
                    >
                      {" "}
                      Pinnacle Technologies
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </footer>

        {isVisible && (
          <a
            href="#"
            onClick={scrollToTop}
            className="scroll-top d-flex align-items-center justify-content-center back-to-top active"
          >
            <i className="bi bi-arrow-up-short"></i>
          </a>
        )}
      </div>
    </>
  );
}
