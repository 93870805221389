import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getBatchOfVillage,
  getAllBatchesDropdown,
  getDonorLoanReport,
} from "../../actions/shg";
import { getAllVillages } from "../../actions/area";
import { getAllSHGCategory } from "../../actions/shg";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";

const DonorReport = ({
  auth: { isAuthenticated, user, users },
  area: { villageData },
  shg: { allShgCategories, allbatchesdropdown, donorLoanReport },
  getAllVillages,
  getAllSHGCategory,
  getAllBatchesDropdown,
  getDonorLoanReport,
}) => {
  //--------------SHG DROPDOWN---------------------//
  const [batchList, setBatchNameList] = useState("");

  useEffect(() => {
    getAllBatchesDropdown();
    getDonorLoanReport();
    getAllSHGCategory();
    getAllVillages();
  }, []);

  const [selectedVillage, setSelectedVillage] = useState("");
  const [selectedProj, setSeletedProj] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");

  useEffect(() => {
    if (allbatchesdropdown) {
      setBatchNameList(
        allbatchesdropdown.length > 0
          ? allbatchesdropdown.map((batchnamelist) => ({
              label: batchnamelist.batchName,
              value: batchnamelist.batchName,
            }))
          : 0
      );
    }
  }, [allbatchesdropdown]);

  const onBatchChange = (e) => {
    setSelectedVillage("");
    setSeletedProj("");
    setSelectedBatch(e);
    getDonorLoanReport({ batchName: e.label });
  };

  //------------------CATEGORY DROPDOWN-------------//

  const [ProjectList, setProjectList] = useState("");
  useEffect(() => {
    setProjectList(
      allShgCategories.map((project) => ({
        label: project.shgCategoryName || "",
        value: project.shgCategoryName || "",
      }))
    );
  }, [allShgCategories]);

  const onProjectChange = (e) => {
    setSeletedProj(e);
    setSelectedVillage("");
    setSelectedBatch("");

    getDonorLoanReport({ shgCategoryName: e.label });
  };

  //---------Project-----------//

  const [villageList, setVillageList] = useState("");
  useEffect(() => {
    setVillageList(
      villageData.map((village) => ({
        label: village.villageName,
        value: village.villageName,
      }))
    );
  }, [villageData]);

  const onVillageChange = (e) => {
    setSelectedVillage(e);
    setSeletedProj("");
    setSelectedBatch("");

    getDonorLoanReport({ villageName: e.label });
  };
  //-----------REFRESH BUTTON-----------//
  const onClickReset = () => {
    setSelectedVillage("");
    setSeletedProj("");
    setSelectedBatch("");
    getDonorLoanReport();
  };

  ////////////CSV//////////////
  let csvData = [];
  csvData = [
    [
      "SL.NO",
      "Institution Name",
      "Project Name",
      "TankName",
      "SHG/JLG Name",
      "Batch Type",
      "Village Name",
      "SHG/JLG Formation Date",
      "Group Bank A/C number",
      "No.of Women",
      "Status",
      "Savings",

      "Loan Amount from Internal Group (Till Date)",
      "Loan Amount from Bank (Till Date)",
      "Repayment Amount for Internal Funding (IL)",
      "Internal Loan Reason",
      "Repayment Amount for Bank Loan (OL)",
      "Other Loan Reason",
      "Outstanding IL",
      "Outstanding OL",
    ],
  ];

  donorLoanReport &&
    donorLoanReport.map((donorReport, idx) => {
      return csvData.push([
        idx + 1,
        user && user.institutionName,
        donorReport.shgCategoryName,
        donorReport.tankName,
        donorReport.batchName,
        donorReport.groupType,
        donorReport.villageName,
        donorReport.batchFormationDate,
        donorReport.batchBankAccountNumber,
        donorReport.memberCounter,
        donorReport.batchStatus,
        donorReport.batchSavingAmt,
        donorReport.batchLoanAmt,
        donorReport.batchOlLoan,
        donorReport.ILFunding,
        donorReport.ILReason.join(", "),
        donorReport.OLFunding,
        donorReport.OLReason.join(", "),
        donorReport.OutstandingIL,
        donorReport.OutstandingOL,
      ]);
    });

  const listReason = (arrayReason) => {
    try {
      if (arrayReason.length == 0) return "";
      return arrayReason.join(", ");
    } catch (er) {
      return "";
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      {!donorLoanReport ? (
        <Spinner />
      ) : (
        <div className="container container_align ">
          <div className="col-lg-11 col-md-11 col-sm-11 col-11">
            <h2 className="heading_color">Donor Report </h2>
            <hr />
          </div>
          <section className="sub_reg">
            <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
              <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                <Select
                  name="projectName"
                  options={ProjectList}
                  isSearchable={true}
                  value={selectedProj}
                  placeholder="Select Project"
                  onChange={(e) => onProjectChange(e)}
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                <Select
                  name="villageName"
                  options={villageList}
                  isSearchable={true}
                  value={selectedVillage}
                  placeholder="Select Village"
                  onChange={(e) => onVillageChange(e)}
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                <Select
                  name="shgjlgName"
                  options={batchList}
                  isSearchable={true}
                  value={selectedBatch}
                  placeholder="Select SHG/JLG"
                  onChange={(e) => onBatchChange(e)}
                />
              </div>
              <div className="col-lg-12 col-md-3 col-sm-4 col-11 py-3">
                <Link to="/all-reports">
                  <img
                    className="img_icon_size log float-right ml-3"
                    src={require("../../static/images/back.png")}
                    alt="Back"
                    title="Back"
                  />
                </Link>
                <img
                  className="img_icon_size log float-right ml-3"
                  onClick={() => onClickReset()}
                  src={require("../../static/images/refresh-icon.png")}
                  alt="refresh"
                  title="Refresh"
                />
                <CSVLink data={csvData}>
                  <img
                    className="img_icon_size log float-right ml-4"
                    src={require("../../static/images/excel_icon.png")}
                    alt="Excel-Export"
                    title="Excel-Export"
                  />
                </CSVLink>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
                <section className="body">
                  <div className=" body-inner no-padding table-responsive table-container mt-3 ">
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th>SL.NO</th>
                          <th>Institution Name</th>
                          <th>Project Name</th>
                          <th>TankName</th>
                          <th>SHG/JLG Name</th>
                          <th>Batch Type</th>
                          <th>Village Name</th>
                          <th>SHG/JLG Formation Date</th>
                          {/* <th>Group Bank A/C number</th> */}
                          <th>No.of Women</th>
                          <th>Status</th>
                          <th>Savings</th>
                          {/* <th>No.of Women linked to bank</th> */}
                          <th>Loan Amount from Internal Group (Till Date)</th>
                          <th>Loan Amount from Bank (Till Date)</th>
                          <th>Repayment Amount for Internal Funding (IL)</th>
                          <th>IL Reason</th>
                          <th>Repayment Amount for Bank Loan (OL)</th>
                          <th>OL Reason</th>
                          <th>Outstanding IL</th>
                          <th>Outstanding OL</th>
                        </tr>
                      </thead>
                      {donorLoanReport &&
                        donorLoanReport.map((Dreport, idx) => {
                          return (
                            <tbody key={idx}>
                              <td>{idx + 1}</td>
                              <td>{user && user.institutionName}</td>
                              <td>{Dreport.shgCategoryName}</td>
                              <td>{Dreport.tankName}</td>
                              <td>{Dreport.batchName}</td>
                              <td>{Dreport.groupType}</td>
                              <td>{Dreport.villageName}</td>
                              <td>{Dreport.batchFormationDate}</td>
                              {/* <td>{Dreport.batchBankAccountNumber}</td> */}
                              <td>{Dreport.memberCounter}</td>
                              <td>{Dreport.batchStatus}</td>
                              <td>{Dreport.batchSavingAmt}</td>

                              <td>{Dreport.batchLoanAmt}</td>
                              <td>{Dreport.batchOlLoan}</td>
                              <td>{Dreport.ILFunding}</td>
                              <td>{listReason(Dreport.ILReason)}</td>
                              <td>{Dreport.OLFunding}</td>
                              <td>{listReason(Dreport.OLReason)}</td>
                              <td>{Dreport.OutstandingIL}</td>
                              <td>{Dreport.OutstandingOL}</td>
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      )}
    </Fragment>
  );
};

DonorReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getDonorLoanReport: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getAllBatchesDropdown,
  getBatchOfVillage,
  getAllVillages,
  getAllSHGCategory,
  getAllSHGCategory,
  getDonorLoanReport,
})(DonorReport);
